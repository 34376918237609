export const fn_url = process.env.REACT_APP_BACKEND_URL + '/.netlify/functions/';
export const token = 'Skullz'
export const rake_percent = 50;
export const default_collection = 'alien.worlds';
export const bank_account = 'battle.dome';
export const bank_url = 'https://wax.bloks.io/account/' + bank_account;
export const transaction_url = 'https://wax.bloks.io/transaction/';
export const image_base = 'https://atomichub-ipfs.com/ipfs/';
export const asset_url_base = 'https://wax.atomichub.io/explorer/asset/wax-mainnet/';
export const template_url_base = 'https://wax.atomichub.io/explorer/template/wax-mainnet/alien.worlds/';
export const card_back = image_base + 'QmaUNXHeeFvMGD4vPCC3vpGTr77tJvBHjh1ndUm4J7o4tP'
export const card_size_ratio = 750 / 1050;
export const card_common = image_base + 'QmPSjMKxC6aZYJiKxSVtb6yVRB7CYC327zUt7dpEbMXot2';
export const card_rare = image_base + 'QmVZcV2BdNAxyrortmE1j5u5KUywUQCXUuG2uNHvaL8hxU';
export const card_epic = image_base + 'Qme6fvdPzMbJ7zip2c33JPizGgL6P6uMkLz5HpXyrFfG3a';
export const card_legendary = image_base + 'Qmb78MVB1H2Za4GDKQSgm7WwCzwf7sjqQAtrHJVsKAgM5Q';
export const card_mythical = image_base + 'QmbwsoeaUnbEy6abAE2sQJ6coy9eFzLP2SiNTi9hgb15Rp';
export const simulator_url = '/battle';
export const waiting_reload_seconds = 30;
export const ready_at_seconds = 10;
export const race_at_seconds = 10;
export const finished_at_seconds = 30;
export const close_before_seconds = 15;
export const orders = ['ORDERS_EVADE', 'ORDERS_DEFENCE', 'ORDERS_TARGETING', 'ORDERS_ATK_STR', 'ORDERS_ATK_WEAK'];
export const leaderPageSize = 10;
export const leaderGroupSize = 10;
export const leaderGroups = 3;
export const maxRepairs = 20;
export const season = 14;
export const seasonSponsor = 'BattleOfTheWorlds';
export const start_of_season = '2023-07-21';
export const end_of_season = '2023-08-21';
export const nftPrizeImagesAdvanced = [];
export const nftPrizeImagesBeginner = [];
export const monkeyNft = 'https://cryptomonkeys.cc/static/img/cards/QmaGaDmt1wnyX6kTtAXTbV5CuPqxdCjAnq85hsrU3LAdTp.png';
// export const tournamentPrizes = [200000, 100000, 50000, 30000, 25000, 25000, 20000, 20000, 15000, 15000];
