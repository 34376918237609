import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MainMenu from "../components/MainMenu";
import { imageForTeam, planetName } from "../helpers/Utils";
import { fn_url } from "../helpers/Config";
import LeagueSelector from "../components/Game/LeagueSelector";
import { League } from "../helpers/Types";
import { WalletContext } from "../main/App";

export default function LeaderTeams() {
    let wallet = React.useContext(WalletContext);
    const [league, setLeague] = useState(wallet.player.wins || 0 > 5 ? League.ADVANCED : League.BEGINNER);
    const [teams, setTeams] = useState<any[]>([]);

    const selectAndLoadLeague = (league: League) => {
        setTeams([]);
        setLeague(league);
        loadTeams(league)
    }

    const loadTeams = async (league: League) => {
        console.log("Loading teams...");
        let teams = await fetch(fn_url + "leaderteams?league=" + league).then(response => response.json());
        setTeams(teams);
    }

    useEffect(() => {
        loadTeams(league);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const month_lowercase = new Date().toLocaleString('en-us', { month: 'long' });
    const month = month_lowercase[0].toUpperCase() + month_lowercase.slice(1);

    return (
        <div style={{display: "flex", flexDirection: "row"}}>
            <MainMenu/>
            <div style={{width: '660px', padding: '10px 0px 0px 70px'}}>
                <div className='flexRow justifyStart'>
                    <div style={{fontSize: '30px', marginBottom: '20px'}}>
                        Planetary wins in { month }
                    </div>
                </div>
                <div className='flexColumn' style={{gap: '10px', alignItems: 'flex-start'}}>
                {teams.map(team =>
                    <div className='flexRow' key={team.winner} style={{gap: '20px', padding: '2px 5px 2px 10px', fontSize: '20px', justifyContent: 'flex-start'}}>
                        <div style={{width: '20px', marginLeft: '10px'}}>{team.placing}.</div>
                        <div className='flexRow' style={{width: '200px', gap: '10px', justifyContent: 'flex-start'}}>
                            <img alt='' height='50px' width='50px' title={planetName(team.winnerTeam)} src={imageForTeam(team.winnerTeam)} style={{marginBottom: '0'}}/>
                            <div>{planetName(team.winnerTeam)}</div>
                        </div>
                        <div style={{width: '24px', height: '32px', display: 'flex'}}>
                            { team.placing === 1 && (<img alt='' style={{height: '32px'}} src='trophy-gold.png' />)}
                            { team.placing === 2 && (<img alt='' style={{height: '32px'}} src='trophy-silver.png' />)}
                            { team.placing === 3 && (<img alt='' style={{height: '32px'}} src='trophy-bronze.png' />)}
                        </div>
                        <div className='flexRow' style={{width: '150px', gap: '5px', justifyContent: 'flex-start'}}>
                            <div>{team.wins} win{team.wins > 1 && 's'}</div>
                        </div>
                    </div>
                    )}
                </div>
                <div style={{marginTop: '20px', width: '550px'}}>
                    <LeagueSelector league={league} setLeague={selectAndLoadLeague}/>
                </div>
                <div style={{marginTop: '20px'}}><Link to='/Leaders'>Show Players Leaderboard</Link></div>
            </div>
        </div>
    );
}
