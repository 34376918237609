import React, { useEffect, useState } from "react";
import Nft from "../../helpers/Types";
import { purchase } from "../../methods/Bank";
import { WalletContext } from "../../main/App";
import { fn_url, image_base } from "../../helpers/Config";
import { repairCost } from "../../helpers/Utils";

export default function RepairPopup(props: {nft: Nft, repairFn?: any, handleClose?: any}) {
    let wallet = React.useContext(WalletContext);

    const [saveSending, setSaveSending] = useState<boolean | undefined>(undefined);
    const [saveError, setSaveError] = useState<boolean | undefined>(undefined);
    const [saveOkay, setSaveOkay] = useState<boolean | undefined>(undefined);
    const fee = repairCost(props.nft.rarity);
    const wait_until = wallet.player.repairs.find(x => x.id === props.nft.asset_id)?.wait_until;

    const [now, setNow] = useState<Date>(new Date());

    useEffect(() => {
        const interval = setInterval(() => setNow(new Date()), 1000);
        return () => clearInterval(interval);
    }, []);


    async function payForRepair() {
        if (!wallet.key) return;

        let signature = await purchase(wallet.key, fee, 'TLM', 'repair nft');
        if (!signature) {
            return;
        }

        console.log('sending repairsave...');
        setSaveSending(true);
        var response = await fetch(fn_url + "repairsave", {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'text/plain'},
            body: JSON.stringify({
                walletKey: wallet.key,
                id: props.nft.asset_id,
                fee: fee,
                signature: signature,
            })
        });
        console.log('ok: ' + response.ok);

        if (!response.ok) {
            setSaveError(true);
        } else {
            setSaveOkay(true);
            console.log('item ' + props.nft.asset_id + ' was repaired.');
            wallet.player.doRepair(props.nft.asset_id);
            props.handleClose();
        }
    }

    function durationHHmmSS(when: string | undefined) {
        if (!when) return "???";
        let whenDate = new Date(when);
        let diff = (whenDate.getTime() - now.getTime()) / 1000;
        return Math.trunc(diff / 3600).toString().padStart(2, '0') + ':' +
            Math.trunc(diff % 3600 / 60).toString().padStart(2, '0') + ':' +
            Math.trunc(diff % 60).toString().padStart(2, '0');
    }

    return (
        <div className='flexColumn'>
            <div>Repair this item?</div>
            <img alt='' height='150px'title={props.nft.name} src={image_base + props.nft.image} />
            <div>{wait_until ? 'Repair for free: ' + durationHHmmSS(wait_until) : 'Your NFT is repaired'}</div>
            <div className='flexRow' style={{gap: '5px'}}><div>Repair now:</div><img alt='' src='tlm-32.png'/><div>{fee} TLM</div></div>
            {saveError ? <div style={{color: 'red'}}>ERROR</div> :
             saveOkay ? <div>Done :)</div> :
             saveSending ? <div>Sending...</div> :
             <button className="playButton" onClick={() => payForRepair()}>REPAIR NOW</button>
            }
        </div>
    )
}
