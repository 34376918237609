import Announcement from "./Announcement";
import GameList from "../Game/GameList";
import PrecacheImages, { league_images } from "../../helpers/PrecacheImages";
import React from "react";

export default function LobbyMid() {
    return(
        <div style={{width: '450px', height: '368px', textAlign: 'center'}}>
            <img alt='' src='battledome-blue.png' style={{width: '425px', marginLeft: '15px', marginTop: '5px', paddingBottom: '15px'}}/>
            <Announcement/>
            {/*<NewsFlash/>*/}
            <GameList/>

            <PrecacheImages images={[...league_images]} />
        </div>
    );
}
