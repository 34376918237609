import * as React from "react";
import { WalletContext } from "../main/App";

export default function WalletPanel() {
    let wallet = React.useContext(WalletContext);

    const signOut = () => {
        wallet.disconnect();
    }

    return (
        <div className='flexRow' style={{}}>
            {wallet.key ?
                <div className='flexRow' style={{width: '100%', height: '32px', marginTop: '0px', color: 'black', backgroundColor: 'lightblue', borderRadius: '10px', padding: '0px 20px 0px 20px'}}>
                    <img alt='' style={{marginTop: '0px', height: '20px', width:'20px'}} src='/wax-32.png'/>
                    <div style={{fontSize: '16px'}}>{ wallet.key }</div>
                    <div title='sign out'>
                    <svg style={{marginTop: '0px', color: 'white'}} stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" color="#fff" height="24" width="24" xmlns="http://www.w3.org/2000/svg" onClick={signOut}>
                        <polyline points="8 12 16 20 12 16 8 20 16 12"></polyline>
                    </svg>
                    </div>
                </div>
            : // wallet.provider ?
                <button className='playButton'
                        style={{fontSize: "15px", width: '150px', padding: "5px 10px 5px 10px", borderRadius: "5px"}}
                        onClick={wallet.connect}>
                    Connect Wallet
                </button>
            // :
            //     <a target='phantom' href='https://phantom.app'>
            //         <button className='playButton'
            //                 style={{fontSize: "15px", width: '150px', padding: "5px 10px 5px 10px", borderRadius: "5px"}}>
            //             Install Wallet
            //         </button>
            //     </a>
            }
        </div>
    )
}
