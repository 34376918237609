import * as React from "react";
import { useState } from "react";
import * as waxjs from "@waxio/waxjs/dist";

import "./App.css";
import Lobby from "../views/Lobby";
import History from "../views/History";
import Leaders from "../views/Leaders";
import MyNfts from "../views/MyNfts";
import Admin from "../views/Admin";
import HowToPlay from "../views/HowToPlay";
import Nft, { Game, Player, Repair } from "../helpers/Types";
import { fn_url } from "../helpers/Config";
import Battle from "../views/Battle";
import RewardPage from "../views/RewardPage";
import Shop from "../views/Shop";
import LeaderTeams from "../views/LeaderTeams";
import Batchjobs from "../views/Batchjobs";
import Repairs from "../views/Repairs";
import News from "../views/News";
import Airdrops from "../views/Airdrops";
import ComingSoon from "../views/ComingSoon";
import Tokens from "../views/Tokens";
import Market from "../views/Market";
import MarketInfo from "../views/MarketInfo";
import Tournament from "../views/Tournament";
import Boost from "../views/Boost";
import { scanNfts } from "../methods/Nfts";


export interface Wallet {
  key: string | undefined,
  // provider: any | undefined,
  connect: any | undefined,
  disconnect: any | undefined,
  player: Player,
}

export const WalletContext = React.createContext<Wallet>(null!);

export default function App(props: {screen: any}) {
  // const [provider, setProvider] = useState<PhantomProvider | undefined>(undefined);
  const [waxApi, setWaxApi] = useState<any | undefined>(undefined);
  const [walletKey, setWalletKey] = useState<string | undefined>(undefined);
  const [wax, setWax] = useState<number | undefined>(undefined);
  const [tlm, setTlm] = useState<number | undefined>(undefined);
  const [wins, setWins] = useState<number | undefined>(undefined);
  const [nfts, setNfts] = useState<Nft[] | undefined>(undefined);
  const [tag, setTag] = useState(undefined);
  const [games, setGames] = useState<Game[]>([]);
  const [tokens, setTokens] = useState(undefined);
  const [repairs, setRepairs] = useState<Repair[]>([]);
  const [nextReward, setNextReward] = useState<Game>();

  const connectWallet = async () => {
    try {
      const wax = new waxjs.WaxJS({rpcEndpoint: 'https://wax.greymass.com'});
      let key = await wax.login();
      console.log("wallet account ", key);
      await setWaxApi(wax.api);

      // key = 'xyfgw.wam';
      setWalletKey(key);
      loadBalances(wax.api, key);
      loadNfts(key);
      loadPlayerdata(key);
      return key;
    } catch (err) {
      // { code: 4001, message: 'User rejected the request.' }
    }
  };

  const disconnectWallet = async () => {
    if (walletKey) {
      setWaxApi(undefined);
      setWalletKey(undefined);
      setWax(undefined);
      setNfts(undefined);
    }
  };

  const loadBalances = async (api: any, key: string) => {
    if (!api) return;
    console.log("Loading balances...");
    let account = await api.rpc.get_account(key);
    if (account.core_liquid_balance) {
      let balance: number = +account.core_liquid_balance.replace(' WAX', '');
      setWax(+balance.toFixed(2));
    }
    let currency = await api.rpc.get_currency_balance('alien.worlds', key, 'TLM');
    if (currency.length) {
      let tlm_value = +currency[0].replace(' TLM', '');
      setTlm(tlm_value >= 0.01 ? +tlm_value.toFixed(2) : tlm_value);
    }
  }

  const loadNfts = async (walletKey: string) => {
    let plr_nfts = await scanNfts(walletKey);
    console.log('Loading nfts...');
    setNfts(plr_nfts);

    console.log(plr_nfts);

    // console.log(await api.getSchema('alien.worlds', 'faces.worlds'));
    // let avatars = (await api.getTemplates({limit: 500, collection: 'alien.worlds', schema_name: 'faces.worlds'}))
    //     .filter(x => (+x.issued_supply) > 0)
    //     .filter(x.immutable_data.rarity)
    //     .sort((a, b) => (+(a.template_id) - (+b.template_id)))
    //     .map(x => ({id: x.template_id, img: x.immutable_data.img, name: x.immutable_data.name, rarity: x.immutable_data.rarity}))
    // ;
    // console.log(avatars.length);
    // console.log(JSON.stringify(avatars));

    // let minions = (await api.getTemplates({limit: 500, collection: 'alien.worlds', schema_name: 'crew.worlds'}))
    //     .filter(x => (+x.issued_supply) > 0)
    //     .filter(x => !["121338", "122198", "122202", "122204", "167444", "142907"].includes(x.template_id))
    //     .sort((a, b) => (+(a.template_id) - (+b.template_id)))
    //     .map(x => ({id: x.template_id, img: x.immutable_data.img, name: x.immutable_data.name, rarity: x.immutable_data.rarity, element: x.immutable_data.element, attack: x.immutable_data.attack, defense: x.immutable_data.defense, movecost: x.immutable_data.movecost}));
    // console.log(minions.length);
    // console.log(JSON.stringify(minions));

    // let weapons = (await api.getTemplates({limit: 500, collection: 'alien.worlds', schema_name: 'arms.worlds'}))
    //     .filter(x => (+x.issued_supply) > 0)
    //     .filter(x => !["118165", "123552", "155738"].includes(x.template_id))
    //     .sort((a, b) => (+(a.template_id) - (+b.template_id)))
    //     .map(x => ({id: x.template_id, img: x.immutable_data.img, name: x.immutable_data.name, rarity: x.immutable_data.rarity, class: x.immutable_data.class, attack: x.immutable_data.attack, defense: x.immutable_data.defense}));
    // console.log(weapons.length);
    // console.log(JSON.stringify(weapons));

    // let account = await api.getAccount(walletKey);
    // console.log(account);
    // console.log(assets);
    // console.log(plr_nfts);
  };

  const loadPlayerdata = async (walletKey: string) => {
    console.log("Loading playerdata...");
    setGames([]);
    setRepairs([]);
    setNextReward(undefined);
    let playerdata = await fetch(fn_url + "playerdata?walletKey=" + walletKey).then(response => response.json());
    setTag(playerdata.tag);
    setWins(playerdata.wins || 0);
    setGames(playerdata.games);
    setRepairs(playerdata.repairs);
    setTokens(playerdata.rewards.balance);
    if (playerdata.rewards.claims.length > 0) {
      setNextReward(playerdata.rewards.claims[0]);
    }
  }

  let player : Player = {
    tag: tag,
    wax: wax,
    tlm: tlm,
    wins: wins,
    nfts: nfts,
    tokens: tokens,
    nextReward: nextReward,
    games: games,
    repairs: repairs,
    doRepair: ((id: string) => {
      id === 'ALL' ? setRepairs([]) : setRepairs(repairs.filter(x => x.id !== id));
    }),
    refresh: (() => {
      loadBalances(waxApi, walletKey!);
      loadPlayerdata(walletKey!);
    }),
  };

  let wallet = {key: walletKey, connect: connectWallet, disconnect: disconnectWallet, player: player };

  return (
      <WalletContext.Provider value={wallet}>
          <div className="App">
            { (props.screen === 'admin') && <Admin/> }
            { (props.screen === 'airdrops') && <Airdrops/> }
            { (props.screen === 'batchjobs') && <Batchjobs/> }
            { (props.screen === 'battle') && <Battle/> }
            { (props.screen === 'boost') && <Boost/> }
            { (props.screen === 'comingsoon') && <ComingSoon/> }
            { (props.screen === 'howtoplay') && <HowToPlay/> }
            { (props.screen === 'history') && <History/> }
            { (props.screen === 'leaders') && <Leaders/> }
            { (props.screen === 'leaderteams') && <LeaderTeams/> }
            { (props.screen === 'lobby') && <Lobby/> }
            { (props.screen === 'market') && <Market/> }
            { (props.screen === 'marketinfo') && <MarketInfo/> }
            { (props.screen === 'news') && <News/> }
            { (props.screen === 'mynfts') && <MyNfts/> }
            { (props.screen === 'rewards') && <RewardPage/> }
            { (props.screen === 'repairs') && <Repairs/> }
            { (props.screen === 'shop') && <Shop/> }
            { (props.screen === 'tokens') && <Tokens/> }
            { (props.screen === 'tournament') && <Tournament/> }
          </div>
      </WalletContext.Provider>
  );
}

