import React from "react";
import MainMenu from "../components/MainMenu";
import { WalletContext } from "../main/App";
import { token } from "../helpers/Config";
import { TokenPack } from "../helpers/Types";
import TokenPackPanel from "../components/Tokens/TokenPackPanel";
import { Link } from "react-router-dom";

export default function Tokens() {
    const wallet = React.useContext(WalletContext);
    const tokenPacks : TokenPack[] = [
        {id: 'TOKENPACK-S', name: 'Small ' + token + ' Pack', price: 100, tokens: 20, bonusPct: 0},
        {id: 'TOKENPACK-M', name: 'Medium ' + token + ' Pack', price: 250, tokens: 50, bonusPct: 0},
        {id: 'TOKENPACK-L', name: 'Large ' + token + ' Pack', price: 500, tokens: 110, bonusPct: 10},
        {id: 'TOKENPACK-XL', name: 'Xtra-Large ' + token + ' Pack', price: 1000, tokens: 250, bonusPct: 25},
    ]

    return (
        <div style={{display: "flex", flexDirection: "row"}}>
            <MainMenu/>
            <div style={{width: '660px', padding: '10px 0px 20px 70px', fontSize: '20px'}}>
                <div style={{fontSize: '30px', marginBottom: '20px'}}>
                    Top up {token}
                </div>

                {wallet.key ?
                    <div className='flexColumn'>
                        <div className='flexRow'>
                            <div>Your balance is:</div>
                            <img alt="" src="token-32.png" height="30px" width="30px"/>
                            <div>{wallet.player.tokens} {token.toUpperCase()}</div>
                        </div>

                        <div className='flexRow wrap'>
                            {tokenPacks.map(x =>
                                <TokenPackPanel tokenPack={x}/>
                            )}
                        </div>

                        <div className='flexRow' style={{marginTop: '20px'}}>
                            <Link to='/shop'><button className='playButton'>Go to Shop</button></Link>
                            <Link to='/market'><button className='playButton'>Go to Market</button></Link>
                        </div>
                    </div>
                :
                    <div className='flexColumn' style={{fontSize: '26px', marginTop: '120px'}}>
                        You need to connect a wallet
                        <Link to='/' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px', cursor: 'pointer', textDecoration: 'none'}}><button className="playButton">Go to Lobby</button></Link>
                    </div>
                }

            </div>
        </div>
    );
}
