import { useCallback, useEffect, useState } from "react";
import BattlePlr from "../components/Battle/BattlePlr";
import { BPlayer, BResult, SfxMap } from "../components/Battle/BattleTypes";
import BattleEvent from "../components/Battle/BattleEvent";
import { applyEvent } from "../components/Battle/BattleEngine";
import { imageForAvatar } from "../helpers/Utils";
import { fn_url } from "../helpers/Config";

export default function Battle() {
    const [bresult, setBresult] = useState<BResult | undefined>(undefined);
    const [bplayers, setBplayers] = useState<BPlayer[] | undefined>(undefined);
    const [bevents, setBevent] = useState<string[] | undefined>(undefined);
    const [displayFrom, setDisplayFrom] = useState(0);
    const [displayTo, setDisplayTo] = useState(0);
    const eventPageSize = 8;
    const sfx: SfxMap = {
        'INTRO-01': new Audio("sfx/INTRO-01.mp3"),
        'ATT-0101': new Audio("sfx/ATT-0101.mp3"),
        'ATT-0102': new Audio("sfx/ATT-0102.mp3"),
        'ATT-0103': new Audio("sfx/ATT-0103.mp3"),
        'END-01': new Audio("sfx/END-01.mp3"),
    }
    useEffect(() => {
        let key = (new URL(document.location.toString())).searchParams.get("key");
        key && loadBattleResult(key);
        sfx['INTRO-01'].play();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    function getItem(itemType: string, tokens: string[]) {
        if (tokens.length >= 21 && tokens[20] !== 'null') {
            let items = tokens[20].split(',').filter(x => x.startsWith(itemType));
            if (items.length) {
                return items[items.length - 1];
            }
        }
        return undefined;
    }

    function hasItem(itemType: string, tokens: string[]) {
        return getItem(itemType, tokens) !== undefined;
    }

    const loadBattleResult = async (key: string) => {
        let result = await fetch(fn_url + "result?key=" + key).then(resp => resp.text());
        let jsonLines = 13;
        let jsonText = result.split('\n').slice(0, jsonLines).join('\n');
        let parsedBResult = JSON.parse(jsonText);
        let numPlayers = parsedBResult.players;
        let initPlrs = result.split('\n').slice(jsonLines).slice(0, numPlayers);

        setBresult(parsedBResult);

        let bplrs = initPlrs.map(line => {
            let tokens = line.replace(']', '').split(' ');
            return {
                num: tokens[1],
                walletKey: tokens[2],
                tag: tokens.length >= 19 && tokens[18] !== 'null' ? tokens[18] : undefined,
                img: imageForAvatar(tokens[4].split('/')[0]),
                rarity: tokens.length >= 23 ? +tokens[22] : 0,
                orders: tokens[7],
                planet: tokens[8],
                phase: +tokens[10],
                stealth: tokens.length >= 17 ? +tokens[16] : 0,
                avatar: tokens[4],
                selection: [tokens[4], tokens[5], tokens[6], tokens[7], tokens[8], tokens[20]].join(' '),
                placing: -1,
                maxHealth: +tokens[12],
                health: +tokens[12],
                attack: +tokens[14],
                damaged: false,
                attacking: false,
                defending: false,
                alive: true,
                winner: false,
                animationType: undefined,
                attackItem: getItem('ATT', tokens),
                healthPotion: hasItem('POT-01', tokens),
                attackPotion: hasItem('POT-02', tokens),
                alienPotion: hasItem('POT-03', tokens),
            };
        });

        let eventsText = result.split('\n').slice(jsonLines + numPlayers);
        setBplayers(bplrs);
        setBevent(eventsText);
    }

    const nextEvent = useCallback(() => {
        if (bplayers && bevents && displayTo + 1 < bevents.length) {
            applyEvent(bevents[displayTo], bplayers, sfx);
            setDisplayTo(displayTo + 1);
            if (displayFrom <= displayTo - eventPageSize) setDisplayFrom(displayTo - eventPageSize + 1);
            if (bevents[displayTo].startsWith('>')) setDisplayFrom(displayTo);
        }

    }, [bplayers, bevents, displayFrom, displayTo]);  // eslint-disable-line react-hooks/exhaustive-deps

    const closeWindow = () => {
        window.close();
    };

    useEffect(() => {
        const interval = setInterval(() => nextEvent(), 3000);
        return () => clearInterval(interval);
    }, [nextEvent]);

    return (
        <div className='flexColumn' style={{width: '1011px', height: '750px', justifyContent: 'flex-start'}}>
            <div style={{fontSize: '50px', fontFamily: 'AlienWorld'}}>BATTLEDOME</div>
            { bresult && bplayers && bevents ?
                <div className='flexColumn'>
                    <div style={{fontSize: '20px', fontFamily: 'AlienWorld'}}>Game: {bresult.game_name}</div>
                    <div className='flexRow' style={{gap: '50px', marginBottom: '5px'}}>
                        <div style={{fontSize: '20px', fontFamily: 'AlienWorld'}}>Players: {bresult.players}</div>
                        <div style={{fontSize: '20px', fontFamily: 'AlienWorld'}}>Phase: {bresult.phase}</div>
                    </div>
                    {bplayers.length > 8 ?
                        <div className='flexColumn'>
                            <div className='flexRow alignStart'>
                                {bplayers.slice(0,Math.ceil(bplayers.length/2)).map(bplr => <BattlePlr key={bplr.num} bplr={bplr} />)}
                            </div>
                            <div className='flexRow alignStart'>
                                {bplayers.slice(Math.ceil(bplayers.length/2)).map(bplr => <BattlePlr key={bplr.num} bplr={bplr} />)}
                            </div>
                        </div>
                        :
                        <div className='flexRow alignStart'>
                            {bplayers.map(bplr => <BattlePlr key={bplr.num} bplr={bplr} />)}
                        </div>
                    }
                    <div className='flexColumn' style={{marginTop: '10px'}}>
                        {bevents.slice(displayFrom,displayTo).map((bevent,x) => <BattleEvent key={x} bevent={bevent}/>)}
                    </div>
                    {displayTo + 1 < bevents.length &&
                        <div className='flexColumn gap5'>
                            <button autoFocus={true} className='playButton' style={{padding: '5px'}} onClick={() => nextEvent()}>Next</button>
                            <div style={{fontSize: '12px'}}>use spacebar to scroll</div>
                        </div>
                    }
                    {displayTo + 1 >= bevents.length &&
                        <button className='playButton' style={{padding: '5px'}} onClick={() => closeWindow()}>CLOSE</button>
                    }
                </div>
                :
                <div>
                    <div style={{fontSize: '20px', fontFamily: 'AlienWorld', marginTop: '50px'}}>Loading...</div>
                </div>
            }
        </div>
    )
}
