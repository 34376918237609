import { imageForTeam, planetName } from "../../helpers/Utils";
import React from "react";
import TabPlayersPanelRow from "./TabPlayersPanelRow";

export default function TabPlayersPanel(props: {team: any, players: any[]}) {
    return(
        <div className='flexRow' key={props.team} style={{gap: '20px', padding: '2px 5px 2px 10px', fontSize: '20px', textAlign: 'left'}}>
            <div style={{width: '20px', marginLeft: '10px'}}>{props.team.slice(-1)}.</div>
            <div className='flexRow' style={{width: '150px', gap: '10px', justifyContent: 'flex-start'}}>
                <img alt='' height='75px' width='75px' title={planetName(props.team)} src={imageForTeam(props.team)}/>
                <div>{planetName(props.team)}</div>
            </div>
            <div className='flexColumn alignStart' style={{width: '150px', gap: '0px', fontSize: '15px'}}>
                <TabPlayersPanelRow placing={1} player={props.players[0]} />
                <TabPlayersPanelRow placing={2} player={props.players[1]} />
                <TabPlayersPanelRow placing={3} player={props.players[2]} />
            </div>
        </div>
    );
}
