import React from "react";
import MainMenu from "../components/MainMenu";
import { WalletContext } from "../main/App";
import NftSelector from "../components/NftSelector";

export default function MyNfts() {
    let wallet = React.useContext(WalletContext);
    const nfts = wallet.player.nfts;

    return (
        <div style={{display: "flex", flexDirection: "row"}}>
            <MainMenu/>
            <div style={{fontSize: '20px', width: '660px', padding: '10px 0px 0px 70px'}}>
                <div style={{fontSize: '30px', marginBottom: '20px'}}>
                    My NFTs for Battledome
                </div>

                <div className='flexRow' style={{textAlign: 'center'}}>
                    <div className='flexColumn' style={{gap: '30px'}}>
                        <div className='flexColumn' style={{gap: '10px'}}>
                            <div style={{fontFamily: 'Alienworld'}}>
                                Avatars
                            </div>
                            <div style={{fontFamily: 'Alienworld', fontSize: '13px'}}>
                                {nfts ? 'you have ' + nfts.filter(x => x.schema === 'faces.worlds').length + ' avatars' : 'connect wallet'}
                            </div>
                            {nfts && <NftSelector schema={'faces.worlds'} nfts={nfts.filter(x => x.schema === 'faces.worlds')} pagesize={5} height={50} isHoverCard={true}/> }
                            <div>
                                Buy avatars from the "faces.worlds" NFT collection.
                            </div>
                            <div style={{marginTop: '5px'}}>
                                <a href="https://wax.atomichub.io/market?collection_name=alien.worlds&schema_name=faces.worlds" target="atomichub">
                                    <button className="clearButton">Browse&nbsp;avatars</button>
                                </a>
                            </div>
                        </div>

                        <div className='flexColumn' style={{gap: '10px'}}>
                            <div style={{fontFamily: 'Alienworld'}}>
                                Weapons
                            </div>
                            <div style={{fontFamily: 'Alienworld', fontSize: '13px'}}>
                                {nfts ? 'you have ' + nfts.filter(x => x.schema === 'arms.worlds').length + ' weapons' : 'connect wallet'}
                            </div>
                            {nfts && <NftSelector schema={'arms.worlds'} nfts={nfts.filter(x => x.schema === 'arms.worlds')} pagesize={5} height={50} isHoverCard={true}/> }
                            <div>
                                Buy weapons from the "arms.worlds" NFT collection.
                            </div>
                            <div style={{marginTop: '5px'}}>
                                <a href="https://wax.atomichub.io/market?collection_name=alien.worlds&schema_name=arms.worlds" target="atomichub">
                                    <button className="clearButton">Browse&nbsp;weapons</button>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='flexColumn' style={{gap: '30px'}}>
                        <div className='flexColumn' style={{gap: '10px'}}>
                            <div style={{fontFamily: 'Alienworld'}}>
                                Minions
                            </div>
                            <div style={{fontFamily: 'Alienworld', fontSize: '13px'}}>
                                {nfts ? 'you have ' + nfts.filter(x => x.schema === 'crew.worlds').length + ' minions' : 'connect wallet'}
                            </div>
                            {nfts && <NftSelector schema={'crew.worlds'} nfts={nfts.filter(x => x.schema === 'crew.worlds')} pagesize={5} height={50} isHoverCard={true}/> }
                            <div>
                                Buy minions from the "crew.worlds" NFT collection.
                            </div>
                            <div style={{marginTop: '5px'}}>
                                <a href="https://wax.atomichub.io/market?collection_name=alien.worlds&schema_name=crew.worlds" target="atomichub">
                                    <button className="clearButton">Browse&nbsp;minions</button>
                                </a>
                            </div>
                        </div>

                        <div className='flexColumn' style={{gap: '10px'}}>
                            <div style={{fontFamily: 'Alienworld'}}>
                                Artifacts
                            </div>
                            <div style={{fontFamily: 'Alienworld', fontSize: '13px'}}>
                                {nfts ? 'you have ' + nfts.filter(x => x.schema === 'artifacts.dome').length + ' artifacts' : 'connect wallet'}
                            </div>
                            {nfts && <NftSelector schema={'battle.dome'} nfts={nfts.filter(x => x.schema === 'battle.dome')} pagesize={5} height={50} isHoverCard={true}/> }
                            <div style={{height: '62px'}}>
                                Artifacts are coming in a future game update!
                            </div>
                            <div style={{marginTop: '5px'}}>
                                <button className="clearButton">Coming soon</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
