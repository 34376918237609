import { imageForMinion, imageForWeapon, ordersImage} from "../helpers/Utils";

export default function HoverCard(props: {hoverImage: string, hoverFun?: string}) {
    const hoverImage = props.hoverImage;
    const hoverFun = props.hoverFun;

    return (
        <div style={
            {
                position: 'absolute',
                top: '100%',
                left: '50%',
                transform: 'translate(-50%, 0)',
                // width: '250px',
                width: `${hoverImage.length === 0 ? '0' : '250px'}`,
                // height: 'auto',
                height: `${hoverImage.length === 0 ? '0' : '350px'}`,
                // zIndex: '10',
                zIndex: `${hoverImage.length === 0 ? '-10' : '10'}`,
                background: `${hoverFun === 'ordersImage' ? '#000000' : ''}`,
                transition: '.3s',
            }
        }
        >
            <img alt='' src={hoverFun === undefined ? hoverImage : hoverFun === 'imageForMinion' ? imageForMinion(hoverImage) : hoverFun === 'imageForWeapon' ? imageForWeapon(hoverImage) : hoverFun === 'ordersImage' ? ordersImage(hoverImage) : ''} width='100%'/>
        </div>
    );
}
