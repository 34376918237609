import MainMenu from "../components/MainMenu";
import React from "react";

export default function ComingSoon() {
    return (
        <div style={{display: "flex", flexDirection: "row"}}>
            <MainMenu/>
            <div style={{width: '660px', padding: '10px 0px 0px 70px', fontSize: '20px'}}>
                <div style={{fontSize: '30px', marginBottom: '20px'}}>
                    Coming Soon
                </div>
                <div>Stay tuned, this feature is coming soon :)</div>
            </div>
        </div>
    );
}
