import React, { useState } from "react";
import Nft from "../helpers/Types";
import { card_back, card_size_ratio, image_base } from "../helpers/Config";
import RepairPopup from "./Repair/RepairPopup";
import Popup from "./Popup";
import { WalletContext } from "../main/App";
import HoverCard from "./HoverCard";

export default function NftSelector(props: {schema: string, nfts: Nft[], pagesize: number, height: number, showMessage?: boolean, selectFn?: any, isHoverCard?: boolean}) {
    const wallet = React.useContext(WalletContext);

    const [isPopup, setIsPopup] = useState(false);
    const [repairNft, setRepairNft] = useState<Nft>();
    const [page, setPage] = useState<number>(0);
    const [hoverCard, setHoverCard] = useState<{index: number, imageSrc: string} | null>(null);
    const pagesize = props.pagesize;
    const isHoverCard = props.isHoverCard;
    const width = props.height * card_size_ratio;

    function pageLeft() {
        if (page > 0) {
            setPage(page - 1);
        }
    }

    function pageRight() {
        if (props.nfts && page < (props.nfts.length / pagesize - 1)) {
            setPage(page + 1);
        }
    }

    function selectNft(nft: Nft) {
        console.log('selecting nft ' + nft.name);
        props.selectFn && props.selectFn(nft);
    }

    const togglePopup = () => {
        setIsPopup(!isPopup);
    }

    function askRepair(nft: Nft) {
        setRepairNft(nft);
        togglePopup();
    }

    function needsRepair(asset_id: string) {
        return wallet.player.repairs.find(x => x.id === asset_id);
    }

    return (props.nfts && props.nfts.length > 0) ? (
        <div>
            <div className='flexRow'>
                {props.nfts.length > pagesize && <div><img alt="left" width="15px" src="arrow-left-25.png" onClick={pageLeft}/></div> }
                {props.nfts.slice(page * pagesize, (page + 1) * pagesize).map((x,i) =>
                        <div key={i} className='imageContainer'>
                            <div style={{position: 'relative'}}>
                                <img onMouseOver={() => setHoverCard({index: i, imageSrc: image_base + x.image})}
                                    onMouseLeave={() => setHoverCard(null)} alt='' title={x.name} height={props.height + 'px'} width={width + 'px'} src={image_base + x.image} onClick={() => selectNft(x)}/>
                                {
                                  isHoverCard && hoverCard?.index === i && hoverCard.imageSrc === image_base + x.image ? <HoverCard hoverImage={hoverCard.imageSrc}/> : ''
                                }
                            </div>
                            {x.schema === 'crew.worlds' && needsRepair(x.asset_id) &&
                                <div className='imageOverlay'><img alt='' title={'Needs heal'}
                                                                   height={props.height + 'px'} width={width + 'px'}
                                                                   src={'heal-' + props.height + '.png'}
                                                                   onClick={() => askRepair(x)}
                                                                   onMouseOver={() => setHoverCard({index: i, imageSrc: image_base + x.image})}
                                                                   onMouseLeave={() => setHoverCard(null)}
                                                                   /></div>}
                            {x.schema === 'arms.worlds' && needsRepair(x.asset_id) &&
                                <div className='imageOverlay'><img alt='' title={'Needs repair'}
                                                                   height={props.height + 'px'} width={width + 'px'}
                                                                   src={'repair-' + props.height + '.png'}
                                                                   onClick={() => askRepair(x)}
                                                                   onMouseOver={() => setHoverCard({index: i, imageSrc: image_base + x.image})}
                                                                   onMouseLeave={() => setHoverCard(null)}
                                                                   /></div>}
                        </div>
                )}
                {props.nfts.length > pagesize && <div><img alt="right" width="15px" src="arrow-right-25.png" onClick={pageRight}/></div> }
            </div>

            {isPopup && repairNft && (<Popup content={<RepairPopup nft={repairNft} handleClose={togglePopup}/>} handleClose={togglePopup}/>)}
        </div>
    ) : props.showMessage ? (
        <div style={{height: '150px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '10px'}}>
            <div style={{textAlign: 'center', fontWeight: 'bold'}}>
                Want to play?
            </div>
            <div style={{textAlign: 'center', width: '100%'}}>
                Purchase an NFT from '{props.schema}'
            </div>
            <a href={'https://wax.atomichub.io/market?collection_name=alien.worlds&schema_name=' + props.schema} target='atomichub'>
                <button className="clearButton">Browse&nbsp;here</button>
            </a>
        </div>
    ) : (<div style={{height: props.height + 'px'}}>
            <img alt='nft' height={props.height +'px'} src={card_back} />
        </div>);
}
