import React from "react";
import { Link } from "react-router-dom";
import MainMenu from "../components/MainMenu";
import { card_common, card_epic, card_rare, token } from "../helpers/Config";

export default function MarketInfo() {


    return (
        <div style={{display: "flex", flexDirection: "row"}}>
            <MainMenu/>
            <div style={{width: '660px', padding: '10px 0px 20px 70px', fontSize: '20px'}}>
                <div style={{fontSize: '30px', marginBottom: '20px'}}>
                    Market Info
                </div>
                <div className='flexColumn'>
                    <div>Welcome to the Battledome Market! How does it work?</div>

                    <div>1. Play and Win to earn Skullz</div>

                    <div className='flexRow'>
                        <img alt='' height='78px' width='78px' src='winner.png'/>
                        <img alt='' src='arrow-right-25.png'/>
                        <img alt='' height='50px' width='50px' src='token-50.png'/>
                    </div>

                    <div>2. NFTs will be listed in the Market, you can purchase them with Skullz.</div>

                    <div className='flexRow'>
                        <img alt='' height='50px' width='50px' src='token-50.png'/>
                        <img alt='' src='arrow-right-25.png'/>
                        <div className='flexRow gap5'>
                            <img alt='' height='50px' src={card_common}/>
                            <img alt='' height='50px' src={card_rare}/>
                            <img alt='' height='50px' src={card_epic}/>
                        </div>
                    </div>

                    <div>3. The price of the NFTs in the Market will reduce every hour.</div>

                    <div className='flexRow'>
                        <div className="flexRow" style={{gap: '7px'}}><img alt="" src="token-32.png" height="24px" width="24px"/> 10 {token}</div>
                        <img alt='' height='16px' src='arrow-right-25.png'/>
                        <div className="flexRow" style={{gap: '7px'}}><img alt="" src="token-32.png" height="24px" width="24px"/> 9 {token}</div>
                        <img alt='' height='16px' src='arrow-right-25.png'/>
                        <div className="flexRow" style={{gap: '7px'}}><img alt="" src="token-32.png" height="24px" width="24px"/> 8 {token}</div>
                        <img alt='' height='16px' src='arrow-right-25.png'/>
                        <div>...</div>
                    </div>

                    <div>4. Each unique wallet can purchase only one NFT per 24 hours.</div>

                    <div>5. You can also top up Skullz by spending Trilium.</div>

                    <div className='flexRow'>
                        <img alt='' height='32px' src='tlm-32.png'/>
                        <img alt='' src='arrow-right-25.png'/>
                        <img alt='' height='32px' src='token-32.png'/>
                    </div>

                    <div className='flexRow' style={{marginTop: '20px'}}>
                        <Link to='/tokens'><button className='playButton'>Top up Skullz</button></Link>
                        <Link to='/market'><button className='playButton'>Go to Market</button></Link>
                    </div>

                </div>
            </div>
        </div>
    );
}
