export default function TabWelcome() {
    return (
        <div className='flexColumn' style={{fontSize: '18px', width: '60%', marginTop: '15px'}}>
            <div>Welcome to the Battle of the Worlds :)</div>

            <div>Fight in Battledome's first planet vs planet tournament - team up, destroy your opponents and battle your planetary syndicate to victory.</div>

            <div>The winning planetary syndicate in this tournament will receive an epic prize, as will the top 3 players for each of the 6 planetary syndicates.</div>

            <div>Please read the rules and play nice - anyone botting will be perma-banned.</div>

            <div>Good luck and may your planetary syndicate emerge victorious!</div>

            <div className='flexRow'>
                <div>Credits:</div>
                <img alt='' height='30px' width='30px' title='Tr0n' src='/tr0n.png' style={{borderRadius: '50%'}}/>
                <img alt='' height='30px' width='30px' title='Bulga' src='/bulga.png' style={{borderRadius: '50%'}}/>
            </div>

        </div>
    )
}