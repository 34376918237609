import React from "react";
import TabPlanetsPanel from "./TabPlanetsPanel";

export default function TabPlanets(props: {teams: any[]}) {
    return (
        <div className='flexRow' style={{marginTop: '25px'}}>
            <div className='flexColumn'>
                {props.teams.slice(0,3).map(team => <TabPlanetsPanel key={team.winnerTeam} team={team}/>)}
            </div>
            <div className='flexColumn'>
                {props.teams.slice(3,6).map(team => <TabPlanetsPanel key={team.winnerTeam} team={team}/>)}
            </div>
        </div>
    )
}