import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MainMenu from "../components/MainMenu";
import { isAdmin } from "../methods/Authorize";
import { WalletContext } from "../main/App";
import { bank_account, bank_url, card_back } from "../helpers/Config";
import Nft from "../helpers/Types";
import NftSelector from "../components/NftSelector";
import { scanNfts } from "../methods/Nfts";

const { Api, JsonRpc } = require('eosjs');
const fetch = require('node-fetch');

export default function Admin() {
    const wallet = React.useContext(WalletContext);
    const [wax, setWax] = useState<number | undefined>(undefined);
    const [tlm, setTlm] = useState<number | undefined>(undefined);
    const [nfts, setNfts] = useState<Nft[] | undefined>(undefined);
    const requiredTlm = 50 * 5 + 20 * 5;
    const requiredNfts = 5;

    const loadBankBalances = async () => {
        console.log('loading bank balance...');
        const rpc = new JsonRpc('https://wax.greymass.com', {fetch});
        const api = new Api({rpc, textDecoder: new TextDecoder(), textEncoder: new TextEncoder()});
        let account = await api.rpc.get_account(bank_account);
        if (account.core_liquid_balance) {
            let balance: number = +account.core_liquid_balance.replace(' WAX', '');
            setWax(+balance.toFixed(2));
        }
        let currency = await api.rpc.get_currency_balance('alien.worlds', bank_account, 'TLM');
        if (currency.length) {
            let tlm_value = +currency[0].replace(' TLM', '');
            setTlm(tlm_value >= 0.01 ? +tlm_value.toFixed(2) : tlm_value);
        }
    };

    const loadNfts = async () => {
        console.log('Loading nfts...');
        let bank_nfts = await scanNfts(bank_account);
        setNfts(bank_nfts);
    };

    useEffect(() => {
        loadBankBalances();
        loadNfts();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div style={{display: "flex", flexDirection: "row"}}>
            <MainMenu/>
            {isAdmin(wallet.key) ?
                <div style={{width: '660px', padding: '10px 0px 0px 70px'}}>
                    <div style={{fontSize: '30px', marginBottom: '20px'}}>
                        Admin Page
                    </div>

                    {(tlm && wax) ?
                        <div className='flexColumn' style={{fontSize: '20px', marginBottom: '20px', alignItems: 'flex-start', gap: '5px'}}>
                            <div>
                                Bank balance: <a target='bloks' href={bank_url}>{bank_account}</a>
                            </div>

                            <div className='flexRow' style={{justifyContent: 'flex-start'}}>
                                <img alt='wax' height='24px' width='24px' src='wax-32.png'/>
                                <div >{wax} WAX</div>
                            </div>

                            <div className='flexRow' style={{justifyContent: 'flex-start'}}>
                                <img alt='tlm' height='24px' width='24px' src='tlm-32.png'/>
                                <div >{tlm} TLM / ${(tlm * 0.015).toFixed(2)}</div>
                            </div>

                            {nfts &&
                                <div className='flexRow' style={{justifyContent: 'flex-start'}}>
                                    <img alt='nft' height='24px' src={card_back} style={{paddingLeft: '4px', paddingRight: '3px'}}/>
                                    <div>{nfts.length} NFT's</div>
                                </div>
                            }
                            {nfts &&
                                <NftSelector schema={''} nfts={nfts} pagesize={5} height={50}/>
                            }

                            <div style={{marginTop: '20px'}}>
                                Next rewards payments:
                            </div>
                            <div>
                                <div className='flexRow' style={{justifyContent: 'flex-start'}}>
                                    <img alt='tlm' height='24px' width='24px' src='tlm-32.png'/>
                                    <div style={{width: '75px'}}>{requiredTlm} TLM</div>
                                    {tlm >= requiredTlm ? <div style={{color: 'green'}}>OK</div> : <div style={{color: 'red'}}>INSUFFICIENT</div>}
                                </div>
                                <div className='flexRow' style={{justifyContent: 'flex-start'}}>
                                    <img alt='nft' height='24px' src={card_back} style={{paddingLeft: '4px', paddingRight: '3px'}}/>
                                    <div style={{width: '75px'}}>{requiredNfts} NFTs</div>
                                    {nfts && (nfts.length >= requiredNfts ? <div style={{color: 'green'}}>OK</div> : <div style={{color: 'red'}}>INSUFFICIENT</div>)}
                                </div>
                            </div>

                            <div style={{marginTop: '20px'}}>
                                Batch Jobs:
                            </div>
                            <div style={{fontSize: '15px'}}>
                                <Link to='/batchjobs'>&gt;&gt; View Batch Jobs</Link>
                            </div>

                            <div style={{marginTop: '20px'}}>
                                How to play:
                            </div>
                            <div style={{fontSize: '15px'}}>
                                <Link to='/howtoplay'>&gt;&gt; View How to Play</Link>
                            </div>
                        </div>
                    :
                        <div>loading...</div>
                    }
                </div>
            :
                <div style={{width: '660px', padding: '40px 0px 0px 70px'}}>
                    <div style={{fontSize: '30px', marginBottom: '20px'}}>
                        Not authorized
                    </div>
                </div>
            }
        </div>
    );
}

