import { BPlayer, SfxMap } from "./BattleTypes";

const plrByNum = (bplayers: BPlayer[], plr: string) => {
    return bplayers.find(x => x.num === plr.replace('Plr-', ''));
}

const plrByWalletKey = (bplayers: BPlayer[], walletKey: string) => {
    return bplayers.find(x => x.walletKey === walletKey);
}

export function applyEvent(bevent : string, bplayers : BPlayer[], sfx : SfxMap) {
    bplayers.forEach(bplr => {bplr.attacking = false; bplr.defending = false; bplr.animationType = undefined;})
    let tokens = bevent.split(' ');
    switch (bevent.charAt(0)) {
        case 'x':
            let bplr_attacker = plrByNum(bplayers, tokens[1]);
            bplr_attacker!.attacking = true;
            let bplr_defender = plrByNum(bplayers, tokens[3]);
            bplr_defender!.defending = true;
            bplr_defender!.health -= (+tokens[5]);
            bplr_defender!.damaged = true;
            if (bplr_attacker!.attackItem) {
                bplr_defender!.animationType = bplr_attacker!.attackItem;
                sfx[bplr_attacker!.attackItem].play();
            }
            break;
        case '@':
            let bplr_dead = plrByNum(bplayers, tokens[1]);
            bplr_dead!.defending = true;
            bplr_dead!.alive = false;
            break;
        case '!':
            plrByWalletKey(bplayers, tokens[2])!.winner = true;
            sfx['END-01'].play();
            break;
    }
}