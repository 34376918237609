import { useEffect, useState } from "react";

export default function ShopTimeLimit(props: {time: string | null}) {
    const time = props.time;
    const [result, setResult] = useState<string>('');

    useEffect(() => {
        const timeLimit = setInterval(() => {
            timeCalculation();
        }, 1000);
    
        return () => clearInterval(timeLimit);
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps

    function timeCalculation(){
        if (time !== null) {
            let now = new Date(), expires = new Date(time);
            let ms,d,h,m,s;
            ms=expires.getTime()-now.getTime();
            d=parseInt(ms/86400000 + '');
            ms=ms-(d*86400000);
            h=parseInt(ms/3600000 + '');
            ms=ms-(h*3600000);
            m=parseInt(ms/60000 + '');
            ms=ms-(m*60000);
            s=parseInt(ms/1000 + '');
            setResult(`expires in: ${h} : ${m} : ${s} `)
        }
    }

    return (
        <div style={{fontSize: '15px'}}>
            {result}
        </div>
    );
}