import { card_common, card_epic, card_legendary, card_mythical, card_rare } from "../../helpers/Config";

export default function RepairCosts() {
    return (
        <table style={{borderSpacing: '10px'}}>
            <tr>
                <td><img alt='' height='36px' title='Common card' src={card_common} style={{verticalAlign: 'middle'}} /></td>
                <td>Common cards</td>
                <td><img alt='' height='28px' src='tlm-32.png' style={{verticalAlign: 'middle'}} /></td>
                <td>1 TLM</td>
            </tr>
            <tr>
                <td><img alt='' height='36px' title='Rare card' src={card_rare} style={{verticalAlign: 'middle'}} /></td>
                <td>Rare cards</td>
                <td><img alt='' height='28px' src='tlm-32.png' style={{verticalAlign: 'middle'}} /></td>
                <td>2 TLM</td>
            </tr>
            <tr>
                <td><img alt='' height='36px' title='Epic card' src={card_epic} style={{verticalAlign: 'middle'}} /></td>
                <td>Epic cards</td>
                <td><img alt='' height='28px' src='tlm-32.png' style={{verticalAlign: 'middle'}} /></td>
                <td>3 TLM</td>
            </tr>
            <tr>
                <td><img alt='' height='36px' title='Legendary card' src={card_legendary} style={{verticalAlign: 'middle'}} /></td>
                <td>Legendary cards</td>
                <td><img alt='' height='28px' src='tlm-32.png' style={{verticalAlign: 'middle'}} /></td>
                <td>5 TLM</td>
            </tr>
            <tr>
                <td><img alt='' height='36px' title='Mythical card' src={card_mythical} style={{verticalAlign: 'middle'}} /></td>
                <td>Mythical cards</td>
                <td><img alt='' height='28px' src='tlm-32.png' style={{verticalAlign: 'middle'}} /></td>
                <td>10 TLM</td>
            </tr>
        </table>
    );
}