import HistoryGameLine from "./HistoryGamePlacingsLine";
import { Game } from "../../helpers/Types";

export default function HistoryGamePlacings(props: {game: Game}) {

    const game = props.game;
    const numPlayers = game.registrations.length;

    return (
        <div style={{marginTop: '30px', display: 'flex', flexDirection: 'column', gap: '3px', height: '390px', overflowY: 'scroll'}}>
            {game.registrations.map(player =>
                <HistoryGameLine key={player.placing} player={player} game={game} numPlayers={numPlayers}/>
            )}
        </div>

    );
}