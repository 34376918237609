import MainMenu from "../components/MainMenu";
import ConnectWallet from "../components/ConnectWallet";
import React, { useEffect, useState } from "react";
import { WalletContext } from "../main/App";
import { scanNfts, sendNft } from "../methods/Nfts";
import NftSelector from "../components/NftSelector";
import Nft from "../helpers/Types";
import Popup from "../components/Popup";
import { nftImage, rarityColor } from "../helpers/Utils";
import { fn_url, token } from "../helpers/Config";

export default function Boost() {
    const wallet = React.useContext(WalletContext);
    const [boosts, setBoosts] = useState<any[] | undefined>(undefined);
    const [isPopup, setIsPopup] = useState<boolean>(false);
    const [selectedNFt, setSelectedNft] = useState<Nft | undefined>(undefined);

    const loadBoosts = async (key: string | undefined) => {
        console.log('loadBoosts: ' + key);
        if (!key) return;
        let boost_nfts = await scanNfts(key, 'battledomegg', 'boosts');
        setBoosts(boost_nfts);
    }

    useEffect(() => {
        loadBoosts(wallet.key);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const selectBoost = async (nft: Nft) => {
        console.log('this boost was selected: ' + JSON.stringify(nft));
        setIsPopup(true);
        setSelectedNft(nft);
    }

    const handleClosePopup = () => {
        setIsPopup(false);
        setSelectedNft(undefined);
    }

    const performActivateBoost = async () => {
        if (!wallet.key || !selectedNFt) return;

        let signature = await sendNft(wallet.key, 'boosts.dome', +selectedNFt.asset_id, 'Activate boost in Battledome');
        if (!signature) return;
        // backend call, sending tx signature
        var registerResp = await fetch(fn_url + "boostactivate", {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'text/plain'},
            body: JSON.stringify({
                walletKey: wallet.key,
                asset_id: selectedNFt.asset_id,
                name: selectedNFt.name,
                signature: signature,
            })
        });

        if (!registerResp.ok) {
            handleClosePopup();
        } else {
            handleClosePopup();
            loadBoosts(wallet.key);
            wallet.player.refresh();
            // let playerdata = await fetch(fn_url + "playerdata?walletKey=" + wallet.key).then(response => response.json());
            // setTokens(playerdata.rewards.balance);
        }
    }

    return (
        <div style={{display: "flex", flexDirection: "row"}}>

            {isPopup && selectedNFt &&
                <Popup style={{left: '477px'}} handleClose={handleClosePopup} content={
                    <div className='flexColumn'>
                        <div style={{fontSize: '28px'}}>Activate this boost?</div>
                        <div className='flexRow' style={{minWidth: '200px', padding: '20px', color: 'white', backgroundColor: '#1a203c', borderRadius: '20px', gap: '25px'}}>
                            <div className='flexColumn gap5'>
                                <img alt='' src={nftImage(selectedNFt.image)} style={{height: '150px', objectFit: 'contain'}}/>
                                <div style={{color: rarityColor('rare')}}>{selectedNFt.name}</div>
                            </div>
                        </div>
                        <div className='flexColumn'>
                            <div>You will receive: 20 Skullz</div>
                            <div>The boost will be consumed when you activate it.</div>
                            <div className='flexRow'>
                                <button onClick={() => handleClosePopup()} className="clearButton" style={{cursor: 'pointer'}}>Cancel</button>
                                <button onClick={() => performActivateBoost()} className="clearButton" style={{cursor: 'pointer'}}>Activate</button>
                            </div>
                        </div>
                    </div>
                }/>
            }

            <MainMenu/>
            <div style={{width: '660px', padding: '10px 0px 0px 70px', fontSize: '20px', textAlign: 'center'}}>
                {wallet.key ?
                    <div className='flexColumn'>
                        {boosts ?
                            <>
                                <div>Need a boost? Boost NFTs are available on Atomichub.io</div>
                                <div style={{marginTop: '5px'}}>
                                    <a href="https://wax.atomichub.io/market?collection_name=battledomegg&schema_name=boosts" target="atomichub">
                                        <button className="clearButton">Buy Boosts</button>
                                    </a>
                                </div>
                                <div>You have {boosts?.length} Boost NFTs</div>
                                <NftSelector schema={'boosts'} nfts={boosts} pagesize={5} height={150} selectFn={selectBoost}/>
                                {boosts.length > 0 && <div>Click on a boost to activate it.</div>}

                                <div>You have no active boosts.</div>

                                {wallet.player.tokens !== undefined &&
                                    <div className='flexRow'>
                                        <div>Your balance is:</div>
                                        <img alt="" src="token-32.png" height="30px" width="30px"/>
                                        <div>{wallet.player.tokens} {token}</div>
                                    </div>
                                }
                            </>
                            :
                            <div>Loading...</div>
                        }
                    </div>
                    :
                    <ConnectWallet onConnectFn={loadBoosts}/>
                }
            </div>
        </div>
    );
}