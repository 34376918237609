export default function TabPrizes() {
    return (
        <div className='flexColumn' style={{fontSize: '18px', width: '60%', marginTop: '15px'}}>
            <div>Winner takes all! The winning planetary syndicate will receive:</div>

            <div className='flexRow'>
                <div className='flexRow gap5'>
                    <img alt='' height='32px' width='24px' src='/cardback-50.png'/>
                    <div>50 NFTs</div>
                </div>
                <div className='flexRow gap5'>
                    <img alt='' height='32px' width='32px' src='/token-50.png'/>
                    <div>5.000 Skullz</div>
                </div>
                <div className='flexRow gap5'>
                    <img alt='' height='32px' width='32px' src='/tlm-50.png'/>
                    <div>50.000 TLM</div>
                </div>
            </div>

            <div>The winning syndicate will also take over administration of the next Battledome tournament, defining the prizes and running the tournament!</div>

            <div style={{marginTop: '15px'}}>Individual rewards - the top 3 players for each planet will receive:</div>

            <div className='flexRow'>
                <div className='flexRow gap5'>
                    <img alt='' height='32px' width='32px' src='/league-advanced-50.png'/>
                    <div>Commemorative NFT</div>
                </div>
                <div className='flexRow gap5'>
                    <img alt='' height='32px' width='32px' src='/token-50.png'/>
                    <div>200 Skullz</div>
                </div>
                <div className='flexRow gap5'>
                    <img alt='' height='32px' width='32px' src='/tlm-50.png'/>
                    <div>2.000 TLM</div>
                </div>
            </div>

        </div>
    )
}