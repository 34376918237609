import MainMenu from "../components/MainMenu";
import React, { useState } from "react";
import { token } from "../helpers/Config";

export default function HowToPlay() {
    const [page, setPage] = useState(0);

    const prevPage = async () => {
        setPage(page - 1);
    }

    const nextPage = async () => {
        setPage((page + 1) % 16);
    }

    return (
        <div style={{display: "flex", flexDirection: "row"}}>
            <MainMenu/>
            {page === 0 &&
                <div style={{width: '660px', padding: '10px 0px 0px 70px', fontSize: '20px'}}>
                    <div style={{fontSize: '30px', marginBottom: '20px'}}>
                        How to Play
                    </div>
                    <div style={{marginBottom: '15px'}}>
                        Welcome to Battledome!
                    </div>
                    <div style={{marginBottom: '15px', width: '500px'}}>
                        <iframe height="337px" width="600px" allow="accelerometer;encrypted-media;gyroscope;picture-in-picture"
                                title="Play Battledome"
                                src="https://www.youtube.com/embed/dH3pBamznMc?autohide=1&amp;border=0&amp;wmode=opaque&amp;playsinline=1"
                                className="tf_abs tf_w tf_h"></iframe>
                    </div>

                    <button className="playButton" onClick={nextPage}>NEXT</button>
                </div>
            }
            {page === 1 &&
                <div style={{width: '660px', padding: '10px 0px 0px 70px', fontSize: '20px'}}>
                    <div style={{fontSize: '30px', marginBottom: '20px'}}>
                        How to Play - Season 2
                    </div>
                    <div style={{marginBottom: '15px'}}>
                        Season 2 runs from December 16 to 31
                    </div>
                    <div style={{marginBottom: '15px', width: '500px'}}>
                        <iframe height="337px" width="600px" allow="accelerometer;encrypted-media;gyroscope;picture-in-picture"
                                title="Battledome Season 2"
                                src="https://www.youtube.com/embed/OmX3nMnPctY?autohide=1&amp;border=0&amp;wmode=opaque&amp;playsinline=1"
                                className="tf_abs tf_w tf_h"></iframe>
                    </div>


                    <div className='flexRow' style={{justifyContent: 'flex-start'}}>
                        <button className="playButton" onClick={prevPage}>PREV</button>
                        <button className="playButton" onClick={nextPage}>NEXT</button>
                    </div>
                </div>
            }
            {page === 2 &&
                <div style={{width: '660px', padding: '10px 0px 0px 70px', fontSize: '20px'}}>
                    <div style={{fontSize: '30px', marginBottom: '20px'}}>
                        How to Play - New Season Rewards
                    </div>

                    <div style={{marginBottom: '15px'}}>
                        Do you have what it takes to win prizes on the Battledome leaderboard?
                    </div>
                    <div className='flexRow' style={{marginBottom: '15px'}}>
                        <img alt='' height='50px' src='icon-attack-50.png'/>
                    </div>

                    <div style={{marginBottom: '15px'}}>
                        December 16 to 31 season of battle dome will include EPIC nft rewards:
                    </div>
                    <div className='flexRow' style={{marginBottom: '15px'}}>
                        <td><img alt='' height='100px' title='Epic card' src='https://atomichub-ipfs.com/ipfs/Qme6fvdPzMbJ7zip2c33JPizGgL6P6uMkLz5HpXyrFfG3a' style={{verticalAlign: 'middle'}} /></td>
                        <td><img alt='' height='100px' title='Epic card' src='https://atomichub-ipfs.com/ipfs/Qme6fvdPzMbJ7zip2c33JPizGgL6P6uMkLz5HpXyrFfG3a' style={{verticalAlign: 'middle'}} /></td>
                        <td><img alt='' height='100px' title='Epic card' src='https://atomichub-ipfs.com/ipfs/Qme6fvdPzMbJ7zip2c33JPizGgL6P6uMkLz5HpXyrFfG3a' style={{verticalAlign: 'middle'}} /></td>
                        <td><img alt='' height='100px' title='Epic card' src='https://atomichub-ipfs.com/ipfs/Qme6fvdPzMbJ7zip2c33JPizGgL6P6uMkLz5HpXyrFfG3a' style={{verticalAlign: 'middle'}} /></td>
                        <td><img alt='' height='100px' title='Epic card' src='https://atomichub-ipfs.com/ipfs/Qme6fvdPzMbJ7zip2c33JPizGgL6P6uMkLz5HpXyrFfG3a' style={{verticalAlign: 'middle'}} /></td>
                    </div>
                    <div style={{marginBottom: '15px'}}>
                        Welcome back all players to season 2 of Battledome, starting December 16!
                    </div>

                    <div className='flexRow' style={{justifyContent: 'flex-start'}}>
                        <button className="playButton" onClick={prevPage}>PREV</button>
                        <button className="playButton" onClick={nextPage}>NEXT</button>
                    </div>
                </div>
            }
            {page === 3 &&
                <div style={{width: '660px', padding: '10px 0px 0px 70px', fontSize: '20px'}}>
                    <div style={{fontSize: '30px', marginBottom: '20px'}}>
                        How to Play
                    </div>
                    <div style={{marginBottom: '15px'}}>
                        Battledome is a free-to-play, strategic space combat game set in the Alien Worlds metaverse.
                    </div>
                    <div style={{marginBottom: '15px'}}>
                        Enter the Battledome with your Alien Worlds NFT's - up to 20 players can fight in each
                        battle, one player emerges victorious!
                    </div>
                    <div style={{marginBottom: '15px'}}>
                        Anyone with a WAX cloud wallet and 3 Alien Worlds NFT's can play :)
                    </div>
                    <div style={{marginBottom: '15px'}}>
                        Strategic play includes battle orders, planetary alignment (allowing players to form alliances)
                        and a battledome phase that changes every hour (granting bonuses to NFT's matching that element).
                    </div>
                    <div style={{marginBottom: '15px'}}>
                        Battle your way to the top of the leaderboard - the winners each month will receive awesome
                        prizes including NFT's, Trilium (TLM) and boosts.
                    </div>
                    <div className='flexRow' style={{justifyContent: 'flex-start'}}>
                        <button className="playButton" onClick={prevPage}>PREV</button>
                        <button className="playButton" onClick={nextPage}>NEXT</button>
                    </div>
                </div>
            }
            {page === 4 &&
                <div style={{width: '660px', padding: '10px 0px 0px 70px', fontSize: '20px'}}>
                    <div style={{fontSize: '30px', marginBottom: '20px'}}>
                        How to Play - New Rules
                    </div>
                    <div style={{marginBottom: '15px'}}>
                        We have added 2 new rules to the game to help game balance.
                    </div>
                    <div style={{marginBottom: '15px'}}>
                        1. Stealth bonus. If you are the only person (Solo), of if you and another person are the only
                        two players (Duo) representing a planetary alignment at the start of a battle, you will receive bonuses for
                        the duration of the battle.
                    </div>
                    <div style={{marginBottom: '15px'}}>
                        Duo players receive "stealth bonus": +5/+5
                    </div>
                    <div style={{marginBottom: '15px'}}>
                        Solo players receive "stealth bonus x2": +10/+10
                    </div>
                    <div style={{marginBottom: '15px'}}>
                        2. Dominance. For each round of a battle, dominance is evaluated. If a particular planetary alliance
                        has more players alive than all the other players alive at the start of any round, that planetary alliance
                        has "dominance".
                    </div>
                    <div style={{marginBottom: '15px'}}>
                        In a round where "dominance" is declared, players from all planets OTHER than the dominant planetary alliance
                        will: A. attack first in the round, and B. target the dominant planetary alliance.
                    </div>
                    <div className='flexRow' style={{justifyContent: 'flex-start'}}>
                        <button className="playButton" onClick={prevPage}>PREV</button>
                        <button className="playButton" onClick={nextPage}>NEXT</button>
                    </div>
                </div>
            }
            {page === 5 &&
                <div style={{width: '660px', padding: '10px 0px 0px 70px'}}>
                    <div style={{fontSize: '30px', marginBottom: '20px'}}>
                        How to Play - Game Lobby
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        The game lobby shows the next battledome game, and the 2 previously played games. Each game
                        has a unique name, and will start automatically when 5 players are registered.
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        To join the next game - click "REGISTER", choose your NFT's and strategy, and authorize with your
                        wallet. Battledome games are free-to-play / f2p.
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        When the game starts, your NFT's battle the other players automatically - click "PLAY" to see who wins the battle :)
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        Previously played games are marked "game finished" and you can click "REPLAY" to watch a replay of any game later.
                    </div>
                    <div className='flexRow' style={{justifyContent: 'flex-start'}}>
                        <button className="playButton" onClick={prevPage}>PREV</button>
                        <button className="playButton" onClick={nextPage}>NEXT</button>
                    </div>
                </div>
            }
            {page === 6 &&
                <div style={{width: '660px', padding: '10px 0px 0px 70px'}}>
                    <div style={{fontSize: '30px', marginBottom: '20px'}}>
                        How to Play - My NFTs
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        Connect your wallet and go to "My NFTs" to see the NFT's in your collection that you can play in Battledome.
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        There are 3 categories: Avatars, Minions and Weapons (you need one of each).
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        Click on "Browse Avatars", "Browse Minions" and "Browse Weapons" to redirect to the NFT marketplace
                        on atomichub.io where you can buy more NFT's (sort by lowest price to get the best deals).
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        Purchase NFT's in the same WAX cloud wallet that you play battledome - then hit "refresh" on our site and
                        your NFT's will be available to play in the game!
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        Artifacts are not yet implemented and will come in a future update :)
                    </div>
                    <div className='flexRow' style={{justifyContent: 'flex-start'}}>
                        <button className="playButton" onClick={prevPage}>PREV</button>
                        <button className="playButton" onClick={nextPage}>NEXT</button>
                    </div>
                </div>
            }
            {page === 7 &&
                <div style={{width: '660px', padding: '10px 0px 0px 70px'}}>
                    <div style={{fontSize: '30px', marginBottom: '20px'}}>
                        How to Play - Battle History
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        You can see the recently completed Battledome games here.
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        Each game has a winner, who is credited with a win on the Leaderboard - you can also see the planetary alignment of the winning player.
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        Click on the name of a game in the Battle History to see the player placings or to watch a replay.
                    </div>
                    <div className='flexRow' style={{justifyContent: 'flex-start'}}>
                        <button className="playButton" onClick={prevPage}>PREV</button>
                        <button className="playButton" onClick={nextPage}>NEXT</button>
                    </div>
                </div>
            }
            {page === 8 &&
                <div style={{width: '660px', padding: '10px 0px 0px 70px'}}>
                    <div style={{fontSize: '30px', marginBottom: '20px'}}>
                        How to Play - Leaderboard
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        The players with the most wins each month compete for the top rankings in the leaderboard.
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        Tie-breakers for players with the same number of wins are decided by the number of kills in winning games,
                        then by the total remaining health in winning games, or then finally by wallet address (alphabetically, a to z).
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        There is also a leaderboard for planetary wins, showing the planets with the most wins.
                    </div>
                    <div className='flexRow' style={{justifyContent: 'flex-start'}}>
                        <button className="playButton" onClick={prevPage}>PREV</button>
                        <button className="playButton" onClick={nextPage}>NEXT</button>
                    </div>
                </div>
            }
            {page === 9 &&
                <div style={{width: '660px', padding: '10px 0px 0px 70px'}}>
                    <div style={{fontSize: '30px', marginBottom: '20px'}}>
                        How to Play - Rewards
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        The top players in the leaderboard at the end of each month receive rewards.
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        There are three tiers of rewards: Gold, Silver and Bronze.
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        Gold rewards are the very best, and may include NFT's, Trilium, boosts.
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        Rewards are transferred automatically to the wallet address of the winning players on the 1st
                        day of each month at 9am UTC.
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        Rewards are paid out from the account 'battle.dome', viewable <a target='bloks' href='https://wax.bloks.io/account/battle.dome'>here</a>.
                    </div>
                    <div className='flexRow' style={{justifyContent: 'flex-start'}}>
                        <button className="playButton" onClick={prevPage}>PREV</button>
                        <button className="playButton" onClick={nextPage}>NEXT</button>
                    </div>
                </div>
            }
            {page === 10 &&
                <div style={{width: '660px', padding: '10px 0px 0px 70px'}}>
                    <div style={{fontSize: '30px', marginBottom: '20px'}}>
                        How to Play - Repairs
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        When your NFT's play in a battle and you are not the winner, they become damaged and you need to
                        repair them before they can play again.
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        You can simply wait 23 hours, 59 minutes and 59 seconds and the NFT will repair automatically.
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        Or you can choose to spend Trilium to repair the NFT, based on it's rarity.
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        Repair costs are 1 TLM (Common), 2 TLM (Rare), 3 TLM (Epic), 5 TLM (Legendary) and 10 TLM (Mythical).
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        If you pay TLM to repair an NFT, you can instantly play the NFT again in the next battledome game.
                    </div>
                    <div className='flexRow' style={{justifyContent: 'flex-start'}}>
                        <button className="playButton" onClick={prevPage}>PREV</button>
                        <button className="playButton" onClick={nextPage}>NEXT</button>
                    </div>
                </div>
            }
            {page === 11 &&
                <div style={{width: '660px', padding: '10px 0px 0px 70px'}}>
                    <div style={{fontSize: '30px', marginBottom: '20px'}}>
                        How to Play - Shop
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        An in-game shop is coming soon, with new items to use in Battledome!
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        Here you will be able to spend "{token}" (the in-game rewards) on cosmetics, powerups, and even new NFTS!
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        Look forward to an exciting party when we launch the shop :)
                    </div>
                    <div className='flexRow' style={{justifyContent: 'flex-start'}}>
                        <button className="playButton" onClick={prevPage}>PREV</button>
                        <button className="playButton" onClick={nextPage}>NEXT</button>
                    </div>
                </div>
            }
            {page === 12 &&
                <div style={{width: '660px', padding: '10px 0px 0px 70px'}}>
                    <div style={{fontSize: '30px', marginBottom: '20px'}}>
                        Something Wrong?
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        If you experience a problem with the game, please
                        contact us <a target='discord' href='https://discord.gg/restackai'>on discord</a> and
                        our team will assist you.
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        Tell us: which game you entered, your wallet address, and the nature of the problem.
                    </div>
                    <div className='flexRow' style={{justifyContent: 'flex-start'}}>
                        <button className="playButton" onClick={prevPage}>PREV</button>
                        <button className="playButton" onClick={nextPage}>NEXT</button>
                    </div>
                </div>
            }
            {page === 13 &&
                <div style={{width: '660px', padding: '10px 0px 0px 70px'}}>
                    <div style={{fontSize: '30px', marginBottom: '20px'}}>
                        Your Data
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        Battledome collects data from you necessary to play the game and by using our web site you
                        agree to this.
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        When you connect, battledome inspects your WAX wallet, including which NFT's you own and your
                        token balances.
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        When you Register to play a game, battledome registers your wallet address, token
                        balances and where you are connecting from.
                    </div>
                    <div className='flexRow' style={{justifyContent: 'flex-start'}}>
                        <button className="playButton" onClick={prevPage}>PREV</button>
                        <button className="playButton" onClick={nextPage}>NEXT</button>
                    </div>
                </div>
            }
            {page === 14 &&
                <div style={{width: '660px', padding: '10px 0px 0px 70px'}}>
                    <div style={{fontSize: '30px', marginBottom: '20px'}}>
                        Credits
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        Battledome is a creative project by enthusiasts in the Alien Worlds metaverse.
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        We hope you like the game, it was developed by Alex and Tr0n from <a href='https://restack.ai' target='restack' >Restack.AI</a>,
                        thanks to a pioneer grant from the Alien Worlds Galactic Hubs foundation.
                    </div>
                    <div className='flexRow' style={{marginBottom: '15px'}}>
                        <img alt='RestackAI' height='50px' src='https://restack.ai/wp-content/uploads/2022/09/RestackLogo-2.png'/>
                    </div>
                    <div className='flexRow' style={{marginBottom: '15px'}}>
                        <div className='flexColumn'>
                            <img alt='Alex' height='150px' src='https://restack.ai/wp-content/uploads/2022/10/Alex-white-rounded.png'/>
                            <div style={{fontFamily: 'AlienWorld', color: 'blueviolet'}}>Alex</div>
                        </div>
                        <div className='flexColumn'>
                            <img alt='Tr0n' height='150px' src='https://restack.ai/wp-content/uploads/2022/10/Tr0n-rounded.png'/>
                            <div style={{fontFamily: 'AlienWorld', color: 'blueviolet'}}>Tr0n</div>
                        </div>
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        Thanks for playing!
                    </div>
                    <div className='flexRow' style={{justifyContent: 'flex-start'}}>
                        <button className="playButton" onClick={prevPage}>PREV</button>
                        <button className="playButton" onClick={nextPage}>NEXT</button>
                    </div>
                </div>
            }
            {page === 15 &&
                <div style={{width: '660px', padding: '10px 0px 0px 70px'}}>
                    <div style={{fontSize: '30px', marginBottom: '20px'}}>
                      Restack LLC
                    </div>
                    <div className='flexColumn gap5' style={{marginBottom: '15px'}}>
                        <img alt='' src='/restackcoin.png'/>
                        <div style={{fontSize: '20px'}}>
                          Company CEO: Alexander Grover
                        </div>
                        <div style={{fontSize: '20px'}}>
                          Zelena 204A-7, Lviv, 79028 Ukraine
                        </div>
                        <div style={{fontSize: '20px', marginBottom: '20px'}}>
                          Registration 44444416
                        </div>
                    </div>
                    <div className='flexRow' style={{justifyContent: 'flex-start'}}>
                        <button className="playButton" onClick={prevPage}>PREV</button>
                        <button className="playButton" onClick={nextPage}>NEXT</button>
                    </div>
                </div>
            }
        </div>
    );
}

