import { card_size_ratio, orders } from "../../helpers/Config";
import { ordersImage, ordersName } from "../../helpers/Utils";

export default function OrdersSelector(props: {selectFn?: any }) {
    const height = 150;
    const width = height * card_size_ratio;

    return (
        <div>
            <div className='flexRow'>
                {orders.map(x =>
                    <div key={x} className='flexColumn' style={{fontSize: '20px', marginBottom: '20px'}}>
                        {/*<img alt='nft' title={modeName(x)} height='150px' src={'/' + x.toLowerCase() + '.png'} onClick={() => props.selectFn(x)}/>*/}
                        <img alt='' title={ordersName(x)} height={height+'px'} width={width+'px'} src={ordersImage(x)} onClick={() => props.selectFn(x)}/>
                        {/*<div style={{fontSize: '15px'}}>{modeName(x)}</div>*/}
                    </div>
                )}
            </div>
        </div>
    );
}
