import React from "react";
import { WalletContext } from "../main/App";

export default function WalletInfo() {
    const wallet = React.useContext(WalletContext);
    return (
        wallet.player.nfts === undefined ? <div style={{height: '83px'}}><p style={{textAlign: 'center'}}>loading NFTs...</p></div> :
            <div style={{marginTop: '20px', marginBottom: '10px'}}>
                <div className='flexColumn' style={{gap: '5px'}}>
                    <div style={{fontWeight: 'bold'}}>
                        { wallet.player.tag ? 'Welcome, ' + wallet.player.tag + '.' : 'Your wallet:' }
                    </div>
                    <div className='flexRow'>
                        <img alt='' height='15px' width='15px' src='wax-32.png'/>
                        <div>{wallet.player.wax} WAX</div>
                    </div>
                    <div className='flexRow'>
                        <img alt='' height='20px' width='20px' src='tlm-32.png'/>
                        <div>{wallet.player.tlm} TLM</div>
                    </div>
                    <div>{wallet.player.nfts.length} alien.worlds NFT's</div>
                </div>
            </div>
    );
}
