import { purchase } from "../../methods/Bank";
import { fn_url, token } from "../../helpers/Config";
import React, { useState } from "react";
import { WalletContext } from "../../main/App";
import { TokenPack } from "../../helpers/Types";

export default function TokenPackPanel(props: {tokenPack: TokenPack}) {
    let wallet = React.useContext(WalletContext);
    let pack = props.tokenPack;

    const [saveSending, setSaveSending] = useState<boolean | undefined>(undefined);
    const [saveError, setSaveError] = useState<boolean | undefined>(undefined);
    const [saveOkay, setSaveOkay] = useState<boolean | undefined>(undefined);

    async function buyPack() {
        if (!wallet.key) return;
        console.log('buying tokenPack: ' + pack.name + ' for ' + pack.price);

        let price = pack.price;
        if (price <= 0) return;

        let signature = await purchase(wallet.key, price, 'TLM', pack.name);
        if (!signature) {
            return;
        }

        console.log('sending repairallsave...');
        setSaveSending(true);
        // var response = await fetch('https://localhost:8888/.netlify/functions/' + "tokensbuy", {
        var response = await fetch(fn_url + "tokensbuy", {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'text/plain'},
            body: JSON.stringify({
                walletKey: wallet.key,
                item_id: pack.id,
                price: pack.price,
                signature: signature,
            })
        });
        console.log('ok: ' + response.ok);

        if (!response.ok) {
            setSaveError(true);
        } else {
            wallet.player.refresh();
            setSaveOkay(true);
            console.log('token pack ' + pack.name + ' was purchased.');
        }
    }

    return (
        <div className='flexRow' style={{width: '280px', padding: '20px', backgroundColor: '#1a203c', borderRadius: '20px', gap: '30px'}} key={pack.id}>
            <div className='flexColumn gap5'>
                <div style={{fontSize: '20px', fontWeight: 'bold', whiteSpace: 'nowrap'}}>{pack.name}</div>
                <div className="flexRow" style={{gap: '7px'}}>
                    Price:
                    <img alt="" src="tlm-32.png" height="32px" width="32px"/>
                    {pack.price} TLM
                </div>
                {pack.bonusPct > 0 && <div style={{color: 'cyan', fontSize: '16px', fontStyle: 'italic'}}>{pack.bonusPct} % extra Skullz with this pack!</div>}
                <div className="flexRow" style={{gap: '7px'}}>
                    You receive:
                    <img alt="" src="token-32.png" height="32px" width="32px"/>
                    {pack.tokens} {token}
                </div>
                <div className='flexColumn' style={{width: '150px', marginTop: '10px'}}>
                    {
                        saveError ? <div style={{color: 'red'}}>ERROR</div> :
                            saveOkay ? <div>Done :)</div> :
                                saveSending ? <div>Sending...</div> :
                                    <button className='playButton' style={{width: '150px'}} onClick={() => buyPack()}>Buy this pack</button>
                    }
                </div>
            </div>
        </div>
    );
}