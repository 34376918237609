import { League } from "../../helpers/Types";
import { leagueIcon } from "../../helpers/Utils";
import React from "react";

export default function LeagueSelector(props: {league: League, setLeague: any}) {
    return (
        <div className='flexRow gap15' style={{marginLeft: '8px'}}>
            <button
                className={`tabButton`}
                onClick={() => props.setLeague(League.BEGINNER)}
                style={{backgroundColor: props.league !== League.BEGINNER ? 'grey' : '' }}
                title="Beginner League"
            >
                <div className='flexRow gap10'>
                    {props.league === League.BEGINNER && (<img alt='' height='32px' width='32px' src={leagueIcon(League.BEGINNER)}/>)}
                    <div className='flexColumn gap0'>
                        <div>Beginner</div>
                        <div>League</div>
                    </div>
                    {props.league === League.BEGINNER && (<img alt='' height='32px' width='32px' src={leagueIcon(League.BEGINNER)}/>)}
                </div>
            </button>
            <button className={`tabButton`}
                    onClick={() => props.setLeague(League.ADVANCED)}
                    style={{backgroundColor: props.league !== League.ADVANCED ? 'grey': '' }}
                    title="Advanced League">
                <div className='flexRow gap10'>
                    {props.league === League.ADVANCED && (<img alt='' height='32px' width='32px' src={leagueIcon(League.ADVANCED)}/>)}
                    <div className='flexColumn gap0'>
                        <div>Advanced</div>
                        <div>League</div>
                    </div>
                    {props.league === League.ADVANCED && (<img alt='' height='32px' width='32px' src={leagueIcon(League.ADVANCED)}/>)}
                </div>
            </button>
        </div>

    );
}