import { nfts_avatars, nfts_minions, nfts_weapons } from "./NftCache";
import { card_back, image_base } from "./Config";
import { BPlayer } from "../components/Battle/BattleTypes";
import { League } from "./Types";

export function trim(value: string | undefined, len: number) {
    if (value === undefined) return undefined;
    return (value.length > len ? value.slice(0, len) + '...' : value);
}

export function playerName(bplr: BPlayer) {
    return trim(bplr.tag, 8) || bplr.walletKey;
}

export function rarityLevel(rarity: string) {
    return rarity === 'Mythical' ? 5
        : rarity === 'Legendary' ? 4
        : rarity === 'Epic' ? 3
        : rarity === 'Rare' ? 2
        : rarity === 'Abundant' ? 1
        : 0
}

export function repairCost(rarity: string) {
    return rarity === 'Mythical' ? 10
        : rarity === 'Legendary' ? 5
        : rarity === 'Epic' ? 3
        : rarity === 'Rare' ? 2
        : 1
}

export function imageForAvatar(id: string) {
    let avatar = nfts_avatars.find(x => x.id === id);
    return avatar ? image_base + avatar.img : card_back;
}

export function imageForTeam(team: string) {
    return '/' + team.toLowerCase() + '_icon.png';
}

export function nameForMinion(selection: string) {
    let id = selection.split(' ')[1].split('/')[0];
    let minion = nfts_minions.find(x => x.id === id);
    return minion ? minion.name : 'Unknown';
}

export function imageForMinion(selection: string) {
    let id = selection.split(' ')[1].split('/')[0];
    let minion = nfts_minions.find(x => x.id === id);
    return minion ? image_base + minion.img : card_back;
}

export function nameForWeapon(selection: string) {
    let id = selection.split(' ')[2].split('/')[0];
    let weapon = nfts_weapons.find(x => x.id === id);
    return weapon ? weapon.name : 'Unknown';
}

export function imageForWeapon(selection: string) {
    let id = selection.split(' ')[2].split('/')[0];
    let weapon = nfts_weapons.find(x => x.id === id);
    return weapon ? image_base + weapon.img : card_back;
}

export function ordersName(orders: string) {
    return orders === 'ORDERS_EVADE' ? 'Evade' :
        orders === 'ORDERS_DEFENCE' ? 'Defence' :
        orders === 'ORDERS_TARGETING' ? 'Targeting' :
        orders === 'ORDERS_ATK_STR' ? 'Attack Strongest' :
        orders === 'ORDERS_ATK_WEAK' ? 'Attack Weakest' :
        'Unknown'
}

export function ordersImage(orders: string) {
    return orders.toLowerCase() + '.png';
}

export function planetName(planet: string) {
    return planet === 'PLANET_1' ? 'Eyeke' :
        planet === 'PLANET_2' ? 'Kavian' :
        planet === 'PLANET_3' ? 'Magor' :
        planet === 'PLANET_4' ? 'Naron' :
        planet === 'PLANET_5' ? 'Neri' :
        planet === 'PLANET_6' ? 'Veles' :
        'Unknown'
}

export function planetImage(planet: string) {
    return planet.toLowerCase() + '.png';
}

export function planetIcon(planet: string) {
    return planet.toLowerCase() + '_icon.png';
}

export function getPhase(date: Date) {
    return date.getUTCHours() % 5;
}

export function phaseName(phase: number) {
    return phase === 0 ? 'Air' :
        phase === 1 ? 'Fire' :
        phase === 2 ? 'Gem' :
        phase === 3 ? 'Metal' :
        phase === 4 ? 'Nature' :
        'Unknown'
}

export function phaseColor(phase: number) {
    return phase === 0 ? 'white' :
        phase === 1 ? 'darkred' :
        phase === 2 ? 'darkblue' :
        phase === 3 ? 'rebeccapurple' :
        phase === 4 ? 'darkgreen' :
        'Unknown'
}

export function itemImage(planet: string) {
    return 'items/' + planet.toLowerCase() + '.svg';
}

export function rarityColor(rarity: string) {
    rarity = rarity.toLowerCase();
    return rarity === "legendary" ? '#ffc128' : rarity === "epic" ? 'rgb(217,8,250)' : rarity === "common" ? 'rgb(185,182,182)' : rarity === "rare" ? 'rgb(30,113,229)' : 'rgb(155 165 157)';
}

export function nftImage(image: string) {
    return image_base + image;
}

export function leagueName(league: League) {
    return league === League.BEGINNER ? 'Beginner League'
         : league === League.ADVANCED ? 'Advanced  League'
         : 'Unknown';
}

export function leagueIcon(league: League, size: number = 32) {
    return league === League.BEGINNER ? 'league-beginner-' + size + '.png'
         : league === League.ADVANCED ? 'league-advanced-' + size + '.png'
         : 'Unknown';
}
