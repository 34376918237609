import React from "react";
import TabPlayersPanel from "./TabPlayersPanel";

export default function TabPlayers(props: {topplayers: any[]}) {
    return (
        <div className='flexRow' style={{marginTop: '25px'}}>
            <div className='flexColumn'>
                <div className='flexColumn'>
                    <TabPlayersPanel key={1} team={'PLANET_1'} players={props.topplayers[0].filter((x:any) => x.award)}/>
                </div>
                <div className='flexColumn'>
                    <TabPlayersPanel key={2} team={'PLANET_2'} players={props.topplayers[1].filter((x:any) => x.award)}/>
                </div>
                <div className='flexColumn'>
                    <TabPlayersPanel key={3} team={'PLANET_3'} players={props.topplayers[2].filter((x:any) => x.award)}/>
                </div>
            </div>
            <div className='flexColumn'>
                <div className='flexColumn'>
                    <TabPlayersPanel key={4} team={'PLANET_4'} players={props.topplayers[3].filter((x:any) => x.award)}/>
                </div>
                <div className='flexColumn'>
                    <TabPlayersPanel key={5} team={'PLANET_5'} players={props.topplayers[4].filter((x:any) => x.award)}/>
                </div>
                <div className='flexColumn'>
                    <TabPlayersPanel key={6} team={'PLANET_6'} players={props.topplayers[5].filter((x:any) => x.award)}/>
                </div>
            </div>
        </div>
    )
}