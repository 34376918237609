import React, { useState } from "react";
import { Game } from "../../helpers/Types";
import { isAdmin } from "../../methods/Authorize";
import HistoryGamePlacings from "./HistoryGamePlacings";
import HistoryGameTransactions from "./HistoryGameTransactions";
import { WalletContext } from "../../main/App";
import { simulator_url } from "../../helpers/Config";
import { imageForAvatar, imageForTeam, leagueIcon, leagueName, planetName } from "../../helpers/Utils";

export default function HistoryGame(props: {game: Game, selectGame: any}) {
    let wallet = React.useContext(WalletContext);

    const [adminMode, setAdminMode] = useState<boolean>(false);

    const game = props.game;
    const selectGame = props.selectGame;

    const openSimulator = async () => {
        window.open( simulator_url + '?key=' + game.results.key, 'unity');
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>

            {/* top banner */}
            <div style={{display: 'flex', gap: '30px'}}>
                <div>
                    <div style={{fontSize: '30px', fontWeight: 'bold'}}>{game.name}</div>
                    <div style={{fontSize: '15px'}}>Played at {new Date(game.played_at).toLocaleString()}</div>
                </div>
                <img alt='' height='50px' width='50px' style={{marginTop: '8px'}} title={leagueName(game.league)} src={leagueIcon(game.league, 50)}/>

                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '20px', color: 'deepskyblue'}}>
                    <img alt="" style={{height: '60px'}} src={imageForAvatar(game.winner)}/>
                    <div className='flexColumn' style={{gap: '0px'}}>
                        <div style={{fontSize: '25px', fontWeight: 'bold'}}>WINNER</div>
                        <div>{game.winnerTag || game.winnerWallet}</div>
                    </div>
                    <img alt="" style={{height: '55px'}} title={planetName(game.winnerTeam)} src={imageForTeam(game.winnerTeam)}/>
                </div>
            </div>

            {/* placings grid */}
            {adminMode ?
                <HistoryGameTransactions game={game}/> :
                <HistoryGamePlacings game={game}/>
            }

            {/* bottom nav */}
            <div style={{width: '450px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '5px'}}>
                <button className="playButton" onClick={openSimulator} style={{marginTop: '15px', width: '150px'}}>REPLAY</button>
                {isAdmin(wallet.key) && <button className="adminButton" onClick={() => setAdminMode(!adminMode)} style={{marginTop: '15px', width: '150px'}}>
                    {adminMode ? 'PLACINGS' : 'TRANSACTIONS'}
                </button>}
            </div>

            {/*back button*/}
            <div style={{marginTop: '5px'}}>
                <button className="link-button" onClick={() => selectGame(undefined)}>&lt; back </button>
            </div>
        </div>
    );
}
