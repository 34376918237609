import React, { useState } from "react";
import MainMenu from "../components/MainMenu";
import { WalletContext } from "../main/App";
import NftSelector from "../components/NftSelector";
import Popup from "../components/Popup";
import RepairCosts from "../components/Repair/RepairCosts";
import RepairAllPopup from "../components/Repair/RepairAllPopup";
import { maxRepairs } from "../helpers/Config";

export default function Repairs() {
    const wallet = React.useContext(WalletContext);
    const [isCostsPopup, setIsCostsPopup] = useState(false);
    const [isRepairPopup, setIsRepairPopup] = useState(false);
    let crewRepairs = wallet.player.nfts?.filter(x => x.schema === 'crew.worlds' && needsRepair(x.asset_id));
    let armsRepairs = wallet.player.nfts?.filter(x => x.schema === 'arms.worlds' && needsRepair(x.asset_id));

    const toggleCostsPopup = () => {setIsCostsPopup(!isCostsPopup);}
    const toggleRepairPopup = () => {setIsRepairPopup(!isRepairPopup);}

    function needsRepair(asset_id: string) {
        return wallet.player.repairs.find(x => x.id === asset_id);
    }

    return (
        <div style={{display: "flex", flexDirection: "row"}}>
            <MainMenu/>
            <div style={{width: '660px', padding: '10px 0px 0px 70px', fontSize: '20px'}}>
                <div style={{fontSize: '30px', marginBottom: '20px'}}>
                    Repairs
                </div>
                {wallet.player.nfts && wallet.player.repairs && wallet.player.repairs.length > 0 ?
                    <div className='flexColumn' style={{textAlign: 'center'}}>
                        <div>You have {wallet.player.repairs.length} NFT{wallet.player.repairs.length > 1 && 's'} that need repairs</div>
                        {crewRepairs && crewRepairs.length > 0 && <NftSelector schema={'crew.worlds'} nfts={crewRepairs} pagesize={5} height={150}/>}
                        {armsRepairs && armsRepairs.length > 0 && <NftSelector schema={'arms.worlds'} nfts={armsRepairs} pagesize={5} height={150}/>}
                        <div>Maximum number of repairs to play Battledome: {maxRepairs} NFTs</div>
                        <button className="playButton" onClick={toggleRepairPopup} style={{width: '175px'}}>Repair all my NFT's</button>
                        <button className="playButton" onClick={toggleCostsPopup} style={{width: '175px'}}>Show repair costs</button>
                        {isRepairPopup && crewRepairs && armsRepairs && <Popup content={
                            <RepairAllPopup nfts={crewRepairs.concat(armsRepairs)} handleClose={toggleRepairPopup}/>
                        } handleClose={toggleRepairPopup}/>}
                        {isCostsPopup && <Popup content={
                            <div className='flexColumn'>
                                <div style={{fontSize: '20px', fontWeight: 'bold'}}>Repair costs:</div>
                                <RepairCosts />
                                <button className="playButton" onClick={toggleCostsPopup}>OKAY</button>
                            </div>
                        } handleClose={toggleCostsPopup}/>}
                    </div>
                    :
                    <div>
                        <div>You have no NFTs that need repairs right now :)</div>
                        <div style={{marginTop: '20px'}}>When your NFTs are used in a battle, can you repair them here.</div>
                        <div style={{marginTop: '20px'}}>The repair cost depends on the rarity of the NFT.</div>
                        <div style={{marginTop: '20px', marginLeft: '100px'}}>
                            <RepairCosts />
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}
