import { League } from "../../helpers/Types";
import { leagueIcon, leagueName } from "../../helpers/Utils";
import React from "react";

export default function LeagueBanner(props: {league: League}) {
    return (
        <div className='flexRow gap10'>
            <img alt='' height='28px' width='28px' src={leagueIcon(props.league)}/>
            <div style={{fontSize: '30px'}}>{leagueName(props.league)}</div>
            <img alt='' height='28px' width='28px' src={leagueIcon(props.league)}/>
        </div>
    );
}