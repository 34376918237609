import { Link } from "react-router-dom";
import MainMenu from "../components/MainMenu";
import NewsItem from "../components/NewsItem";
import React from "react";
import { image_base } from "../helpers/Config";

export default function News() {
    return (
        <div style={{display: "flex", flexDirection: "row"}}>
            <MainMenu/>
            <div style={{width: '660px', padding: '10px 0px 0px 70px'}}>
                {/*<div style={{fontSize: '30px', marginBottom: '20px'}}>*/}
                {/*    News*/}
                {/*</div>*/}

                <div className='flexColumn justifyStart' style={{height: '605px', overflowY: 'scroll', paddingRight: '15px'}}>

                    <NewsItem title='Cloud Wallet Update' date='01.04.2023' by='Tr0n' content={
                        <div className='flexColumn gap5'>
                            <div>Please update your Cloud Wallet</div>
                            <img alt='' height='50px' src='/news-cloudwallet.png'/>
                            <div>Go to https://www.mycloudwallet.com</div>
                        </div>
                    }/>

                    <NewsItem title='Season 8' date='01.04.2023' by='Tr0n' content={
                        <div className='flexColumn gap5'>
                            <div>Season 8 is sponsored by Magor</div>
                            <div>First prize - mythical item!</div>
                            <div>Competition runs from 1 to 15 April, 2023.</div>
                        </div>
                    }/>

                    <NewsItem title='Season 7' date='16.03.2023' by='Tr0n' content={
                        <div className='flexColumn gap5'>
                            <div>Season 7 is sponsored by the Hive</div>
                            <div>Strike first, strike hard.</div>
                            <div>Season 7 runs from 15 to 31 March, 2023.</div>
                        </div>
                    }/>

                    <NewsItem title='Movecost is LIVE' date='09.03.2023' by='Tr0n' content={
                        <div className='flexColumn gap5'>
                            <div>The Movecost code changes are LIVE!</div>
                            <div>Funded for you by: The Syndicates</div>
                            <div>Coded by: Bulga</div>
                            <img alt='' height='50px' src='/news-bulga.png'/>
                            <div>Minions with *lower* Movecost attack first in battles</div>
                            <div>Strike FIRST - strike Hard :)</div>
                        </div>
                    }/>

                    <NewsItem title='Season 6' date='01.03.2023' by='Tr0n' content={
                        <div className='flexColumn gap5'>
                            <div>Season 6 is sponsored by kavianplanet</div>
                            <div>Good luck, battle hard :).</div>
                            <div>Season 6 runs from 1 to 15 March, 2023.</div>
                        </div>
                    }/>

                    <NewsItem title='Season 5' date='15.02.2023' by='Tr0n' content={
                        <div className='flexColumn gap5'>
                            <div>Season 5 is sponsored by The HIVE!</div>
                            <div>Thanks to The Hive, the top 45 winners will receive an NFT.</div>
                            <img alt='' height='64px' src={image_base + 'QmawqkcVKKKGvDCcR9KqZB7Dt2Vz7D2EhvyA191yMTMQcD'}/>
                            <div>In a world first for battledome, the #1 player will win a MYTHICAL nft :)</div>
                            <div>Season 5 runs from 15 to 28 february, 2023.</div>
                        </div>
                    }/>

                    <NewsItem title='Season 4' date='01.02.2023' by='Tr0n' content={
                        <div className='flexColumn gap5'>
                            <div>Season 4 is sponsored by Naron!</div>
                            <img alt='' height='64px' src='planet_4_icon.png'/>
                            <div>The planetary syndicate for Naron has donated 15 NFTs for battledome season 4</div>
                            <div>Season runs from 1 to 14 february, 2023. Get in the battle now :)</div>
                        </div>
                    }/>

                    <NewsItem title='Updated rule: Rarity bonus' date='30.01.2023' by='Tr0n' content={
                        <div className='flexColumn gap5'>
                            <div>Rarity bonus - rarer cards now grant an extra bonus to attack and health!</div>
                            <img alt='' height='64px' title='Rarity bonus' src='rarity_icon.png' style={{verticalAlign: 'middle'}} />
                            <div>The extra bonuses are counted for BOTH minion and weapon as follows:</div>
                            <div className='flexRow'>
                                <img alt='' height='75px' title='Rare card: +10/+10' src='https://atomichub-ipfs.com/ipfs/QmVZcV2BdNAxyrortmE1j5u5KUywUQCXUuG2uNHvaL8hxU' style={{verticalAlign: 'middle'}} />
                                <img alt='' height='75px' title='Epic card: +20/+20' src='https://atomichub-ipfs.com/ipfs/Qme6fvdPzMbJ7zip2c33JPizGgL6P6uMkLz5HpXyrFfG3a' style={{verticalAlign: 'middle'}} />
                                <img alt='' height='75px' title='Legendary card: +30/+30' src='https://atomichub-ipfs.com/ipfs/Qmb78MVB1H2Za4GDKQSgm7WwCzwf7sjqQAtrHJVsKAgM5Q' style={{verticalAlign: 'middle'}} />
                                <img alt='' height='75px' title='Mythical card: +50/+50' src='https://atomichub-ipfs.com/ipfs/QmbwsoeaUnbEy6abAE2sQJ6coy9eFzLP2SiNTi9hgb15Rp' style={{verticalAlign: 'middle'}} />
                            </div>
                            <div>Rare cards: +10/+10</div>
                            <div>Epic cards: +20/+20</div>
                            <div>Legendary cards: +30/+30</div>
                            <div>Mythical cards: +50/+50</div>
                            <div>Rarity bonus is in addition to the stats on the card itself :)</div>
                        </div>
                    }/>

                    <NewsItem title='Buy NFTs in the Market' date='09.01.2023' by='Tr0n' content={
                        <div className='flexColumn gap5'>
                            <div>We are proud to open the Battledome Market :)</div>
                            <div>1. Play to earn Skullz</div>
                            <img alt='' height='64px' src='news-market-1.png' />
                            <div>2. Spend Skullz in the Market to earn NFTs</div>
                            <img alt='' height='64px' src='news-market-2.png' />
                            <div>We hope you like the new ways to spend Skullz.</div>
                        </div>
                    }/>

                    <NewsItem title='Battledome Shop is open' date='03.01.2023' by='Tr0n' content={
                        <div className='flexColumn gap5'>
                            <div>The Battledome shop is open!</div>
                            <div className='flexRow'>
                                <img alt='' height='32px' src='token-32.png'/>
                                <img alt='' height='32px' src='token-32.png'/>
                                <img alt='' height='32px' src='token-32.png'/>
                                <img alt='' height='32px' src='token-32.png'/>
                                <img alt='' height='32px' src='token-32.png'/>
                            </div>
                            <div>We hope you like it :)</div>
                            <div>Choose from 3 new attack animations, and 2 types of boosts</div>
                            <div>Please report any bugs or problems in our discord</div>
                        </div>
                    }/>

                    <NewsItem title='Orbs has become Skullz' date='01.01.2023' by='Tr0n' content={
                        <div className='flexColumn gap5'>
                            <div>Happy New Year in Battledome!</div>
                            <div>In preparation for launching the Shop, we have changed the in-game currency</div>
                            <img alt='' height='150px' src='token-150.png'/>
                            <div>The new token is "Skullz" (which replaces Orbs)</div>
                            <div>All players' Orbs have been automatically converted to Skullz</div>
                            <div>In January you will be able to spend Skullz on awesome new items, coming soon :)</div>
                        </div>
                    }/>

                    <NewsItem title='cryptomonKeys NFT airdrops' date='25.12.2022' by='Tr0n' content={
                        <div className='flexColumn gap5'>
                            <div>Celebrate the 2023 new year with free cryptomonKeys NFTs!</div>
                            <img alt='' height='200px' src='monkeydome-banner.png'/>
                            <div>Thanks to our friends at <a target='cryptomonkeys' href='https://cryptomonkeys.cc/'>cryptomonKeys</a> we have 500 NFTs to airdrop - absolutely free :)</div>
                            <div>We will randomly airdrop 10 cryptomonKeys NFTs every day in January!</div>
                            <div>Play Battledome in January to be eligible for the free NFT airdrops.</div>
                            <div>All players who play 5 games per day are eligible.</div>
                        </div>
                    }/>

                    <NewsItem title='Battle Orders changes' date='18.12.2022' by='Tr0n' content={
                        <div className='flexColumn gap5'>
                            <div>Battle orders have been updated!</div>
                            <img alt='' height='100px' src='news_targeting.png'/>
                            <div>Evade chance has been reduced from 20% to 10%</div>
                            <div>Targeting order has been added - 20% chance to do critical hit (double damage)</div>
                        </div>
                    }/>

                    <NewsItem title='Stealth bonus increase' date='17.12.2022' by='Tr0n' content={
                        <div className='flexColumn gap5'>
                            <div>Stealth bonus has been increased</div>
                            <img alt='' title='Stealth bonus' src='stealth_icon.png'/>
                            <div>Solo players get +20 / +20</div>
                            <div>Duo players get +10 / +10</div>
                        </div>
                    }/>

                    <NewsItem title='Congratulations' date='15.12.2022' by='Tr0n' content={
                        <div className='flexColumn gap5'>
                            <div>Congratulations to Luffy on winning the tournament!</div>
                            <img alt='' height='100px' title='Luffy' src='https://atomichub-ipfs.com/ipfs/QmeK926g8EH56AfSQSrT34zuT5yaY72owKSNKuW5iRfmov'/>
                            <div>Thank you to everyone participating, the top 10 winners are:</div>
                            <div>
                                <div>1. Luffy - 573 wins</div>
                                <div>2. FoviBro - 542 wins</div>
                                <div>3. veltod - 464 wins</div>
                                <div>4. Pies - 439 wins</div>
                                <div>5. Mate - 311 wins</div>
                                <div>6. Pestilentia - 299 wins</div>
                                <div>7. juanjowas - 266 wins</div>
                                <div>8. MjaM - 242 wins</div>
                                <div>9. Gypo - 181 wins</div>
                                <div>10. Victor - 144 wins</div>
                            </div>
                        </div>
                    }/>

                    <NewsItem title='Tournament winners' date='15.12.2022' by='Tr0n' content={
                        <div className='flexColumn gap5'>
                            <div>The tournament is now closed.</div>
                            <div>How to claim your TLM prize?</div>
                            <div>The tournament organizer, Dacoco, will be sending emails to the 10 tournament winners tomorrow on December 16</div>
                            <div>Please check your email! You must answer this email in order to claim your prize.</div>
                        </div>
                    }/>

                    <NewsItem title='Battledome Season 2' date='13.12.2022' by='Tr0n' content={
                        <div className='flexColumn gap5'>
                            <div>Welcome back to Battledome Season 2</div>
                            <div style={{margin: '10px'}}>
                            <iframe height="337px" width="600px" allow="accelerometer;encrypted-media;gyroscope;picture-in-picture"
                                    title="Play Battledome"
                                    src="https://www.youtube.com/embed/OmX3nMnPctY?autohide=1&amp;border=0&amp;wmode=opaque&amp;playsinline=1"
                                    className="tf_abs tf_w tf_h"></iframe>
                            </div>
                            <div>Season 2 starts December 16.</div>
                        </div>
                    }/>

                    <NewsItem title='Welcome back all players' date='07.12.2022' by='Tr0n' content={
                        <div className='flexColumn gap5'>
                            <div>The Battledome tournament is completed soon...</div>
                            <div>We want to welcome back ALL players for the December Battledome season, which runs</div>
                            <div style={{color: 'lightblue'}}>from December 16 00:00 UTC to December 31 23:59 UTC</div>
                            <div>The leaderboard will be reset on December 16, and gold end-of-season prizes
                                for December 16 to 31 season will include EPIC nft's.</div>
                            <div className='flexRow gap5'>
                                <td><img alt='' height='36px' title='Epic card' src='https://atomichub-ipfs.com/ipfs/Qme6fvdPzMbJ7zip2c33JPizGgL6P6uMkLz5HpXyrFfG3a' style={{verticalAlign: 'middle'}} /></td>
                                <td><img alt='' height='36px' title='Epic card' src='https://atomichub-ipfs.com/ipfs/Qme6fvdPzMbJ7zip2c33JPizGgL6P6uMkLz5HpXyrFfG3a' style={{verticalAlign: 'middle'}} /></td>
                                <td><img alt='' height='36px' title='Epic card' src='https://atomichub-ipfs.com/ipfs/Qme6fvdPzMbJ7zip2c33JPizGgL6P6uMkLz5HpXyrFfG3a' style={{verticalAlign: 'middle'}} /></td>
                                <td><img alt='' height='36px' title='Epic card' src='https://atomichub-ipfs.com/ipfs/Qme6fvdPzMbJ7zip2c33JPizGgL6P6uMkLz5HpXyrFfG3a' style={{verticalAlign: 'middle'}} /></td>
                                <td><img alt='' height='36px' title='Epic card' src='https://atomichub-ipfs.com/ipfs/Qme6fvdPzMbJ7zip2c33JPizGgL6P6uMkLz5HpXyrFfG3a' style={{verticalAlign: 'middle'}} /></td>
                            </div>
                            <div>All players can play Battledome again from December 16. See you then!</div>
                        </div>
                    }/>

                    <NewsItem title='Tournament running' date='01.12.2022' by='Tr0n' content={
                        <div className='flexColumn gap5'>
                            <div>The Battledome tournament is running until December 15.</div>
                            <div>Only players who registered for the tournament and ended up on the final list can play.</div>
                            <div>This lasts until December 15, when the tournament concludes at 1PM UTC.</div>
                        </div>
                    }/>

                    <NewsItem title='Scheduled downtime' date='30.11.2022' by='Tr0n' content={
                        <div className='flexColumn gap5'>
                            <div>As we prepare for the Tournament, Battledome has scheduled downtime.</div>
                            <div style={{color: 'lightblue'}}>downtime: from 00:00 to 13:00 December 1st</div>
                            <div>Battledome will restart on 1st December at 1pm UTC.</div>
                            <div>Note that only players registered for Tournament can play between 1 and 15 December.</div>
                            <div>Players not registered for the Tournament, you can play again after 15 December.</div>
                        </div>
                    }/>

                    <NewsItem title='Battle tags for Tournament' date='30.11.2022' by='Tr0n' content={
                        <div className='flexColumn gap5'>
                            <div>The final list of tournament competitors has been imported into Battledome.</div>
                            <img alt='' src='news-tag.png'/>
                            <div>If you see your "battle tag" (instead of the wallet address) - you are in the Tournament!</div>
                        </div>
                    }/>

                    <NewsItem title='New rule: Stealth bonus' date='29.11.2022' by='Tr0n' content={
                        <div className='flexColumn gap5'>
                            <div>A planetary alliance of one or two players will receive a bonus for the duration of the battle.</div>
                            <img alt='' src='news-stealth.png'/>
                            <div>Single players in a planetary alignment receive +10/+10, whilst two players in a planetary alliance
                                receive +5/+5. The bonus lasts the duration of the battle.</div>
                        </div>
                    }/>

                    <NewsItem title='New rule: Dominance' date='29.11.2022' by='Tr0n' content={
                        <div className='flexColumn gap5'>
                            <div>At the start of every round in a battle, if the remaining players of any plantary alignment
                                are more than half of the total remaining players, that planet has "dominance" for the round.</div>
                            <img alt='' src='news-dominance.png'/>
                            <div>Players in the planetary alliance with dominance are targeted first by other players!</div>
                        </div>
                    }/>

                    <NewsItem title='New feature: Repair all' date='28.11.2022' by='Tr0n' content={
                        <div className='flexColumn gap5'>
                            <div>Did you know you can repair all your NFT's at once?</div>
                            <div>Head over to the <Link to='/repairs'>repair center</Link>, commander!</div>
                        </div>
                    }/>

                    <NewsItem title='Welcome to Battledome' date='22.11.2022' by='Tr0n' content={
                        <div className='flexColumn gap5'>
                            <div>The game is officially launched, thank you for your support!</div>
                            <div>If you want help, or to chat, or report a bug, come to our <a target='discord' href='https://discord.com/channels/1026556540100022373/1026556940433764464'>discord</a></div>
                        </div>
                    }/>

                </div>
            </div>
        </div>
    );
}
