export default function TournamentBanner() {
    return (
        <div className='flexColumn gap0 justifyStart' style={{paddingTop: '15px', color: 'cyan', textAlign: 'center'}}>
            <div style={{fontSize: '50px', fontFamily: 'CobaltAlien'}}>BATTLE OF THE WORLDS</div>
            <div className='flexRow'>
                <img alt='' height='32px' width='32px' src='/icon-attack-50.png'/>
                <div style={{fontSize: '30px'}}>Battledome Tournament</div>
                <img alt='' height='32px' width='32px' src='/icon-attack-50.png'/>
            </div>
            <div style={{fontSize: '20px'}}>21st July - 20st August, 2023</div>
        </div>
    )
}