import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { WalletContext } from "../../main/App";
import { fn_url, transaction_url } from "../../helpers/Config";

export default function BatchjobOverview() {
    const wallet = React.useContext(WalletContext);
    const [batchjobs, setBatchjobs] = useState<any[] | undefined>(undefined);
    const [selectedJob, setSelectedJob] = useState<any | undefined>(undefined);
    const [batchitems, setBatchitems] = useState<any[] | undefined>(undefined);

    const loadBatchjobs = async () => {
        console.log('loading batchjobs...');
        let response: any[] = await fetch(fn_url + "batchjobs?key=" + wallet.key).then(response => response.json());
        setBatchjobs(response);
    }

    const loadBatchitems = async (job: any) => {
        console.log('loading batchitems...');
        let response: any[] = await fetch(fn_url + "batchitems?key=" + wallet.key + '&name=' + job.name + '&id=' + job.id).then(response => response.json());
        setBatchitems(response);
    }

    useEffect(() => {
        loadBatchjobs();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    function selectBatchjob(job: any) {
        setSelectedJob(job);
        loadBatchitems(job)
    }

    return (
        <div>
            {selectedJob ?
                <div className='flexColumn' style={{alignItems: 'flex-start'}}>
                    <div style={{fontSize: '30px', marginBottom: '20px'}}>
                        {selectedJob.name} {selectedJob.id}
                    </div>
                    {batchitems ?
                        <table style={{borderCollapse: 'separate', borderSpacing: '10px 5px', textAlign: 'center'}}>
                            <tr>
                                <td>Job name</td>
                                <td>Job id</td>
                                <td>Item id</td>
                                <td>Desc</td>
                                <td>Info</td>
                                <td>Src</td>
                                <td>Dest</td>
                                <td>Currency</td>
                                <td>Amount</td>
                                <td>Status</td>
                                <td>Signature</td>
                            </tr>
                            {batchitems.map(item => (
                                <tr className='tableRow'>
                                    <td>{item.job_name}</td>
                                    <td>{item.job_id}</td>
                                    <td>{item.item_id}</td>
                                    <td>{item.desc}</td>
                                    <td>{item.info}</td>
                                    <td>{item.src}</td>
                                    <td>{item.dest}</td>
                                    <td>{item.currency}</td>
                                    <td>{item.amount}</td>
                                    <td>{item.status}</td>
                                    <td>
                                        {item.signature && item.signature.slice(0,8)}
                                        {item.signature && <a style={{marginLeft: '5px'}} target='bloks' href={transaction_url + item.signature}>tx</a>}
                                    </td>
                                </tr>
                            ))}
                        </table>
                    :
                        <div>loading...</div>
                    }
                    <button className="link-blue" onClick={() => setSelectedJob(undefined)}>&lt; back</button>
                </div>
            :
                <div className='flexColumn' style={{alignItems: 'flex-start'}}>
                    <div style={{fontSize: '30px', marginBottom: '20px'}}>
                        Batch Jobs
                    </div>
                    {batchjobs ?
                        <table style={{borderCollapse: 'separate', borderSpacing: '10px 5px', textAlign: 'center'}}>
                            <tr>
                                <td>Job name</td>
                                <td>Job id</td>
                                <td>Status</td>
                                <td>User</td>
                                <td>Desc</td>
                            </tr>
                        { batchjobs.map(job => (
                            <tr className='tableRow' onClick={() => selectBatchjob(job)}>
                                <td>{job.name}</td>
                                <td>{job.id}</td>
                                <td>{job.status}</td>
                                <td>{job.user}</td>
                                <td>{job.desc}</td>
                            </tr>
                        )) }
                        </table>
                    :
                        <div>loading... </div>
                    }
                    <Link className="link-blue" to='/admin'>&lt; back</Link>
                </div>
            }
        </div>
    )
}