import { Link } from "react-router-dom";
import { Countdown } from "../../helpers/Countdown";

export default function TournamentFooter(props: {played: number | undefined, countdown: Countdown}) {
    return (
        <div className='flexColumn gap0 justifyStart' style={{paddingBottom: '10px', color: 'cyan'}}>
            <div className='flexRow' style={{gap: '20px'}}>
                <div style={{width: '400px', fontSize: '20px', textAlign: 'right'}}>{props.played && ('Total games played: ' + props.played)}</div>
                <Link to='/'><button className='playButton'>OKAY</button></Link>
                <div style={{width: '400px', fontSize: '20px', textAlign: 'left'}}>{props.countdown.days} days {props.countdown.hours} hours {props.countdown.minutes} minutes</div>
            </div>
        </div>
    )
}