export default function TabRules() {
    return (
        <div className='flexColumn' style={{fontSize: '18px', width: '60%', marginTop: '15px'}}>
            <div>The "Battle of the Worlds" tournament is open to anyone with a wax cloud wallet who can play Battledome in the "Advanced League".</div>

            <div>The organizer may ban anyone at any time for any reason, including botting, hacking or anti-social behaviour.</div>

            <div>For "Top Planets" prizes, the wins by all players during the tournament period are aggregated towards each planet.</div>

            <div>For "Top Players" prizes, the wins for only the planet you have the most wins for are counted, and you can only win a "Top Players" prize for one planet.</div>

            <div>Prizes are distributed within 14 days of tournament close.</div>

            <div>All decicions are by the discretion of the tournament organizer, Restack.AI</div>
        </div>
    )
}