import React, { useEffect, useState } from "react";
import Popup from "../Popup";
import { Game, GameMode } from "../../helpers/Types";
import { close_before_seconds, finished_at_seconds, fn_url, maxRepairs, race_at_seconds, rake_percent, ready_at_seconds, simulator_url, token, waiting_reload_seconds } from "../../helpers/Config";
import { WalletContext } from "../../main/App";
import SelectStrategy from "../Strategy/SelectStrategy";
import { League } from '../../helpers/Types';
import { Link } from "react-router-dom";
import { leagueName, leagueIcon } from "../../helpers/Utils";

export default function GameDetails(props: {game: Game, loadGames: any}) {
    const wallet = React.useContext(WalletContext);
    const [lastLoadGames] = useState(new Date());
    const [sentKeyReq, setSentKeyReq] = useState(false);
    const [isRegistering, setIsRegistering] = useState(false);
    const [isPopup, setIsPopup] = useState(false);
    const [game, setGame] = useState(props.game);
    const [now, setNow] = useState<Date>(new Date());

    useEffect(() => {
        const interval = setInterval(() => setNow(new Date()), 1000);
        return () => clearInterval(interval);
    }, []);

    const clickButton = () => {
        if (game.action === 'REGISTER' || game.action === 'REGISTERED'  || game.action === 'WAITING' || game.action === 'REPLAY') {
            togglePopup();
        } else if (game.action === 'PLAY' || game.action === 'SPECTATE') {
            openSimulatorClient();
        }
    }

    const togglePopup = () => {
        setIsPopup(!isPopup);
    }

    const isFull = () => {
        return game.players >= game.maxPlayers;
    }

    const openSimulatorClient = () => {
        setIsPopup(false);
        try {
            window.open(simulator_url + '?key=' + game.results.key + '&user=' + wallet.key, 'unity');

            // reload the page approximately when the game is finished
            let now = Date.now();
            let finishedAt = new Date(game.scheduled_at).getTime() + finished_at_seconds * 1000;
            if (finishedAt > now) {
                console.log('will reload in ' + ((finishedAt - now) / 1000).toFixed(1) + ' seconds');
                setTimeout(() => {
                    props.loadGames();
                    // wallet.player.loadRewards();
                }, finishedAt - now);
            }
        } catch (e) {
            console.error('openSimulator cannot determine game key for game id: ' + game.id);
        }
    }

    const getGameKey = async () => {
        console.log("getting game key...");
        var registerResp = await fetch(fn_url + "gamekey", {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'text/plain'},
            body: JSON.stringify({id: game.id})
        })
            .then(response => response.json());
        game.results = [registerResp];
    }

    function secondsDiff(from: Date, to: Date) {
        return Math.round((to.getTime() - from.getTime()) / 1000);
    }

    function prettyTime(seconds: number) {
        return  seconds > 3600 ? Math.floor(seconds / 3600) + " hours" :
            seconds > 120 ? Math.floor(seconds / 60) + " minutes" :
                seconds > 60 ? "1 minute" : seconds > 45 ? "60 seconds" : seconds > 30 ? "45 seconds" : seconds > 15 ? "30 seconds" : seconds > 5 ? "15 seconds" : " a moment...";
    }

    let registration = game.registrations.find(x => x.walletKey === wallet.key);

    // set up timer
    let playAt = (game.played_at) ? new Date(game.played_at) : new Date(game.scheduled_at);
    let readyAt = new Date(playAt.getTime() + ready_at_seconds * 1000);
    let raceAt = new Date(playAt.getTime() + race_at_seconds * 1000);
    let finishedAt = new Date(playAt.getTime() + finished_at_seconds * 1000);

    let timerText;
    if (now < playAt) {
        timerText = "ready in " + prettyTime(secondsDiff(now, playAt));
        if (secondsDiff(now, playAt) < close_before_seconds && game.players >= game.minPlayers) {
            game.action = 'CLOSED';
        }
    } else if (now >= playAt && game.played_at === null && game.players < game.minPlayers) {
        timerText = "waiting players (" + (game.minPlayers-game.players) + ")"
        game.action = registration ? 'WAITING' : 'REGISTER';
        if (new Date().getTime() - lastLoadGames.getTime() > waiting_reload_seconds * 1000 && !isPopup) {
            props.loadGames();
        }
    } else if (now >= playAt && now < readyAt) {
        timerText = "creating game...";
        let secondsTilReady = secondsDiff(now, readyAt);
        game.action = 'READY IN ' + secondsTilReady;
        if (secondsTilReady < 5 && !sentKeyReq) {
            setSentKeyReq(true);
            getGameKey();
        }
    } else if (now >= readyAt && now < raceAt) {
        timerText = "starts in " + secondsDiff(now, raceAt) + "...";
        game.action = game.registrations.find(g => g.walletKey === wallet.key) ? 'PLAY' : 'SPECTATE';
    } else if (now >= raceAt && now < finishedAt) {
        game.action = game.registrations.find(g => g.walletKey === wallet.key) ? 'PLAY' : 'SPECTATE';
        timerText = "game playing...";
    } else if (now >= finishedAt) {
        game.action = 'REPLAY';
        timerText = "game finished";
    }

    // TODO: remove hack
    if (game.id === 22542) game.action = 'REGISTER';

    return (
        <div className="gameSchedule" style={{display: 'flex', gap: '12px'}}>
            <div className="gameInfo" style={{width: '280px'}}>
                <div style={{float: 'right', width: '230px'}}>
                    <div className="gameName">{game.name}</div>
                    <div className="gameDesc">{timerText}</div>
                </div>
            </div>

            <div style={{display: 'inline', marginTop: '15px', paddingTop: '5px'}}>
                <button className="playButton" onClick={clickButton}>{game.action}</button>
            </div>

            <div style={{display: 'inline', marginTop: '17px', paddingTop: '5px'}}>
                {game.mode === 'PAID' && <img alt='' title='Win TLM' style={{height: '32px', width: '32px'}} src='tlm-32.png' /> }
                {game.mode === 'FREE' && <img alt='' title={leagueName(game.league)}
                                              style={{height: '36px', width: '36px'}}
                                              src={game.league === League.BEGINNER ? 'league-beginner-50.png' : 'league-advanced-50.png'}/>}
            </div>

            {isPopup && game.action === 'WAITING' && <Popup content={
                <div>
                    <h1 style={{marginTop: '0'}}><b>Waiting for players...</b></h1>

                    <div style={{marginTop: '25px', fontSize: '20px'}}>The battle will start when the minimum number of players are registered.</div>

                    <div style={{marginTop: '25px', fontSize: '20px'}}>We need this many players to battle: {game.minPlayers}</div>

                    <div style={{marginTop: '25px', height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <button className="playButton" onClick={togglePopup}>OKAY</button>
                    </div>

                    <div style={{fontSize: '20px'}}>{game.players} out of {game.maxPlayers} players</div>
                </div>
            } handleClose={togglePopup}/>}

            {isPopup && game.action.startsWith('REGISTER') && wallet.player.nfts && wallet.player.nfts.length > 0 && wallet.player.repairs.length <= maxRepairs && !isRegistering && <Popup content={
                <div>
                    <h2 style={{marginTop: '0'}}><b>Join the Battle!</b></h2>

                    <div style={{fontSize: '25px'}}>{game.name}</div>
                    <div style={{marginTop: '0px'}}>Starts at {new Date(game.scheduled_at).toLocaleString()}</div>
                    <div className='flexRow gap5'>
                        <img alt='' height='22px' width='22px' src={leagueIcon(game.league)}/>
                        <div>{leagueName(game.league)}</div>
                        <img alt='' height='22px' width='22px' src={leagueIcon(game.league)}/>
                    </div>

                    {game.mode === GameMode.Paid && (
                        <div>
                            <div style={{marginTop: '10px', display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center'}}>
                                <div style={{fontSize: '20px'}}>Entry Fee:</div>
                                <div style={{padding: '7px 0px 0px 7px'}}><img alt='' width='22px' height='22px' src='tlm-32.png' /></div>
                                <div style={{fontSize: '20px'}}>{game.entryFee + ' TLM'}</div>
                            </div>
                            <div style={{marginBottom: '0px', display: 'flex', gap: '5px', justifyContent: 'center', alignItems: 'center'}}>
                                <div style={{fontSize: '20px'}}>Reward:</div>
                                <div style={{padding: '7px 0px 0px 7px'}}><img alt='' width='22px' height='22px' src='tlm-32.png' /></div>
                                <div style={{fontSize: '20px'}}>{parseFloat((game.entryFee * Math.max(game.minPlayers, game.players) * (100 - rake_percent) / 100).toFixed(4))} TLM</div>
                            </div>
                        </div>
                    )}
                    {game.mode === GameMode.Free && (
                        <div>
                            <div style={{marginTop: '10px', display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center'}}>
                                <div style={{fontSize: '20px'}}>Entry Fee:</div>
                                <div style={{fontSize: '20px'}}>FREE</div>
                            </div>
                            <div style={{marginBottom: '0px', display: 'flex', gap: '5px', justifyContent: 'center', alignItems: 'center'}}>
                                <div style={{fontSize: '20px'}}>Reward:</div>
                                <div style={{padding: '2px 0px 0px 7px'}}><img alt='' width='22px' height='22px' src='token-32.png' /></div>
                                <div style={{fontSize: '20px'}}>1 {token}</div>
                            </div>
                        </div>
                    )}

                    <div style={{height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        {game.action === 'REGISTER' && (isFull() ? <div>Game is full :(</div> : <button className="playButton" onClick={() => setIsRegistering(true)}>REGISTER</button>)}
                        {game.action === 'REGISTERED' && <div>You are registered :)</div>}
                    </div>

                    <div style={{fontSize: '20px'}}>{game.players} out of {game.maxPlayers} players</div>
                </div>
            } handleClose={togglePopup}/>}

            {isPopup && game.action.startsWith('REGISTER') && wallet.player.nfts && wallet.player.nfts.length > 0 && isRegistering && <Popup wide={true} content={
                <SelectStrategy game={game} setGame={setGame}/>
            } handleClose={togglePopup}/>}

            {isPopup && game.action.startsWith('REGISTER') && wallet.player.nfts && wallet.player.nfts.length === 0 && <Popup content={
                <div>
                    <h1 style={{marginTop: '0'}}><b>Want to Play?</b></h1>

                    <p style={{fontSize: '20px'}}>
                        To play in the Battledome, you need some NFTs :)
                    </p>

                    <p style={{fontSize: '15px'}}>
                        Head on over to atomicassets.io and purchase some NFTs from the "alien.worlds" collection
                    </p>

                    <a href='https://wax.atomichub.io/market?collection_name=alien.worlds' target='atomichub' >
                        <img style={{marginBottom: '10px', height: '60px'}} alt='magiceden' src='banner-atmoichub.png' />
                    </a>

                    <p>
                        Once you have purchased 3 NFT's from "alien.worlds", you can play.
                    </p>

                    <div style={{height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <button className="playButton" onClick={togglePopup}>OKAY</button>
                    </div>
                </div>
            } handleClose={togglePopup}/>}

            {isPopup && game.action.startsWith('REGISTER') && wallet.player.repairs.length > maxRepairs && <Popup content={
                <div>
                    <h1 style={{marginTop: '0'}}><b>Want to Play?</b></h1>

                    <p style={{fontSize: '20px'}}>
                        Please repair your NFTs to play more.
                    </p>

                    <p>
                        You can only play when {maxRepairs} or less NFTs need repairs.
                    </p>

                    <div style={{height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Link to='/repairs'><button className="playButton">REPAIRS</button></Link>
                    </div>
                </div>
            } handleClose={togglePopup}/>}

            {isPopup && game.action.startsWith('REGISTER') && !wallet.key && <Popup content={
                <div>
                    <h1 style={{marginTop: '0'}}><b>Want to Play?</b></h1>

                    <p style={{fontSize: '20px'}}>
                        Connect your Wallet to play.
                    </p>

                    <p>
                        To install WAX Cloud Wallet, download it here:
                    </p>

                    <div className='flexRow'>
                        <a href='https://www.mycloudwallet.com/' target='mycloudwallet' >
                            <img alt='' height='48px' src='mycloudwallet.png' />
                        </a>
                    </div>

                    <p>
                        NFTs from "alien.worlds" are also required to play.
                    </p>

                    <div style={{height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <button className="playButton" onClick={togglePopup}>OKAY</button>
                    </div>
                </div>
            } handleClose={togglePopup}/>}

            {isPopup && game.action === 'REPLAY' && <Popup content={
                <div>
                    <h1 style={{marginTop: '0'}}><b>Battle Over</b></h1>
                    <p>This fight has ended, and what a battle it was!</p>

                    {/*<div style={{height: '75px', marginBottom: '5px'}}>*/}
                    {/*    <img alt='' style={{border: 'solid 2px #270238'}}*/}
                    {/*         title={game.winner}*/}
                    {/*         src={imageForNft(game.winner)}*/}
                    {/*    />*/}
                    {/*</div>*/}

                    <div style={{fontSize: '25px'}}>{game.name}</div>
                    <div style={{marginTop: '0px'}}>Played at {new Date(game.played_at).toLocaleString()}</div>

                    <div style={{marginTop: '10px', display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center'}}>
                        <div style={{fontSize: '20px'}}>Winner:</div>
                        <div style={{fontSize: '20px'}}>{game.winnerWallet}</div>
                    </div>

                    {game.mode === GameMode.Paid &&
                      <div style={{marginBottom: '0px', display: 'flex', gap: '5px', justifyContent: 'center', alignItems: 'center'}}>
                        <div style={{fontSize: '20px'}}>Reward:</div>
                        <div style={{padding: '2px 0px 0px 7px'}}><img alt='' width='22px' height='22px' src='tlm-32.png' /></div>
                        <div style={{fontSize: '20px'}}>{game.payout} TLM</div>
                      </div>
                    }

                    {game.mode === GameMode.Free &&
                      <div style={{marginBottom: '0px', display: 'flex', gap: '5px', justifyContent: 'center', alignItems: 'center'}}>
                        <div style={{fontSize: '20px'}}>Reward:</div>
                        <div style={{padding: '2px 0px 0px 7px'}}><img alt='' width='22px' height='22px' src='token-32.png' /></div>
                        <div style={{fontSize: '20px'}}>1 {token}</div>
                      </div>
                    }

                    <button className="playButton" onClick={openSimulatorClient} style={{marginTop: '15px'}}>REPLAY</button>

                    <div style={{marginTop: '10px'}}>Click to watch the replay.</div>
                </div>


            } handleClose={togglePopup}/>}

        </div>
    );
}
