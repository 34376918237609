import { useState } from "react";
import { BPlayer } from "./BattleTypes";
import { card_back } from "../../helpers/Config";
import { imageForMinion, imageForWeapon, nameForMinion, nameForWeapon, ordersImage, ordersName, planetIcon, planetName, playerName } from "../../helpers/Utils";
import BattlePlrHover from "./BattlePlrHover";
import HoverCard from "../HoverCard";
import BattlePlrAnimation from "./BattlePlrAnimation";

export default function BattlePlr(props: {bplr: BPlayer}) {
    const [isHover, setIsHover] = useState(false);
    const [hoverImage, setHoverImage] = useState('');
    const [hoverFun, setHoverFun] = useState('');
    const bplr = props.bplr;
    const color = bplr.winner ? 'deepskyblue' : bplr.health <= 0 ? 'red' : bplr.damaged ? 'yellow' : 'green';

    function plrBorder() {
        return bplr.attacking ? 'solid 1px lightgreen' : bplr.defending ? 'solid 1px red' : bplr.winner ? 'solid 1px deepskyblue' : 'solid 1px transparent';
    }
    
    function handleHoverImage(img: string, fun: string) {
        setHoverImage(img)
        setHoverFun(fun)
    }
   
    function handleLeaveImage() {
        setHoverImage('')
        setHoverFun('')
    }
    
    return (
        <div className='flexColumn' style={{gap: '2px', fontFamily: 'Alienworld', fontSize: '13px', width: '80px'}}>
            <div className='flexRow' style={{gap: '0px'}}>
                <div style={{marginBottom: '3px', marginRight: '5px'}}>Plr-{bplr.num}</div>
                <img alt='' title={planetName(bplr.planet)} src={planetIcon(bplr.planet)} height='16px'/>
            </div>
            <div>
                <div style={{marginBottom: '3px', marginRight: '5px', fontSize: '12px'}}>{playerName(bplr)}</div>
            </div>
            <div style={{marginBottom: '3px', color: 'skyblue'}}>{bplr.attack} attack</div>
            <div style={{position: 'relative'}}>
                {<div style={{boxSizing: 'border-box', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', width: `${isHover ? '550px' : 0}`, height: `${isHover ? '240px' : '0'}`, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, 0)', padding: '30px 80px', zIndex: `${isHover ? '12' : '-12'}`, opacity: `${isHover ? '1' : '0'}`,  backgroundImage: `url(battle-background-plr-hover.svg)`, transition: '.3s'}}>
                    <div style={{height: `${isHover ? '100%' : '0'}`, opacity: `${isHover ? '1' : '0'}`, transition: '1s'}}>
                        <BattlePlrHover bplr={bplr}/>
                    </div>
                </div>}
                <div
                    onMouseOver={() => setIsHover(true)}
                    onMouseLeave={() => setIsHover(false)}
                    style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', background: 'transparent', zIndex: '1000'}}
                >
                </div>
                <img alt='' src={bplr.alive ? bplr.img : card_back}
                    style={{height: '100px', border: plrBorder(), borderRadius: '8px', marginBottom: '3px'}}
                    className={`${bplr.defending && bplr.animationType ? 'battle-plr__card-animation' : ''}`}
                />
                {
                    bplr.defending && <BattlePlrAnimation type={bplr.animationType}/>
                }
            </div>
            <div className='flexRow gap5'>
                <div style={{position: 'relative'}}>
                    <img alt='' 
                        title={nameForMinion(bplr.selection)} 
                        src={imageForMinion(bplr.selection)} 
                        height='26px'
                        onMouseOver={() => handleHoverImage(bplr.selection, 'imageForMinion')}
                        onMouseLeave={() => handleLeaveImage()}
                    />
                    {
                        hoverFun !== 'imageForWeapon' && hoverFun !== 'ordersImage' && <HoverCard hoverImage={hoverImage} hoverFun={hoverFun}/> 
                    }
                </div>
                <div style={{position: 'relative'}}>
                    <img alt='' 
                        title={nameForWeapon(bplr.selection)} 
                        src={imageForWeapon(bplr.selection)} 
                        height='26px'
                        onMouseOver={() => handleHoverImage(bplr.selection, 'imageForWeapon')}
                        onMouseLeave={() => handleLeaveImage()}
                    />
                    {
                        hoverFun !== 'imageForMinion' && hoverFun !== 'ordersImage' && <HoverCard hoverImage={hoverImage} hoverFun={hoverFun}/> 
                    }
                </div>
                <div style={{position: 'relative'}}>
                    <img alt='' 
                        title={ordersName(bplr.orders)} 
                        src={ordersImage(bplr.orders)} 
                        height='24px'
                        style={{marginTop: '2px'}}
                        onMouseOver={() => handleHoverImage(bplr.orders, 'ordersImage')}
                        onMouseLeave={() => handleLeaveImage()}
                    />
                    {
                        hoverFun !== 'imageForWeapon' && hoverFun !== 'imageForMinion' && <HoverCard hoverImage={hoverImage} hoverFun={hoverFun}/> 
                    }
                </div>
            </div>
            <div style={{color: color}}>{bplr.winner ? 'WINNER' : bplr.alive ? bplr.health + ' health' : '- RIP -'}</div>
            <div className='flexRow' style={{gap: '0px'}}>
                {bplr.rarity > 0 && <img alt='' title={'Rarity bonus +' + bplr.rarity + '/+' + bplr.rarity} src='rarity_icon.png' width='16.5px' height='16px'/>}
                {bplr.phase === 1 && <img alt='' title='Phase bonus +5/+5' src='phase_icon.png' width='16.5px' height='16px'/>}
                {bplr.phase === 2 && <img alt='' title='Phase bonus +10/+10' src='phase_icon.png' width='16.5px' height='16px'/>}
                {bplr.stealth === 1 && <img alt='' title='Stealth bonus +10/+10' src='stealth_icon.png' width='16.5px' height='16px'/>}
                {bplr.stealth === 2 && <img alt='' title='Stealth bonus +20/+20' src='stealth_icon.png' width='16.5px' height='16px'/>}
                {bplr.healthPotion && <img alt='' title='Health boost +15' src='items/pot-0103.svg' width='16.5px' height='16px'/>}
                {bplr.attackPotion && <img alt='' title='Attack boost +15' src='items/pot-0203.svg' width='16.5px' height='16px'/>}
                {bplr.alienPotion && <img alt='' title='Alien boost' src='items/pot-0303.svg' width='16.5px' height='16px'/>}
            </div>
        </div>
    );
}
