import { planetImage, planetName } from "../../helpers/Utils";
import { card_size_ratio } from "../../helpers/Config";

export default function PlanetSelector(props: {selectFn?: any }) {
    const height = 150;
    const width = height * card_size_ratio;

    return (
        <div>
            <div className='flexRow'>
                {Array.from({length: 6}, (v, k) => k+1).map(n =>
                    <div key={n} className='flexColumn' style={{fontSize: '20px', marginBottom: '20px'}}>
                        <img alt='' title={planetName('PLANET_' + n)} height={height+'px'} width={width+'px'} src={planetImage('PLANET_' + n)} onClick={() => props.selectFn('PLANET_' + n)}/>
                    </div>
                )}
            </div>
        </div>
    );
}
