export default function NewsItem(props : any) {
    return (
        <div className='newsItem flexColumn gap5'>
            <div className='flexRow'>
                <div style={{color: "lawngreen"}}>{props.title}</div>
                <div style={{color: 'skyblue'}}>posted by: {props.by}</div>
                <div style={{color: 'mediumpurple'}}>{props.date}</div>
            </div>
            {props.content}
        </div>
    );
};
