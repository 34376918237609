import { useEffect, useState } from "react";
import MainMenu from "../components/MainMenu";
import { Game } from "../helpers/Types";
import HistoryGame from "../components/History/HistoryGame";
import HistoryOverview from "../components/History/HistoryOverview";
import { fn_url } from "../helpers/Config";

export default function History() {
    const [games, setGames] = useState<Game[]>([]);
    const [selectedGame, setSelectedGame] = useState<Game | undefined>(undefined);
    const [gamesPlayed, setGamesPlayed] = useState<number>();
    const [uniquePlayers, setUniquePlayers] = useState<number>();

    const loadHistory = async () => {
        console.log("Loading history...");
        let history = await fetch(fn_url + "history").then(response => response.json());
        setGames(history.games);
        setGamesPlayed(history.gamesPlayed);
        setUniquePlayers(history.uniquePlayers);
    }

    const selectGame = (id: number | undefined) => {
        console.log('selected game ' + id);
        setSelectedGame(games.find(x => x.id === id));
    }

    useEffect(() => {
        loadHistory();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div style={{display: "flex", flexDirection: "row"}}>
            <MainMenu/>
            <div style={{width: '660px', padding: '10px 0px 0px 70px'}}>
                { (selectedGame === undefined) && <HistoryOverview games={games} selectGame={selectGame} gamesPlayed={gamesPlayed} uniquePlayers={uniquePlayers}/> }
                { (selectedGame !== undefined) && <HistoryGame game={selectedGame} selectGame={selectGame} /> }
            </div>
        </div>
    );
}
