import MainMenu from "../components/MainMenu";
import React from "react";
import { leaderGroupSize, season } from "../helpers/Config";

export default function RewardPage() {
    return (
        <div style={{display: "flex", flexDirection: "row"}}>
            <MainMenu/>
            <div style={{width: '660px', padding: '10px 0px 0px 70px', fontSize: '20px'}}>
                <div style={{fontSize: '30px', marginBottom: '20px'}}>
                    Battledome Rewards - Season {season}
                </div>
                {/*<div className='flexColumn' style={{gap: '5px', marginBottom: '20px'}}>*/}
                {/*    <div>This season the top {nftPrizeImagesAdvanced.length} players will receive these NFTs:</div>*/}
                {/*    <div className='flexRow gap10'>*/}
                {/*        {nftPrizeImagesAdvanced.slice(0,15).map(img => { return (<img alt='' height='50px' src={image_base + img}/>)})}*/}
                {/*    </div>*/}
                {/*    <div className='flexRow gap10' style={{marginTop: '5px'}}>*/}
                {/*        {nftPrizeImagesAdvanced.slice(15).map(img => { return (<img alt='' height='50px' src={image_base + img}/>)})}*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className='flexColumn' style={{gap: '5px', marginBottom: '20px'}}>
                    <div className='flexRow' style={{gap: '10px'}}>
                        {/*<img alt='' height='36px' src='cardback-50.png'/>*/}
                        {/*<img alt='' height='32px' src='icon-tlm-50.png'/>*/}
                        <img alt='' height='32px' src='token-32.png'/>
                    </div>
                    <div className='flexRow'>
                        <img alt='' style={{height: '32px'}} title='Gold end-of-season Reward' src='trophy-gold.png' />
                        <div style={{fontFamily: 'AlienWorld', fontSize: '20px', marginBottom: '5px'}}>GOLD REWARDS</div>
                        <img alt='' style={{height: '32px'}} title='Gold end-of-season Reward' src='trophy-gold.png' />
                    </div>
                    <div>The top {leaderGroupSize} players (Advanced League) will win 100 Skullz</div>
                </div>
                <div className='flexColumn' style={{gap: '5px', marginBottom: '20px'}}>
                    <div className='flexRow' style={{gap: '10px'}}>
                        {/*<img alt='' height='32px' src='icon-tlm-50.png'/>*/}
                        <img alt='' height='32px' src='token-32.png'/>
                    </div>
                    <div className='flexRow'>
                        <img alt='' style={{height: '32px'}} title='Gold end-of-season Reward' src='trophy-silver.png' />
                        <div style={{fontFamily: 'AlienWorld', fontSize: '20px', marginBottom: '5px'}}>SILVER REWARDS</div>
                        <img alt='' style={{height: '32px'}} title='Gold end-of-season Reward' src='trophy-silver.png' />
                    </div>
                    <div>The next {leaderGroupSize} players (Advanced League) will win 50 Skullz</div>
                </div>
                <div className='flexColumn' style={{gap: '5px', marginBottom: '20px'}}>
                    <div className='flexRow' style={{gap: '10px'}}>
                        <img alt='' height='32px' src='token-32.png'/>
                    </div>
                    <div className='flexRow'>
                        <img alt='' style={{height: '32px'}} title='Gold end-of-season Reward' src='trophy-bronze.png' />
                        <div style={{fontFamily: 'AlienWorld', fontSize: '20px', marginBottom: '5px'}}>BRONZE REWARDS</div>
                        <img alt='' style={{height: '32px'}} title='Gold end-of-season Reward' src='trophy-bronze.png' />
                    </div>
                    <div>The next {leaderGroupSize} players (Advanced League) will win 20 Skullz</div>
                </div>
                <div className='flexColumn' style={{gap: '5px', marginBottom: '20px'}}>
                    <div className='flexRow' style={{gap: '10px'}}>
                        <img alt='' height='32px' src='token-32.png'/>
                    </div>
                    <div className='flexRow'>
                        <img alt='' style={{height: '32px'}} title='Gold end-of-season Reward' src='icon-attack-50.png' />
                        <div style={{fontFamily: 'AlienWorld', fontSize: '20px', marginBottom: '5px'}}>BEGINNER LEAGUE</div>
                        <img alt='' style={{height: '32px'}} title='Gold end-of-season Reward' src='icon-attack-50.png' />
                    </div>
                    <div>The top {leaderGroupSize} players (Beginner League) will win 10 Skullz</div>
                </div>
            </div>
        </div>
    );
}
