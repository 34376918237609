import React, { useState } from "react";
import Nft from "../../helpers/Types";
import { purchase } from "../../methods/Bank";
import { WalletContext } from "../../main/App";
import { fn_url } from "../../helpers/Config";
import { repairCost } from "../../helpers/Utils";

export default function RepairAllPopup(props: {nfts: Nft[], repairFn?: any, handleClose?: any}) {
    let wallet = React.useContext(WalletContext);

    const [saveSending, setSaveSending] = useState<boolean | undefined>(undefined);
    const [saveError, setSaveError] = useState<boolean | undefined>(undefined);
    const [saveOkay, setSaveOkay] = useState<boolean | undefined>(undefined);
    const totalFees = props.nfts.reduce((sum, nft) => sum + repairCost(nft.rarity), 0);

    async function payForRepair() {
        if (!wallet.key) return;

        let signature = await purchase(wallet.key, totalFees, 'TLM', 'repair ' + props.nfts.length + ' nfts');
        if (!signature) {
            return;
        }

        console.log('sending repairallsave...');
        setSaveSending(true);
        let assetIds = props.nfts.map(x => x.asset_id);
        var response = await fetch(fn_url + "repairallsave", {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'text/plain'},
            body: JSON.stringify({
                walletKey: wallet.key,
                ids: assetIds,
                fee: totalFees,
                signature: signature,
            })
        });
        console.log('ok: ' + response.ok);

        if (!response.ok) {
            setSaveError(true);
        } else {
            setSaveOkay(true);
            console.log('items ' + assetIds + ' were repaired.');
            wallet.player.doRepair('ALL');
            props.handleClose();
        }
    }

    return (
        <div className='flexColumn'>
            <div>Repair all these NFT's?</div>
            <div style={{fontWeight: 'bold', fontSize: '30px'}}>{props.nfts.length} NFTs</div>
            <div className='flexRow' style={{gap: '5px'}}><div>Repair now:</div><img alt='' src='tlm-32.png'/><div>{totalFees} TLM</div></div>
            {
                saveError ? <div style={{color: 'red'}}>ERROR</div> :
                saveOkay ? <div>Done :)</div> :
                saveSending ? <div>Sending...</div> :
                <button className="playButton" onClick={() => payForRepair()}>REPAIR NOW</button>
            }
        </div>
    )
}
