import React, { useState } from "react";
import { Link } from "react-router-dom";
import { WalletContext } from "../../main/App";
import NftSelector from "../NftSelector";
import { card_back, fn_url, image_base } from "../../helpers/Config";
import OrdersSelector from "./OrdersSelector";
import {
    getPhase,
    leagueIcon,
    leagueName,
    ordersImage,
    ordersName,
    phaseName,
    planetImage,
    planetName
} from "../../helpers/Utils";
import PlanetSelector from "./PlanetSelector";
import Nft, { Game, GameMode, League } from "../../helpers/Types";
import { join, purchase } from "../../methods/Bank";
import PrecacheImages, { orders_images, planets_images } from "../../helpers/PrecacheImages";
import LeagueBanner from "./LeagueBanner";

export default function SelectStrategy(props: {game: Game, setGame: any}) {
    const wallet = React.useContext(WalletContext);
    let nfts = wallet.player.nfts;
    let game = props.game;

    const phase = getPhase(new Date());

    const [page, setPage] = useState(0);
    const [avatar, setAvatar] = useState<Nft | undefined>();
    const [minion, setMinion] = useState<Nft | undefined>();
    const [weapon, setWeapon] = useState<Nft | undefined>();
    const [orders, setOrders] = useState();
    const [planet, setPlanet] = useState();
    const [saveSending, setSaveSending] = useState<boolean | undefined>(undefined);
    const [saveError, setSaveError] = useState<boolean | undefined>(undefined);
    const [saveOkay, setSaveOkay] = useState<boolean | undefined>(undefined);

    const nextPage = async () => { setPage(page + 1); }
    const setAvatarAndNext = (x: Nft) => { setAvatar(x); setSaveSending(undefined); setSaveOkay(undefined); nextPage(); }
    const setMinionAndNext = (x: Nft) => { setMinion(x); setSaveSending(undefined); setSaveOkay(undefined);  nextPage(); }
    const setWeaponAndNext = (x: Nft) => { setWeapon(x); setSaveSending(undefined); setSaveOkay(undefined);  nextPage(); }
    const setOrdersAndNext = (x: any) => { setOrders(x); setSaveSending(undefined); setSaveOkay(undefined);  nextPage(); }
    const setPlanetAndNext = (x: any) => { setPlanet(x); setSaveSending(undefined); setSaveOkay(undefined);  nextPage(); }

    const [checked, setChecked] = React.useState(false);

    const handleChange = () => {
      setChecked(!checked);
    };
  
    const registerForGame = async () => {
        if (game.players >= game.maxPlayers) return;
        if (!wallet.key) return;

        if (!avatar || !minion || !weapon || !orders || !planet) { return; }
        let selection = `${avatar.template_id}/${avatar.asset_id} ${minion.template_id}/${minion.asset_id} ${weapon.template_id}/${weapon.asset_id} ${orders} ${planet}`;

        let signature;
        if (game.mode === GameMode.Free) {
            // use a smart contract to authorize for FREE games
            signature = await join(wallet.key);
        } else if (game.mode === GameMode.Paid) {
            // take a payment via client wallet for PAID games
            if (game.entryFee === null) return;
            let amount = game.entryFee;
            if (amount === 0.0) return;
            if (amount > 100.0) return;

            console.log("purchasing entry fee " + game.entryFee + " TLM...");
            signature = await purchase(wallet.key, amount, 'TLM', 'game entry');
            console.log('registered successfully with signature : ' + signature);
        }

        console.log("registering for game...");
        setSaveSending(true);
        var registerResp = await fetch(fn_url + "register", {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'text/plain'},
            body: JSON.stringify({
                game_id: game.id,
                walletKey: wallet.key,
                selection: selection,
                signature: signature,
                coinBalance: wallet.player.tlm,
                nftsCount: wallet.player.nfts?.length
            })
        });

        if (!registerResp.ok) {
            setSaveError(true);
        } else {
            setSaveOkay(true);
            props.setGame(await registerResp.json());
        }
    }

    function filterNfts(nfts: Nft[], schema: string) {
        return  nfts.filter(x => x.schema === schema)
                    .filter(x => (game.league !== League.BEGINNER) || (x.rarity === 'Abundant' || x.rarity === 'Common'))
                    .filter(x => !checked || !['crew.worlds', 'arms.worlds'].includes(schema) || x.element === phaseName(phase));
    }

    return (
        <div className='flexRow'>
            {nfts ?
                <div className='flexColumn justifyStart gap0' style={{width: '660px', height: '480px'}}>
                    <div className='flexColumn' style={{minHeight: '240px', fontSize: '20px', justifyContent: 'flex-start'}}>
                        {page === 0 &&
                            <div className='flexColumn' style={{gap: '5px'}}>
                                <div style={{fontSize: '30px'}}>Welcome to the {leagueName(game.league)}</div>
                                  <img alt='' height='150px' width='150px' style={{padding: '20px'}} src={leagueIcon(game.league, 150)}/>
                                  <div style={{fontWeight: 'bold'}}>The following rules apply to {leagueName(game.league)}:</div>
                                    {game.league === League.BEGINNER && (
                                        <div>
                                            <div>1. Only common and abundant NFTs are permitted.</div>
                                            <div>2. No items or boosts are permitted.</div>
                                            <div>3. Not eligible for end-of-season prizes or airdrops.</div>
                                        </div>
                                        )}
                                    {game.league === League.ADVANCED && (
                                        <div className='flexColumn gap5'>
                                            <div>1. All NFTs can be used (including rare, epic and legendary).</div>
                                            <div>2. Items and boosts are allowed.</div>
                                            <div>3. Eligible for end of season prizes and airdrops.</div>
                                        </div>
                                    )} 
                                <button className="playButton" style={{marginTop: '15px'}} onClick={nextPage}>NEXT</button>
                            </div>
                        }
                        {page === 1 &&
                            <div className='flexColumn' style={{gap: '5px'}}>
                                <div className='flexRow'>
                                    <div style={{fontSize: '30px'}}>1. Select Avatar</div>
                                </div>
                                {game.league === League.BEGINNER && (<NftSelector schema={'faces.worlds'} nfts={nfts.filter(x => x.schema === 'faces.worlds' && (x.rarity === 'Abundant' || x.rarity === 'Common'))} pagesize={5} height={150} showMessage={true} selectFn={setAvatarAndNext}/>)}
                                {game.league === League.ADVANCED && (<NftSelector schema={'faces.worlds'} nfts={nfts.filter(x => x.schema === 'faces.worlds')} pagesize={5} height={150} showMessage={true} selectFn={setAvatarAndNext}/>)}
                            </div>
                        }
                        {page === 2 &&
                            <div className='flexColumn' style={{gap: '5px'}}>
                                <div style={{fontSize: '30px'}}>2. Choose Minion</div>
                                <NftSelector schema={'crew.worlds'} nfts={filterNfts(nfts, 'crew.worlds')} pagesize={5} height={150} showMessage={true} selectFn={setMinionAndNext}/>
                                <div className='flexRow' style={{fontSize: '20px'}}>
                                    <label>
                                        <input type="checkbox" checked={checked} onChange={handleChange} />{phaseName(phase)} cards only
                                    </label>
                                </div>
                            </div>
                        }
                        {page === 3 &&
                            <div className='flexColumn' style={{gap: '5px'}}>
                                <div style={{fontSize: '30px'}}>3. Equip Weapon</div>
                                <NftSelector schema={'arms.worlds'} nfts={filterNfts(nfts, 'arms.worlds')} pagesize={5} height={150} showMessage={true} selectFn={setWeaponAndNext}/>
                                <div style={{fontSize: '20px'}}>
                                    <label>
                                        <input type="checkbox" checked={checked} onChange={handleChange} />{phaseName(phase)} cards only
                                    </label>
                                </div>
                            </div>
                        }
                        {page === 4 &&
                            <div className='flexColumn' style={{gap: '5px'}}>
                                <div style={{fontSize: '30px'}}>4. Select Orders</div>
                                <OrdersSelector selectFn={setOrdersAndNext} />
                            </div>
                        }
                        {page === 5 &&
                            <div className='flexColumn' style={{gap: '5px'}}>
                                <div style={{fontSize: '30px'}}>5. Align to Planet</div>
                                <PlanetSelector selectFn={setPlanetAndNext} />
                            </div>
                        }
                        {page === 6 &&
                            <div className='flexColumn' style={{gap: '5px'}}>
                                <div style={{fontSize: '30px'}}>Ready for Battle</div>
                                <div>Click REGISTER to join the battle!</div>
                                <img alt='' src='icon-attack-50.png'/>
                                {
                                    saveError ? <div style={{color: 'red'}}>ERROR</div> :
                                    saveOkay ?
                                        <div className='flexColumn' style={{gap: '5px'}}>
                                            <div>Saved.</div>
                                            <button className="playButton" onClick={() => wallet.player.refresh()}>Go to Lobby</button>
                                        </div> :
                                    saveSending ? <div>Sending...</div> :
                                    <button className="playButton" onClick={registerForGame}>REGISTER</button>
                                }
                            </div>
                        }
                    </div>
                    {page > 0 &&
                    <div className='flexRow' style={{fontSize: '18px', fontFamily: 'AlienWorld', marginBottom: '10px'}}>
                        <div className='flexColumn' style={{gap: '10px'}}>
                            <div>Avatar</div>
                            {avatar ? <img alt='nft' title={avatar.name} height='150px' src={image_base + avatar.image} onClick={() => setPage(1)}/> : <img alt='nft' height='150px' src={card_back}/> }
                        </div>
                        <div className='flexColumn' style={{gap: '10px'}}>
                            <div>Minion</div>
                            {minion ? <img alt='nft' title={minion.name} height='150px' src={image_base + minion.image} onClick={() => setPage(2)}/> : <img alt='nft' height='150px' src={card_back}/> }
                        </div>
                        <div className='flexColumn' style={{gap: '10px'}}>
                            <div>Weapon</div>
                            {weapon ? <img alt='nft' title={weapon.name} height='150px' src={image_base + weapon.image} onClick={() => setPage(3)}/> : <img alt='nft' height='150px' src={card_back}/> }
                        </div>
                        <div className='flexColumn' style={{gap: '10px'}}>
                            <div>Orders</div>
                            {orders ? <img alt='orders' title={ordersName(orders)} height='150px' src={ordersImage(orders)} onClick={() => setPage(4)}/> : <img alt='orders' height='150px' src={card_back}/> }
                        </div>
                        <div className='flexColumn' style={{gap: '10px'}}>
                            <div>Planet</div>
                            {planet ? <img alt='planet' title={planetName(planet)} height='150px' src={planetImage(planet)} onClick={() => setPage(5)}/> : <img alt='orders' height='150px' src={card_back}/> }
                        </div>
                    </div>}
                    {page > 0 &&
                    <LeagueBanner league={game.league}/>
                    }
                </div>
                :
                <div style={{width: '660px', padding: '10px 0px 0px 70px'}}>
                    <div style={{fontSize: '30px', marginBottom: '20px'}}>
                        Select NFTs
                    </div>
                    <div style={{fontSize: '20px', marginBottom: '20px'}}>
                        Please connect your wallet from Game Lobby.
                    </div>
                    <Link to='/'><button className="playButton">Go to Lobby</button></Link>
                </div>
            }

            <PrecacheImages images={[...orders_images, ...planets_images]} />
        </div>
    );
}
