import { Game } from "../../helpers/Types";
import { imageForAvatar, imageForTeam, leagueIcon, leagueName, planetName } from "../../helpers/Utils";

export default function HistoryOverview(props: { games: Game[], selectGame: any, gamesPlayed: any, uniquePlayers: any }) {
    const games = props.games;
    const selectGame = props.selectGame;
    const gamesPlayed = props.gamesPlayed;
    const uniquePlayers = props.uniquePlayers;

    return (
        <div>
            <div style={{fontSize: '30px', marginBottom: '20px'}}>
                Battle History
            </div>
            <div className='flexColumn gap10'>
                {games.map(game =>
                    <div className='flexRow gap10' key={game.id} style={{fontSize: '20px'}}>
                        <img alt='' height='24px' width='24px' title={leagueName(game.league)} src={leagueIcon(game.league)}/>

                        <div style={{width: '200px'}}>
                            <button className='link-button' onClick={() => selectGame(game.id)}>{game.name}</button>
                        </div>
                        <div style={{width: '225px'}}>{new Date(game.played_at).toLocaleString()}</div>
                        <div style={{width: '175px', display: 'flex', justifyContent: 'flex-start', gap: '10px'}}>
                            <div><img alt='' height='30px' title='winner'  src={imageForAvatar(game.winner)}/></div>
                            <div>{game.winnerTag || game.winnerWallet}</div>
                        </div>
                        <div style={{marginTop: '4px'}}>
                            <img alt='' height='25px' title={planetName(game.winnerTeam)}  src={imageForTeam(game.winnerTeam)}/>
                        </div>
                    </div>
                )}
            </div>

            {gamesPlayed &&
                <div style={{display: 'flex', alignItems: 'center', gap: '10px', marginTop: '10px'}}>
                    <div style={{fontSize: '20px'}}>Total games played this month:</div>
                    <div style={{fontSize: '30px', fontWeight: 'bold', marginLeft: '15px'}}>{gamesPlayed}</div>
                    {gamesPlayed > 100 && (<img alt='wow' src='wow-64.png'/>)}
                </div>
            }
            {uniquePlayers &&
                <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                    <div style={{fontSize: '20px'}}>Unique players this month:</div>
                    <div style={{fontSize: '30px', fontWeight: 'bold', marginLeft: '15px'}}>{uniquePlayers}</div>
                </div>
            }
        </div>
    );
}