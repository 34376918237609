import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Popup from "../components/Popup";
import MainMenu from "../components/MainMenu";
import { WalletContext } from "../main/App";
import { buyitem } from "../methods/Bank";
import { asset_url_base, fn_url, image_base, template_url_base, token } from "../helpers/Config";
import { MarketItem } from "../helpers/Types";
import { nftImage, rarityColor } from "../helpers/Utils";
import { calcCountdown } from "../helpers/Countdown";
import ConnectWallet from "../components/ConnectWallet";

export default function Market() {
    const [countdown1, setCountdown1] = useState(calcCountdown(new Date().setUTCHours(23,59,59,999) + 15 * 1000));
    const [countdown2, setCountdown2] = useState(calcCountdown(new Date().setUTCMinutes(59,59,999) + 15 * 1000));
    const [tokens, setTokens] = useState(0);
    const [items, setItems] = useState<MarketItem[]>([]);
    const [isPopup, setIsPopup] = useState<boolean>(false);
    const [popupItem, setPopupItem] = useState<MarketItem | null>(null);
    const wallet = React.useContext(WalletContext);

    useEffect(() => {
        const interval = setInterval(() => {
            setCountdown1(calcCountdown(new Date().setUTCHours(23, 59, 59, 999) + 15 * 1000));
            setCountdown2(calcCountdown(new Date().setUTCMinutes(59, 59, 999) + 15 * 1000));
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const loadItems = (key: string | undefined) => {
        if (!key) return;
        fetch(`https://fn.battledome.live/.netlify/functions/market?walletKey=${wallet.key}`).then(response => response.json()).then((res) => setItems(res.length ? res : '')).catch((e) => console.log('Items loading error: ', e))
    }

    useEffect(() => {
        loadItems(wallet.key);
        return setTokens(0)
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const performBuyItem = async () => {
        setIsPopup(false);

        if (!wallet.key) return;
        if (!popupItem) return;

        // calling smart contract to authorize purchase
        let signature = await buyitem(wallet.key, 'ID ' + popupItem.asset_id, popupItem.price);
        if (!signature) return;

        // backend call, sending tx signature
        var registerResp = await fetch(fn_url + "marketbuy", {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'text/plain'},
            body: JSON.stringify({
                walletKey: wallet.key,
                market_id: popupItem.id,
                asset_id: popupItem.asset_id,
                price: popupItem.price,
                signature: signature,
            })
        });

        if (!registerResp.ok) {
            handleClosePopup();
        } else {
            handleClosePopup();
            loadItems(wallet.key);
            let playerdata = await fetch(fn_url + "playerdata?walletKey=" + wallet.key).then(response => response.json());
            setTokens(playerdata.rewards.balance);
        }
    }

    const handleClosePopup = () => {
        setIsPopup(false);
        setPopupItem(null);
    }

    const handleOpenPopup = (item: MarketItem) => {
        setIsPopup(true);
        setPopupItem(item);
    }

    return (
        <div style={{display: "flex", flexDirection: "row"}}>
            {isPopup && popupItem &&
                <Popup style={{left: '477px'}} handleClose={handleClosePopup} content={
                    <div className='flexColumn'>
                        <div style={{fontSize: '28px'}}>{popupItem.name}</div>
                        <div className='flexRow' style={{padding: '20px', color: 'white', backgroundColor: '#1a203c', borderRadius: '20px', gap: '25px'}}>
                            <div className='flexColumn gap5'>
                                <img alt='' src={nftImage(popupItem.image)} style={{height: '150px', objectFit: 'contain'}}/>
                                <div style={{color: rarityColor(popupItem.rarity)}}>{popupItem.rarity} item</div>
                            </div>
                            <div className='flexColumn gap5'>
                                <div style={{height: '38px'}}>Collection: {popupItem.collection}</div>
                                <div style={{height: '38px'}}>Schema: {popupItem.schema}</div>
                                <div className='flexRow gap5'>
                                    <div>ID: {popupItem.asset_id}</div>
                                    <a target='asset' href={asset_url_base + popupItem.asset_id}><img alt='' height='20px' width='20px' src='info-icon.png' style={{marginTop: '10px'}}/></a>
                                </div>
                                <div className='flexRow gap5'>
                                    <div>Template ID: {popupItem.template_id}</div>
                                    <a target='template' href={template_url_base + popupItem.template_id}><img alt='' height='20px' width='20px' src='info-icon.png' style={{marginTop: '10px'}}/></a>
                                </div>
                            </div>
                        </div>
                        {popupItem.status === 'FORSALE' &&
                            <div className='flexColumn'>
                                <div>Buy this NFT for {popupItem.price} {token}?</div>
                                <div className='flexRow'>
                                    <button onClick={() => handleClosePopup()} className="clearButton" style={{cursor: 'pointer'}}>Cancel</button>
                                    <button onClick={() => performBuyItem()} className="clearButton" style={{cursor: 'pointer'}}>Buy Item</button>
                                </div>
                            </div>
                        }
                        {popupItem.status === 'SOLD' &&
                            <div className='flexColumn'>
                                <div>This NFT was sold for {popupItem.price} {token} to {popupItem.buyer}</div>
                                <div className='flexRow'>
                                    <button onClick={() => handleClosePopup()} className="clearButton" style={{cursor: 'pointer'}}>Okay</button>
                                </div>
                            </div>
                        }
                    </div>
                }/>
            }
            <MainMenu/>
            <div style={{width: '660px', padding: '10px 0px 20px 70px', fontSize: '20px'}}>
                {/*<div style={{fontSize: '30px', marginBottom: '20px'}}>*/}
                {/*    Market*/}
                {/*</div>*/}

                {wallet.key ?
                    <div className='flexColumn'>
                        {wallet.player.tokens !== undefined ?
                            <div className='flexRow'>
                                <div>Your balance is:</div>
                                <img alt="" src="token-32.png" height="30px" width="30px"/>
                                <div>{tokens ? tokens : wallet.player.tokens} {token.toUpperCase()}</div>
                                <Link to='/tokens'><button className="playButton" style={{marginLeft: '20px'}}>Top up Skullz</button></Link>
                            </div>
                          :
                            <div style={{height: '40px'}}></div>
                        }

                        {items.length > 0 ?
                            <div className='flexColumn'>
                                <div className='flexRow wrap'>
                                    {
                                        items.map((item: MarketItem) =>
                                            <div className='flexColumn gap5 darkBlueCard' style={{width: '165px'}} key={item.id}>
                                                <img alt='' height='150px' src={image_base + item.image} />
                                                <div style={{height: '33px'}}>
                                                    {item.status === 'FORSALE' &&
                                                        <div className="flexRow" style={{gap: '7px'}}>
                                                            <img alt="" src="token-32.png" height="24px" width="24px"/>
                                                            {item.price} {token}
                                                        </div>
                                                    }
                                                    {item.status === 'SOLD' &&
                                                        <div style={{color: 'red', fontWeight: 'bold'}}>
                                                            SOLD
                                                        </div>
                                                    }
                                                </div>
                                                <button className='playButton' style={{padding: '5px'}} onClick={() => handleOpenPopup(item)}>Details</button>
                                            </div>
                                        )
                                    }
                                </div>

                                <div className='flexRow'>
                                    How does the market work? <Link to='/marketinfo'><button className='playButton'>Read Info</button></Link>

                                    <div className='flexRow gap10 darkBlueCard pad10'>
                                        <img alt='' height='20px' title='Prices Reduce' src='info-icon.png'/>
                                        <div style={{width: '60px', fontFamily: 'Alienworld', fontSize: '15px'}}>
                                            {countdown2.minutes.toString().padStart(2, '0')} : {countdown2.seconds.toString().padStart(2, '0')}
                                        </div>
                                    </div>

                                    <div className='flexRow gap10 darkBlueCard pad10'>
                                        <img alt='' height='20px' title='Sold items refresh (New NFTs)' src='info-icon.png'/>
                                        <div style={{width: '90px', fontFamily: 'Alienworld', fontSize: '15px'}}>
                                            {countdown1.hours.toString().padStart(2, '0')} : {countdown1.minutes.toString().padStart(2, '0')} : {countdown1.seconds.toString().padStart(2, '0')}
                                        </div>
                                    </div>
                                </div>

                                {/*<div style={{marginTop: '10px'}}>Market will refresh: {countdown1.minutes} hours {countdown1.minutes} minutes {countdown1.seconds} seconds</div>*/}

                            </div>
                            :
                            <div>Loading...</div>
                        }
                    </div>
                    :
                    <ConnectWallet onConnectFn={loadItems}/>
                }
            </div>
        </div>
    );
}
