import React from "react";
import { WalletContext } from "../main/App";

export default function ConnectWallet(props: {onConnectFn?: any}) {
  const wallet = React.useContext(WalletContext);

  async function doConnect() {
    let key = await wallet.connect();
    console.log('connected.');
    if (key && props.onConnectFn) props.onConnectFn(key);
  }

  return (
        <div className='flexColumn' style={{fontSize: '26px', marginTop: '120px'}}>
            You need to connect a wallet
            <button className='playButton'
                    style={{fontSize: "15px", width: '150px', padding: "5px 10px 5px 10px", borderRadius: "5px"}}
                    onClick={() => doConnect()}>
              Connect Wallet
            </button>
        </div>
    );
}