import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './index.css';
import App from './main/App';

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<App screen='lobby'/>}/>
                <Route path='/admin' element={<App screen='admin'/>}/>
                <Route path='/airdrops' element={<App screen='airdrops'/>}/>
                <Route path='/batchjobs' element={<App screen='batchjobs'/>}/>
                <Route path='/battle' element={<App screen='battle'/>}/>
                <Route path='/boost' element={<App screen='boost'/>}/>
                <Route path='/comingsoon' element={<App screen='comingsoon'/>}/>
                <Route path='/history' element={<App screen='history'/>}/>
                <Route path='/howtoplay' element={<App screen='howtoplay'/>}/>
                <Route path='/leaders' element={<App screen='leaders'/>}/>
                <Route path='/leaderteams' element={<App screen='leaderteams'/>}/>
                <Route path='/mynfts' element={<App screen='mynfts'/>}/>
                <Route path='/lobby' element={<App screen='lobby'/>}/>
                <Route path='/market' element={<App screen='market'/>}/>
                <Route path='/marketinfo' element={<App screen='marketinfo'/>}/>
                <Route path='/news' element={<App screen='news'/>}/>
                <Route path='/rewards' element={<App screen='rewards'/>}/>
                <Route path='/repairs' element={<App screen='repairs'/>}/>
                <Route path='/shop' element={<App screen='shop'/>}/>
                <Route path='/strategy' element={<App screen='strategy'/>}/>
                <Route path='/tokens' element={<App screen='tokens'/>}/>
                <Route path='/tournament' element={<App screen='tournament'/>}/>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

