import React from "react";
import { Link } from "react-router-dom";
import WalletInfo from "../WalletInfo";
import WalletPanel from "../WalletPanel";
import { WalletContext } from "../../main/App";
import PhasePanel from "../PhasePanel";
import RewardsPanel from "../RewardsPanel";

export default function LobbyRight() {
    let wallet = React.useContext(WalletContext);

    return (
        <div style={{width: '280px', textAlign: 'center'}}>
            { wallet.key ?
                <div style={{marginTop: '5px'}}>
                    <WalletPanel/>
                    <WalletInfo/>
                    <Link to='mynfts'><button className="clearButton">My NFTs</button></Link>
                    <div style={{height: '25px'}}/>
                    <PhasePanel/>
                    <div style={{height: '25px'}}/>
                    <RewardsPanel/>
                </div>
                :
                <div className='flexColumn'>
                    <img alt='' src='alienworlds-community-logo-color-rgb.svg' style={{width: '200px', height: '108px'}}/>
                    <div style={{marginTop: '20px', fontSize: '30px', fontWeight: 'bold', fontStyle: 'italic'}}>Let's Play!</div>
                    <WalletPanel/>
                    <div style={{fontSize: '20px', width: '75%'}}>
                        To play Battledome, connect your wallet :)
                    </div>
                </div>
            }
        </div>
    );
}
