import React from "react";
import { Link } from "react-router-dom";
import { isAdmin } from "../methods/Authorize";
import CreditPanel from "./CreditPanel";
import { WalletContext } from "../main/App";

export default function MainMenu() {
    let wallet = React.useContext(WalletContext);

    return(
        <div>
            <div style={{paddingTop: '10px', paddingBottom: '20px', backgroundColor: '#000000'}}>
                <div style={{display: 'flex'}}>
                    <div>
                        <div className="menuItem">
                            <Link to="/" className="menuLink">GAME&nbsp;LOBBY</Link>
                        </div>
                        {isAdmin(wallet.key) ?
                            <div className="menuItem">
                                <Link to="/admin" className="menuLink">ADMIN</Link>
                            </div> :
                            <div className="menuItem">
                                <Link to="/howtoplay" className="menuLink">HOW TO PLAY</Link>
                            </div>
                        }
                        <div className="menuItem">
                            <Link to="/mynfts" className="menuLink">MY&nbsp;NFTS</Link>
                        </div>
                        <div className="menuItem">
                            <Link to="/history" className="menuLink">BATTLE&nbsp;HISTORY</Link>
                        </div>
                        <div className="menuItem">
                            <Link to="/leaders" className="menuLink">LEADERBOARD</Link>
                        </div>
                        <div className="menuItem">
                            <Link to="/rewards" className="menuLink">REWARDS</Link>
                        </div>
                        <div className="menuItem">
                            <Link to="/repairs" className="menuLink">REPAIRS {wallet.player.repairs?.length > 0 && '*'}</Link>
                        </div>
                        <div className="menuItem">
                            <Link to="/boost" className="menuLink">BOOST</Link>
                        </div>
                        <div className="menuItem">
                            <Link to="/shop" className="menuLink"><div className='flexRow justifyStart'>SHOP</div></Link>
                        </div>
                        <div className="menuItem">
                            <Link to="/market" className="menuLink"><div className='flexRow justifyStart'>MARKET</div></Link>
                        </div>
                        <div className="menuItem">
                            <Link to="/news" className="menuLink">NEWS</Link>
                        </div>
                    </div>
                    <div style={{width: '30px'}} />
                </div>
                <div style={{height: '10px'}}> </div>
                <CreditPanel/>
            </div>
        </div>
    );
}
