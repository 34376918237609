
import React from "react";
import { WalletContext } from "../../main/App";

export default function LeagueLock() {
    let wallet = React.useContext(WalletContext);
    return (
            <div className='flexColumn gap10' style={{width: '60%', padding: '20px', fontSize: '20px'}}>
                <div>To unlock Advanced League you need to win 5 games</div>
                <img alt='' height='150px' width='150px' title='Win 5 games to join the Advanced League' src='league-advanced-150.png'/>
                <div>progress: {wallet.player.wins}  / 5 games</div>
            </div>
    );
}
