export const nfts_avatars =
    [
        {
            "id": "13728",
            "img": "QmQ1GBDMPRiadFGxRAWSensph8i4dmtfHDj7jcPVrz39UY",
            "name": "Male Cyborg T15",
            "rarity": "Rare"
        },
        {
            "id": "17453",
            "img": "QmdNPERi5eFHapErYmYgzZh9VcQGLTjb1N6TTTGKn1C3fk",
            "name": "Female Cyborg T8",
            "rarity": "Rare"
        },
        {
            "id": "19648",
            "img": "QmQUU3KsrRuPiFgmu9wJWp2f9NJ4WzD3eVjMRJdctf9rtR",
            "name": "Female Human",
            "rarity": "Abundant"
        },
        {
            "id": "19649",
            "img": "QmXa4fjB7AVd8rLvUcBk5uPKVugg2Bfj26PEwVth71T3yn",
            "name": "Male Human",
            "rarity": "Abundant"
        },
        {
            "id": "19650",
            "img": "QmNSXmTh2uHDY6EwK4CqeQBvB7y23iZNvrfuHmHKogruEt",
            "name": "Female Grey",
            "rarity": "Common"
        },
        {
            "id": "19651",
            "img": "QmRnmsAXtdxFiosAC4xTNAirxtACSh8Cua4Nnp367XEZae",
            "name": "Male Grey",
            "rarity": "Common"
        },
        {
            "id": "19652",
            "img": "QmUnBMPBCsjbDNt1itshyqXk2L4BkyFGC5Zgt2UkbYA45A",
            "name": "Female Reptiloid",
            "rarity": "Rare"
        },
        {
            "id": "19653",
            "img": "QmQGq9X8odfm9B6H5Y1wcau1fc92Z7579qrWbsW5Rdn9TR",
            "name": "Male Reptiloid",
            "rarity": "Rare"
        },
        {
            "id": "19654",
            "img": "QmZot4HaFaonY7UYZqGKVqygKYo7yvBSgeh6jCGrcattr1",
            "name": "Female Little Green Person",
            "rarity": "Rare"
        },
        {
            "id": "19655",
            "img": "QmdHLuJf7SmoL9AJ2ScrxfbUQKJgcZsDKa6pdXro7hy3jk",
            "name": "Male Little Green Person",
            "rarity": "Rare"
        },
        {
            "id": "19656",
            "img": "QmPTU9pcseB61Lp2esADYmEvPdJMHZBEiXbc9aCDChZS5m",
            "name": "Female Nordic",
            "rarity": "Common"
        },
        {
            "id": "19657",
            "img": "QmZT6PyREyuivPA7ZtxN1r8gMw128ojTpzMjpZXHj65KiV",
            "name": "Male Nordic",
            "rarity": "Common"
        },
        {
            "id": "19658",
            "img": "QmdV7HKYmEycnoLqKGNStniZDfSVdhouZmvLaUtymLbSvF",
            "name": "Robotron Soldier",
            "rarity": "Epic"
        },
        {
            "id": "19659",
            "img": "QmdVJG3Ced5xvtSyji44moWfJaJHFnWZRqWA1w2UTGED4k",
            "name": "Commander Church",
            "rarity": "Legendary"
        },
        {
            "id": "19660",
            "img": "QmRM8f2cE7UyZmVE9haoLtaV4wGa7BR8XJaLbur9RM9cVP",
            "name": "Ted Shadewick",
            "rarity": "Epic"
        },
        {
            "id": "20984",
            "img": "QmRMvFR65B3tczqS3ribubRsfkBkhiUSkL2PNNFgukxkTk",
            "name": "Female Reptiloid",
            "rarity": "Rare"
        },
        {
            "id": "20985",
            "img": "QmWdw1vLXG7REw4uvHnKBmU57ifrhEoGEbDttUHw4PNUpB",
            "name": "Male Reptiloid",
            "rarity": "Rare"
        },
        {
            "id": "20986",
            "img": "QmQXfax83Xu1YhJHsVArRp2H6gzFx5oDovgcyMPbY6zSns",
            "name": "Female Little Green Person",
            "rarity": "Rare"
        },
        {
            "id": "20987",
            "img": "QmcN4wXdks74Cpb6dE6qygdXNniXqPEHciXbGsgYgh37TT",
            "name": "Male Little Green Person",
            "rarity": "Rare"
        },
        {
            "id": "28425",
            "img": "Qmc6YSi3jwfcrR5V41ZwS1M6dJZEUxM6h8G7KKbj6HdDWw",
            "name": "Aioshi Holoform",
            "rarity": "Mythical"
        },
        {
            "id": "44934",
            "img": "QmUhF6u35iSj1WtJtGokvGXxassdp1dgZ9Agk34u3imWdK",
            "name": "Celestial Terror",
            "rarity": "Epic"
        },
        {
            "id": "48116",
            "img": "QmQ3HQrqLvK7FV93KJEiVEzFuJsR1EXmnZZm6y2tWkDLMZ",
            "name": "Robotron Soldier",
            "rarity": "Epic"
        },
        {
            "id": "48117",
            "img": "QmU28G5awtckr3E5Eix37acnqeu5Dzv3Avs3cD29NM9LBE",
            "name": "Female Cyborg T8",
            "rarity": "Rare"
        },
        {
            "id": "48118",
            "img": "Qmf87Uw5tkUMHUJgC9R8mRBYCD3dc5iLHgWcnnz79HJxbA",
            "name": "Male Cyborg T15",
            "rarity": "Rare"
        },
        {
            "id": "48119",
            "img": "QmaJR3XRovVwxZ31sEaat2KsrpYBpexhjwUVX6NEptfGVU",
            "name": "Female Human",
            "rarity": "Abundant"
        },
        {
            "id": "48120",
            "img": "QmSUWgba8zsZ4iG3xTAY5Byopk1ihgbsgYPfeLY9Lk21qj",
            "name": "Male Human",
            "rarity": "Abundant"
        },
        {
            "id": "48121",
            "img": "QmaZRBMe7kaLrfhVohpcX8eiLQuMxtwbT4VErJSQweyuDX",
            "name": "Female Grey",
            "rarity": "Common"
        },
        {
            "id": "48122",
            "img": "QmTwZpGsixrGW5fwvFxaJUM6tRS44W8NNK25tub86DvvzF",
            "name": "Male Grey",
            "rarity": "Common"
        },
        {
            "id": "48123",
            "img": "QmdWgCr3EdK3YNv2LQE5zhBiwTLAkqjELo2Qaj3DxkA1wi",
            "name": "Female Reptiloid",
            "rarity": "Rare"
        },
        {
            "id": "48124",
            "img": "QmW3PZQyBA4WL91N3bbeqq2S8883UtZRHQyeJ2YMH7dXL5",
            "name": "Male Reptiloid",
            "rarity": "Rare"
        },
        {
            "id": "48125",
            "img": "QmXXbrRxnwWtZ7nSDmizxYBTAAJEspc8BfVzfHqJ7X5s8u",
            "name": "Female Little Green Person",
            "rarity": "Rare"
        },
        {
            "id": "48126",
            "img": "QmWj5PBYJJ2Nr9RsKFzDBhcFPJMBT85eJVGXKQdx5vdByB",
            "name": "Male Little Green Person",
            "rarity": "Rare"
        },
        {
            "id": "48127",
            "img": "QmUFHJpSfgoMCVqkgPAieS2a1joNWDq1Bf7FfmtiSZxYUD",
            "name": "Female Nordic",
            "rarity": "Common"
        },
        {
            "id": "48128",
            "img": "QmZ8mCLfCv79LnVdyf4duMYi8H6ca5x3nGqnsBNA4xA2W3",
            "name": "Male Nordic",
            "rarity": "Common"
        },
        {
            "id": "48129",
            "img": "QmeK926g8EH56AfSQSrT34zuT5yaY72owKSNKuW5iRfmov",
            "name": "Commander Church",
            "rarity": "Legendary"
        },
        {
            "id": "48130",
            "img": "QmQa93rBR3nyW5FShjVV29M1XRrLicePX14TAdtQu4zsFD",
            "name": "Ted Shadewick",
            "rarity": "Epic"
        },
        {
            "id": "48131",
            "img": "QmQpCVK7F7P7LV9dwZvKEwhHKoU5UtvezT4ny83xJUSj6n",
            "name": "Robotron Soldier",
            "rarity": "Epic"
        },
        {
            "id": "48132",
            "img": "QmNNvMy9oxQ5iWsD7REhJZBvgPRNuN3h3ars4iUXMT4Pmz",
            "name": "Female Cyborg T8",
            "rarity": "Rare"
        },
        {
            "id": "48133",
            "img": "QmTxnUGtWq1VXuE7Hy7Np7gp9Zhv1qz3RpuUz23D6cevq4",
            "name": "Male Cyborg T15",
            "rarity": "Rare"
        },
        {
            "id": "48134",
            "img": "Qmbp6jheyp7utoS2Q3EQ4E21ieo3hzXskAYu9ZLKJpFgAt",
            "name": "Female Grey",
            "rarity": "Common"
        },
        {
            "id": "48135",
            "img": "QmZH63zgtEzoa3c4K2Ah6K8fhjaQRSphSsAJkaUXfEF5LT",
            "name": "Male Grey",
            "rarity": "Common"
        },
        {
            "id": "48136",
            "img": "QmeqfZfajEuyE9KzmoJDhNaiVcWyNk97qABLoNxiGDNQxx",
            "name": "Female Reptiloid",
            "rarity": "Rare"
        },
        {
            "id": "48137",
            "img": "QmNdx1bZB2dzUkchq5CyTVYkqshn7kFF3k1AaFMuVSjC46",
            "name": "Male Reptiloid",
            "rarity": "Rare"
        },
        {
            "id": "48138",
            "img": "QmTgmy2DBV7oSBqE38bkSdyqEpDA9wquUcCAdVE5QW4ZqD",
            "name": "Female Little Green Person",
            "rarity": "Rare"
        },
        {
            "id": "48139",
            "img": "QmVMWRDzHqZCsUbhrERiKAdW8aPXbMBYF4noDRdN6gkmHZ",
            "name": "Male Little Green Person",
            "rarity": "Rare"
        },
        {
            "id": "48140",
            "img": "QmXUja1UiiH2YB893VxdhKTvjfW9qkNN3jm24QdkLzMe1M",
            "name": "Female Nordic",
            "rarity": "Common"
        },
        {
            "id": "48141",
            "img": "QmaokVaofHgBGmu1MZi2PEy6WLWEkcRm66wUB2z7XYdeph",
            "name": "Male Nordic",
            "rarity": "Common"
        },
        {
            "id": "48143",
            "img": "QmbKUo3zTvMenRyWdGdf35HMy67v3Ukm4UeqKt3YEXzTKZ",
            "name": "Ted Shadewick",
            "rarity": "Epic"
        },
        {
            "id": "48144",
            "img": "QmVPYCX46Nqq9WEvZnqiTjGid26cAuNcKV5wofmKEcQohe",
            "name": "Robotron Soldier",
            "rarity": "Epic"
        },
        {
            "id": "48145",
            "img": "QmdXtDaxuoxquMpqKmTsn3QnWWKh6aU4HVDAEXAMquEykG",
            "name": "Female Cyborg T8",
            "rarity": "Rare"
        },
        {
            "id": "48147",
            "img": "QmPLsatFjxiLAEPHUA3REYxGdLrM6YAcq1e8zs2j1cPzNX",
            "name": "Female Reptiloid",
            "rarity": "Rare"
        },
        {
            "id": "48148",
            "img": "QmQaqwP4rMgtGvZVvyxdCBAnLsRgqVrAaVtVB9Y5ixodXV",
            "name": "Male Reptiloid",
            "rarity": "Rare"
        },
        {
            "id": "48149",
            "img": "QmXSn5tUiwGh47EYAju4YE2hD8YuEn4QgG9SG1vSmaaeyG",
            "name": "Female Little Green Person",
            "rarity": "Rare"
        },
        {
            "id": "48150",
            "img": "QmYBsNuGrj4TYmwa9PtCp9AYq98p7wKk31zbLAdgzK6RGw",
            "name": "Male Little Green Person",
            "rarity": "Rare"
        },
        {
            "id": "48152",
            "img": "QmQ7rgJLWpAzNsmsfNWZHcKNvZsJjHJpX3YaQhQFJ6p4hg",
            "name": "Ted Shadewick",
            "rarity": "Epic"
        },
        {
            "id": "116347",
            "img": "QmQ1GBDMPRiadFGxRAWSensph8i4dmtfHDj7jcPVrz39UY",
            "name": "Large Explosive",
            "rarity": "Rare"
        }
    ]
;

export const nfts_minions =
    [
        {
            "id": "19337",
            "img": "QmSEZ",
            "name": "LG Soldier",
            "rarity": "Common",
            "element": "Neutral",
            "attack": 4,
            "defense": 4,
            "movecost": 10
        },
        {
            "id": "19338",
            "img": "QmQ6x",
            "name": "Grey Scientist",
            "rarity": "Common",
            "element": "Air",
            "attack": 16,
            "defense": 25,
            "movecost": 50
        },
        {
            "id": "19339",
            "img": "QmfLR",
            "name": "Nordic Warrior",
            "rarity": "Common",
            "element": "Fire",
            "attack": 18,
            "defense": 8,
            "movecost": 30
        },
        {
            "id": "19340",
            "img": "QmSaV",
            "name": "Wise Ancient One",
            "rarity": "Rare",
            "element": "Air",
            "attack": 6,
            "defense": 28,
            "movecost": 40
        },
        {
            "id": "19342",
            "img": "QmRZU",
            "name": "Explosives Specialist",
            "rarity": "Rare",
            "element": "Fire",
            "attack": 18,
            "defense": 34,
            "movecost": 60
        },
        {
            "id": "19344",
            "img": "QmYU3",
            "name": "Enhanced Reptiloid",
            "rarity": "Epic",
            "element": "Gem",
            "attack": 15,
            "defense": 15,
            "movecost": 30
        },
        {
            "id": "19346",
            "img": "QmW48",
            "name": "Terminator",
            "rarity": "Common",
            "element": "Metal",
            "attack": 16,
            "defense": 10,
            "movecost": 30
        },
        {
            "id": "19349",
            "img": "QmdS2",
            "name": "LG Soldier",
            "rarity": "Common",
            "element": "Metal",
            "attack": 10,
            "defense": 7,
            "movecost": 20
        },
        {
            "id": "19350",
            "img": "QmXv4",
            "name": "Grey Scientist",
            "rarity": "Common",
            "element": "Nature",
            "attack": 19,
            "defense": 22,
            "movecost": 50
        },
        {
            "id": "19351",
            "img": "QmRsc",
            "name": "Nordic Warrior",
            "rarity": "Common",
            "element": "Nature",
            "attack": 16,
            "defense": 10,
            "movecost": 30
        },
        {
            "id": "19624",
            "img": "Qme6fvdPzMbJ7zip2c33JPizGgL6P6uMkLz5HpXyrFfG3a",
            "name": "Mysterious Hacker",
            "rarity": "Epic",
            "element": "Air",
            "attack": 14,
            "defense": 16,
            "movecost": 30
        },
        {
            "id": "19625",
            "img": "QmVRduT9RoAh4kq3srY52uEWe7MgEnUoteM2mMV4fVJWrq",
            "name": "Stealth Mercenary",
            "rarity": "Abundant",
            "element": "Neutral",
            "attack": 13,
            "defense": 11,
            "movecost": 30
        },
        {
            "id": "19626",
            "img": "QmSEZR4YEoudRvJTCK8XZy3TMGG4PYEjmmPKA1YPCT5Zod",
            "name": "LG Soldier",
            "rarity": "Common",
            "element": "Neutral",
            "attack": 4,
            "defense": 4,
            "movecost": 10
        },
        {
            "id": "19627",
            "img": "QmQ6xzHJBdaQ82a9PXsciXjzCrkxa1MX2YmR562q7YHjir",
            "name": "Grey Scientist",
            "rarity": "Common",
            "element": "Air",
            "attack": 16,
            "defense": 25,
            "movecost": 50
        },
        {
            "id": "19628",
            "img": "QmfLRhL5r1yb51y3A7nsXJ5mNxFyVebTAzhn6pxBZN2Tpk",
            "name": "Nordic Warrior",
            "rarity": "Common",
            "element": "Fire",
            "attack": 18,
            "defense": 8,
            "movecost": 30
        },
        {
            "id": "19629",
            "img": "QmSaVvk6qyj7dH6pTTcxSdx1UEw8rKexEiUNVUmEjsMGu5",
            "name": "Wise Ancient One",
            "rarity": "Rare",
            "element": "Air",
            "attack": 6,
            "defense": 28,
            "movecost": 40
        },
        {
            "id": "19630",
            "img": "QmZBdDixC1wFiPh8b9ozn1k9Ge4wDqA7PzweNHYKpjtDea",
            "name": "Storm Giant",
            "rarity": "Legendary",
            "element": "Nature",
            "attack": 56,
            "defense": 17,
            "movecost": 70
        },
        {
            "id": "19631",
            "img": "QmRZUFM8snsdJ8qabrFdmpKjRVmpZ1v4YeNj6CKHWMnVFx",
            "name": "Explosives Specialist",
            "rarity": "Rare",
            "element": "Fire",
            "attack": 18,
            "defense": 34,
            "movecost": 60
        },
        {
            "id": "19632",
            "img": "QmeNWTL3EPT1nM9P8bM2PrAy38bTc9ZTg91P7XntwLmr8Q",
            "name": "Grey Peacemaker",
            "rarity": "Abundant",
            "element": "Nature",
            "attack": 17,
            "defense": 16,
            "movecost": 40
        },
        {
            "id": "19633",
            "img": "QmP3d2dYQseBb5WuEYU7h5hityyfmqGfp15ozefqHdnWTR",
            "name": "Enhanced Reptiloid",
            "rarity": "Epic",
            "element": "Gem",
            "attack": 15,
            "defense": 15,
            "movecost": 30
        },
        {
            "id": "19634",
            "img": "Qmd2Xi4ro9kyubbqWRJzEJYhCkeN96xFYqxVN5NoUwWiug",
            "name": "Robot Supersoldier",
            "rarity": "Rare",
            "element": "Metal",
            "attack": 14,
            "defense": 14,
            "movecost": 30
        },
        {
            "id": "19635",
            "img": "QmW489ditKNMNCPm9rS45eQ7a7UfPyvQtBSZGpDqzX1zJz",
            "name": "Terminator",
            "rarity": "Common",
            "element": "Metal",
            "attack": 16,
            "defense": 10,
            "movecost": 30
        },
        {
            "id": "19636",
            "img": "QmVMAjAc83vzoLcB4uEEocrMtYLdX9Q8azSBVhBE7GfVip",
            "name": "Mysterious Hacker",
            "rarity": "Epic",
            "element": "Gem",
            "attack": 15,
            "defense": 15,
            "movecost": 30
        },
        {
            "id": "19637",
            "img": "QmVXsVEkT5fuASSexrcMQuuXWZFFSuGRSyRkLGghq1yhpW",
            "name": "Stealth Mercenary",
            "rarity": "Abundant",
            "element": "Air",
            "attack": 8,
            "defense": 8,
            "movecost": 20
        },
        {
            "id": "19638",
            "img": "QmdS22WuoCWbv8eMPNiqB5CmZWTA8uxsAXeKTgR2iekszj",
            "name": "LG Soldier",
            "rarity": "Common",
            "element": "Metal",
            "attack": 10,
            "defense": 7,
            "movecost": 20
        },
        {
            "id": "19639",
            "img": "QmXv4dduAzZH3shM89PTbfpA1xRbLnDLyCoRbhbKdhg4AD",
            "name": "Grey Scientist",
            "rarity": "Common",
            "element": "Nature",
            "attack": 19,
            "defense": 22,
            "movecost": 50
        },
        {
            "id": "19640",
            "img": "QmRsc874QBk4NZDczAQUFhdUcPAoU6YM2TNLUzxEzyf6nB",
            "name": "Nordic Warrior",
            "rarity": "Common",
            "element": "Nature",
            "attack": 16,
            "defense": 10,
            "movecost": 30
        },
        {
            "id": "19641",
            "img": "QmPBHFLnc1bbEXZxUo5ee5PKz6ChSQsZqXVdMtYdTAfvTj",
            "name": "Wise Ancient One",
            "rarity": "Rare",
            "element": "Gem",
            "attack": 8,
            "defense": 25,
            "movecost": 40
        },
        {
            "id": "19642",
            "img": "Qmb78MVB1H2Za4GDKQSgm7WwCzwf7sjqQAtrHJVsKAgM5Q",
            "name": "Storm Giant",
            "rarity": "Legendary",
            "element": "Neutral",
            "attack": 50,
            "defense": 21,
            "movecost": 70
        },
        {
            "id": "19643",
            "img": "QmZiMoSCVrFqRFsDnb3ULUDo4KNGw7y2hmmuzZqU3rMkNt",
            "name": "Explosives Specialist",
            "rarity": "Rare",
            "element": "Neutral",
            "attack": 14,
            "defense": 37,
            "movecost": 60
        },
        {
            "id": "19644",
            "img": "Qme5HWgJritQsBFeGsbYW1XCpe9YBmJY5z6SLq8aGbWQtP",
            "name": "Grey Peacemaker",
            "rarity": "Abundant",
            "element": "Gem",
            "attack": 16,
            "defense": 17,
            "movecost": 40
        },
        {
            "id": "19645",
            "img": "Qmcuhm2XjJPeDeo95vSaZjCQCJn7ghrUawsDX24s1dc5yD",
            "name": "Enhanced Reptiloid",
            "rarity": "Epic",
            "element": "Fire",
            "attack": 18,
            "defense": 13,
            "movecost": 30
        },
        {
            "id": "19646",
            "img": "QmPpUYaczvcKgyV9iDXCLU2cgw5TNp3ocxgARjsN59NATa",
            "name": "Robot Supersoldier",
            "rarity": "Rare",
            "element": "Neutral",
            "attack": 15,
            "defense": 13,
            "movecost": 30
        },
        {
            "id": "19647",
            "img": "QmcdVGSJrQJfB4mQWqK23gd1uu8sJG9bvPDuTdRH57wi5C",
            "name": "Terminator",
            "rarity": "Common",
            "element": "Fire",
            "attack": 18,
            "defense": 9,
            "movecost": 30
        },
        {
            "id": "20988",
            "img": "QmSK5nzVVRk4igw62SMY4NKWT7uj9s7Qn6oo5eBnW8kHf7",
            "name": "Wise Ancient One",
            "rarity": "Rare",
            "element": "Air",
            "attack": 7,
            "defense": 30,
            "movecost": 40
        },
        {
            "id": "20990",
            "img": "QmZrBRpFtvwLhs13zSeR8PzjZt6cAeS7jSZLxhrnBK8N4Y",
            "name": "Robot Supersoldier",
            "rarity": "Rare",
            "element": "Metal",
            "attack": 16,
            "defense": 16,
            "movecost": 30
        },
        {
            "id": "20991",
            "img": "QmYUnd7s42EcRjTzmLkMHJUkpDM2U9Y7tkpi5Kj6kLBrU6",
            "name": "Wise Ancient One",
            "rarity": "Rare",
            "element": "Gem",
            "attack": 10,
            "defense": 27,
            "movecost": 40
        },
        {
            "id": "20992",
            "img": "QmQ1ujxwGZmkZA1XtZtebhzQfwi2dDPGYdGmFvkrevWh3p",
            "name": "Explosives Specialist",
            "rarity": "Rare",
            "element": "Neutral",
            "attack": 15,
            "defense": 40,
            "movecost": 60
        },
        {
            "id": "20993",
            "img": "QmeWLCKSeZtJB7bKCty5GEtVKB7tVScFZuEUjgtSYe3amt",
            "name": "Robot Supersoldier",
            "rarity": "Rare",
            "element": "Neutral",
            "attack": 18,
            "defense": 14,
            "movecost": 30
        },
        {
            "id": "28422",
            "img": "QmV3r3VUefmgh5wvmUAWEkdKbFFF6FfE9obgQsAwZrii7u",
            "name": "Astronaut (KOGs)",
            "rarity": "Epic",
            "element": "Neutral",
            "attack": 33,
            "defense": 17,
            "movecost": 50
        },
        {
            "id": "28423",
            "img": "QmXA4fXA8tErEj9kJ787hBMDy7vKZXhXXCDkRUJaeS6rbN",
            "name": "Storm Giant",
            "rarity": "Legendary",
            "element": "Nature",
            "attack": 54,
            "defense": 23,
            "movecost": 70
        },
        {
            "id": "28424",
            "img": "QmTo8fNgWALohA9qfsP86eFKsbtic98RzkWqovr5uEmBZP",
            "name": "Storm Giant",
            "rarity": "Legendary",
            "element": "Neutral",
            "attack": 58,
            "defense": 20,
            "movecost": 70
        },
        {
            "id": "44933",
            "img": "QmaX16aMZuppcnUfS6gv84bYRr2y4xYfhV17LpWVd8zh1F",
            "name": "Alien Bat (Horrors)",
            "rarity": "Epic",
            "element": "Nature",
            "attack": 14,
            "defense": 7,
            "movecost": 20
        },
        {
            "id": "141516",
            "img": "QmUaX71sZaw4UAPEhqVvUziMAboi1SnHiCA2nbPXUdc4Da",
            "name": "Mysterious Hacker",
            "rarity": "Epic",
            "element": "Air",
            "attack": 14,
            "defense": 18,
            "movecost": 30
        },
        {
            "id": "141517",
            "img": "QmekpimMv4JeRjMJr5eyhgmDbnS8S99YCt4aB277UvLu1R",
            "name": "Stealth Mercenary",
            "rarity": "Abundant",
            "element": "Neutral",
            "attack": 14,
            "defense": 11,
            "movecost": 30
        },
        {
            "id": "141518",
            "img": "QmWvaykUd9PZbu6UpHtSqSSFsvwwpmWKUc6NXMK81XK4cL",
            "name": "LG Soldier",
            "rarity": "Common",
            "element": "Neutral",
            "attack": 4,
            "defense": 5,
            "movecost": 10
        },
        {
            "id": "141519",
            "img": "QmerJ8fiUfq3N9GCFyEpw4Se8Aj582PnJkCqqstfJK3JMu",
            "name": "Grey Scientist",
            "rarity": "Common",
            "element": "Air",
            "attack": 16,
            "defense": 27,
            "movecost": 50
        },
        {
            "id": "141520",
            "img": "QmaZYhgksXg9HXuaUzTmjfbo6iYnL4go7PAHwsRghEM1rN",
            "name": "Nordic Warrior",
            "rarity": "Common",
            "element": "Fire",
            "attack": 19,
            "defense": 8,
            "movecost": 30
        },
        {
            "id": "141522",
            "img": "QmfS75oP8rWrhLWSnKyLHuaTXh6dHRjejQbTNRHHrp4k6u",
            "name": "Wise Ancient One",
            "rarity": "Rare",
            "element": "Air",
            "attack": 6,
            "defense": 30,
            "movecost": 40
        },
        {
            "id": "141523",
            "img": "QmVr8GuX6mxVj9Y5ujrdxvEqoGrRa8QHThSQbzpGFRc9XF",
            "name": "Storm Giant",
            "rarity": "Legendary",
            "element": "Nature",
            "attack": 60,
            "defense": 17,
            "movecost": 70
        },
        {
            "id": "141524",
            "img": "QmXBkQcbfne24jCUDV1AXQX8qoP6Pxk4afwvcn4Jcj3ycW",
            "name": "Explosives Specialist",
            "rarity": "Rare",
            "element": "Fire",
            "attack": 18,
            "defense": 37,
            "movecost": 60
        },
        {
            "id": "141525",
            "img": "QmQCwxyU8kAG8b3wfmZvgsPrkgZ2jjHXvRV2Jjt3YU5eQ9",
            "name": "Grey Peacemaker",
            "rarity": "Abundant",
            "element": "Nature",
            "attack": 18,
            "defense": 16,
            "movecost": 40
        },
        {
            "id": "141526",
            "img": "QmZoNp7KE53pMG4PKkuMUU36sVNdobrgAppoNXm5kw52h8",
            "name": "Enhanced Reptiloid",
            "rarity": "Epic",
            "element": "Gem",
            "attack": 15,
            "defense": 17,
            "movecost": 30
        },
        {
            "id": "141527",
            "img": "QmbqsVcPFVhuC5fHoVZXD2f5Zn8xbfBfrJoCCjoEgjiGK7",
            "name": "Robot Supersoldier",
            "rarity": "Rare",
            "element": "Metal",
            "attack": 16,
            "defense": 14,
            "movecost": 30
        },
        {
            "id": "141528",
            "img": "QmP6EyPtMtR9kAdEp61JQgcBMiwjmG8B4rzhwdJaN9fkvw",
            "name": "Terminator",
            "rarity": "Common",
            "element": "Metal",
            "attack": 17,
            "defense": 10,
            "movecost": 30
        },
        {
            "id": "141529",
            "img": "QmaJx47876hS4jESxxB8hgoDh2TXpnWKB5VZiZS11w3s5t",
            "name": "Mysterious Hacker",
            "rarity": "Epic",
            "element": "Gem",
            "attack": 15,
            "defense": 17,
            "movecost": 30
        },
        {
            "id": "141530",
            "img": "QmabApW5LGu4KeDFnBwMipwwDEWS5NCctA34uVtkMWrx57",
            "name": "Stealth Mercenary",
            "rarity": "Abundant",
            "element": "Air",
            "attack": 9,
            "defense": 8,
            "movecost": 20
        },
        {
            "id": "141531",
            "img": "QmdpedR6K3LbXd9MJKGQJm6aUPVbsJatH63cnfnjrVvrCE",
            "name": "LG Soldier",
            "rarity": "Common",
            "element": "Metal",
            "attack": 11,
            "defense": 7,
            "movecost": 20
        },
        {
            "id": "141532",
            "img": "QmQ8hqdUkfmMVdd4CF1bAwVMkLoLE2q9nqasRLPKbwh7oh",
            "name": "Grey Scientist",
            "rarity": "Common",
            "element": "Nature",
            "attack": 19,
            "defense": 24,
            "movecost": 50
        },
        {
            "id": "141533",
            "img": "QmPmWF5N12jv9dgnqFfT53PfvwLaskGcWYjjTNfR4KN3Pw",
            "name": "Nordic Warrior",
            "rarity": "Common",
            "element": "Nature",
            "attack": 17,
            "defense": 10,
            "movecost": 30
        },
        {
            "id": "141534",
            "img": "QmZVyJqqKHoXosUC44XV97Kc8eTkQD8N8kAVGqtE4zvZmA",
            "name": "Wise Ancient One",
            "rarity": "Rare",
            "element": "Gem",
            "attack": 8,
            "defense": 27,
            "movecost": 40
        },
        {
            "id": "141535",
            "img": "QmTUzFNUDiit1oxSoQtVkPpntRx9CQm81g1YLGrkW8Zydw",
            "name": "Storm Giant",
            "rarity": "Legendary",
            "element": "Neutral",
            "attack": 55,
            "defense": 21,
            "movecost": 70
        },
        {
            "id": "141536",
            "img": "QmfGwBNypKd9sdhbtoMTV37bijx1Q8isJ3GZpuoW2Z8o8U",
            "name": "Explosives Specialist",
            "rarity": "Rare",
            "element": "Neutral",
            "attack": 14,
            "defense": 40,
            "movecost": 60
        },
        {
            "id": "141537",
            "img": "QmYXpXga3uYgJny7UJtnHk5Xxrxd1FvjRyzYhQxDrCNgKB",
            "name": "Grey Peacemaker",
            "rarity": "Abundant",
            "element": "Gem",
            "attack": 16,
            "defense": 19,
            "movecost": 40
        },
        {
            "id": "141538",
            "img": "QmeVNpdTpyCKVLZrmpRiNxd2L3SYQAyVK7Pg6hLXJTgcBh",
            "name": "Enhanced Reptiloid",
            "rarity": "Epic",
            "element": "Fire",
            "attack": 20,
            "defense": 13,
            "movecost": 30
        },
        {
            "id": "141539",
            "img": "QmewTqNky1Fx8KM3aDFpGufXEogUgyeqTmzxMf8hLvCiqx",
            "name": "Robot Supersoldier",
            "rarity": "Rare",
            "element": "Neutral",
            "attack": 17,
            "defense": 13,
            "movecost": 30
        },
        {
            "id": "141540",
            "img": "QmdbFumP72gYtUXwN1wTswXHnM9bmNGNqyzns6nLpR4X5x",
            "name": "Terminator",
            "rarity": "Common",
            "element": "Fire",
            "attack": 19,
            "defense": 9,
            "movecost": 30
        },
        {
            "id": "141542",
            "img": "QmdRAkEyEvdrJwDQ9NhWj98cCpfp5pFazTaviJhjtsCN2L",
            "name": "Mysterious Hacker",
            "rarity": "Epic",
            "element": "Air",
            "attack": 16,
            "defense": 18,
            "movecost": 30
        },
        {
            "id": "141543",
            "img": "QmcXDZSau78ARPhNWgF9RdgiFhiuzD7x94oSACbLH3uEDy",
            "name": "LG Soldier",
            "rarity": "Common",
            "element": "Neutral",
            "attack": 6,
            "defense": 5,
            "movecost": 10
        },
        {
            "id": "141544",
            "img": "QmUBGpCjKqrXEUU5xQD6iwjRkLBwgzzBs6Lfd5Bzi8Uomb",
            "name": "Grey Scientist",
            "rarity": "Common",
            "element": "Air",
            "attack": 18,
            "defense": 27,
            "movecost": 50
        },
        {
            "id": "141545",
            "img": "QmXV2hTWKy5sJceEzTFxr4cQBe8SnYAGVB4Qe6b7kP3TMD",
            "name": "Nordic Warrior",
            "rarity": "Common",
            "element": "Fire",
            "attack": 19,
            "defense": 10,
            "movecost": 30
        },
        {
            "id": "141546",
            "img": "QmWmp7UR5NrprVqtkCALwHScAEnFGhcHGQP3wfCTBBaQ2Y",
            "name": "Wise Ancient One",
            "rarity": "Rare",
            "element": "Air",
            "attack": 8,
            "defense": 30,
            "movecost": 40
        },
        {
            "id": "141548",
            "img": "QmU7USzZ7QfXJLRhGBj7rhC97XJADHvPsoTUD6GEGM8QHz",
            "name": "Explosives Specialist",
            "rarity": "Rare",
            "element": "Fire",
            "attack": 20,
            "defense": 37,
            "movecost": 60
        },
        {
            "id": "141549",
            "img": "QmZTYVtaCHr7A9vzoFDuJcuK5n5CkDfZagPTTWZpXxY7bu",
            "name": "Enhanced Reptiloid",
            "rarity": "Epic",
            "element": "Gem",
            "attack": 17,
            "defense": 17,
            "movecost": 30
        },
        {
            "id": "141550",
            "img": "QmWaDkEz79ycPQ292ezXGvCwue2bt413Mwy9nouPb7swPm",
            "name": "Robot Supersoldier",
            "rarity": "Rare",
            "element": "Metal",
            "attack": 16,
            "defense": 16,
            "movecost": 30
        },
        {
            "id": "141551",
            "img": "QmdVHZRJ9yMyqodvM9E7JUhTzrKVPboc2yAssbqghTB9gr",
            "name": "Terminator",
            "rarity": "Common",
            "element": "Metal",
            "attack": 17,
            "defense": 12,
            "movecost": 30
        },
        {
            "id": "141552",
            "img": "QmUFmR6MnDcUxPEP9scnsN7vGwfffpRUzqGtrMfXozYvkB",
            "name": "Mysterious Hacker",
            "rarity": "Epic",
            "element": "Gem",
            "attack": 17,
            "defense": 17,
            "movecost": 30
        },
        {
            "id": "141553",
            "img": "QmRkaEtFAXdYPZCHPwdSvCMDHxpb77jx6LqpwodnciKfCL",
            "name": "LG Soldier",
            "rarity": "Common",
            "element": "Metal",
            "attack": 11,
            "defense": 9,
            "movecost": 20
        },
        {
            "id": "141554",
            "img": "QmUi1VL14Z9QQwuuJDbYcfJvsY6RB3muXYk568mfAwp2tD",
            "name": "Grey Scientist",
            "rarity": "Common",
            "element": "Nature",
            "attack": 21,
            "defense": 24,
            "movecost": 50
        },
        {
            "id": "141555",
            "img": "QmXizRsgb2xUwyYd7gc4L6ngvT1r3rXdHAJfxD55HfxfD4",
            "name": "Nordic Warrior",
            "rarity": "Common",
            "element": "Nature",
            "attack": 17,
            "defense": 12,
            "movecost": 30
        },
        {
            "id": "141556",
            "img": "QmbE9KJ4KH2K2J9fsV2J4CkL3hsPPQeYxWcLMLxnG5Gf7s",
            "name": "Wise Ancient One",
            "rarity": "Rare",
            "element": "Gem",
            "attack": 10,
            "defense": 27,
            "movecost": 40
        },
        {
            "id": "141557",
            "img": "QmRkzReiTskhgPtEEwji1JSTcS156rV2ifukktgP93EVEG",
            "name": "Storm Giant",
            "rarity": "Legendary",
            "element": "Neutral",
            "attack": 55,
            "defense": 24,
            "movecost": 70
        },
        {
            "id": "141558",
            "img": "QmfQMji5xVXwuhW7zNHnKjEZCkS9MtZPY5sWPDJKA18YDE",
            "name": "Explosives Specialist",
            "rarity": "Rare",
            "element": "Neutral",
            "attack": 17,
            "defense": 40,
            "movecost": 60
        },
        {
            "id": "141559",
            "img": "QmPan7Yd3Speposw1oG1e3pNW5aoZLLPoPsX11gUfFeXhr",
            "name": "Enhanced Reptiloid",
            "rarity": "Epic",
            "element": "Fire",
            "attack": 20,
            "defense": 15,
            "movecost": 30
        },
        {
            "id": "141560",
            "img": "QmZwbNiiatrxwaH8YCKcwkYqSaNUMRPbyo1ennZVBT9Miz",
            "name": "Robot Supersoldier",
            "rarity": "Rare",
            "element": "Neutral",
            "attack": 17,
            "defense": 15,
            "movecost": 30
        },
        {
            "id": "141561",
            "img": "QmVWhM6u3Ucta741ZQMBKKnBqkUmZfizKkVpH4w5fF6SRH",
            "name": "Terminator",
            "rarity": "Common",
            "element": "Fire",
            "attack": 19,
            "defense": 11,
            "movecost": 30
        },
        {
            "id": "141564",
            "img": "QmVZBMGf32XBJ7DwLjw7tgZNqnpbNZTTVwKkNeSE2uXqnM",
            "name": "Wise Ancient One",
            "rarity": "Rare",
            "element": "Air",
            "attack": 10,
            "defense": 31,
            "movecost": 40
        },
        {
            "id": "141566",
            "img": "QmUa323XRkmyoUaLqG5msNNWRSDbwbwqp8p8S9SzJB1iiC",
            "name": "Explosives Specialist",
            "rarity": "Rare",
            "element": "Fire",
            "attack": 22,
            "defense": 39,
            "movecost": 60
        },
        {
            "id": "141568",
            "img": "QmS9uJYdT1ZTJLGH4xKnsX8iW4nodeWHqiNjqWDYBRyxgg",
            "name": "Robot Supersoldier",
            "rarity": "Rare",
            "element": "Metal",
            "attack": 18,
            "defense": 17,
            "movecost": 30
        },
        {
            "id": "141570",
            "img": "QmVA3wxdustSs2NooQNREygitwC1YB19Tj9T6ykLwRoPKT",
            "name": "Wise Ancient One",
            "rarity": "Rare",
            "element": "Gem",
            "attack": 13,
            "defense": 27,
            "movecost": 40
        },
        {
            "id": "141572",
            "img": "QmVLfLPD3SSyVGbrVSwze8pM3ni9gwMhx2FCPa8G2aGL7g",
            "name": "Explosives Specialist",
            "rarity": "Rare",
            "element": "Neutral",
            "attack": 20,
            "defense": 41,
            "movecost": 60
        },
        {
            "id": "141574",
            "img": "QmPjTK5tS8yjm86W64Zx6LiaqtUs1LMnXu12qXsJ5LnDFW",
            "name": "Robot Supersoldier",
            "rarity": "Rare",
            "element": "Neutral",
            "attack": 20,
            "defense": 15,
            "movecost": 30
        },
        {
            "id": "235640",
            "img": "QmNxjDDSCWA2CpbUvrYVCfz4fixAy2Le35gw1pRoSGYKYd",
            "name": "Intergalactic Zombie",
            "rarity": "Rare",
            "element": "Neutral",
            "attack": 18,
            "defense": 10,
            "movecost": 30
        },
        {
            "id": "235641",
            "img": "QmcgerxREiHk5Sg5y6KHERjaTDorWFaCZfqTPKFySZCuKA",
            "name": "Interstellar Emissary",
            "rarity": "Epic",
            "element": "Air",
            "attack": 7,
            "defense": 13,
            "movecost": 20
        },
        {
            "id": "235642",
            "img": "Qmd499F22h4LPTZkrqeambQGnTzVJ5Lxn6RX6cKBdYmbGh",
            "name": "Intergalactic Zombie",
            "rarity": "Rare",
            "element": "Neutral",
            "attack": 19,
            "defense": 10,
            "movecost": 30
        },
        {
            "id": "235643",
            "img": "QmXasj6ZV7u4ukdw7pme3LJZT61miCh9wJ2wELLBNoS58o",
            "name": "Interstellar Emissary",
            "rarity": "Epic",
            "element": "Air",
            "attack": 7,
            "defense": 14,
            "movecost": 20
        },
        {
            "id": "235644",
            "img": "QmTMfZ3b3WfuPDLZyeYiZY1KsW8HKusXqtXgihE7VuV6Rw",
            "name": "Intergalactic Zombie",
            "rarity": "Rare",
            "element": "Neutral",
            "attack": 19,
            "defense": 11,
            "movecost": 30
        },
        {
            "id": "235645",
            "img": "QmTKLaWzEawMYdnwrQJf5MZYVC7SCCYcTff6sqCnkuKvRj",
            "name": "Interstellar Emissary",
            "rarity": "Epic",
            "element": "Air",
            "attack": 8,
            "defense": 14,
            "movecost": 20
        },
        {
            "id": "235646",
            "img": "QmRzvXRMwRWt98Au7YGdLps9RQXQ5oXiRv73Srg7SuEhTV",
            "name": "Intergalactic Zombie",
            "rarity": "Rare",
            "element": "Neutral",
            "attack": 20,
            "defense": 11,
            "movecost": 30
        },
        {
            "id": "235647",
            "img": "QmedQrH84cXNHK2ApHV8MXzZsM4wPw1QM41zkU5EBJ1Jew",
            "name": "Interstellar Emissary",
            "rarity": "Epic",
            "element": "Air",
            "attack": 8,
            "defense": 15,
            "movecost": 20
        },
        {
            "id": "254350",
            "img": "QmNxjDDSCWA2CpbUvrYVCfz4fixAy2Le35gw1pRoSGYKYd",
            "name": "Intergalactic Zombie",
            "rarity": "Rare",
            "element": "Neutral",
            "attack": 18,
            "defense": 10,
            "movecost": 30
        },
        {
            "id": "254351",
            "img": "QmcgerxREiHk5Sg5y6KHERjaTDorWFaCZfqTPKFySZCuKA",
            "name": "Interstellar Emissary",
            "rarity": "Epic",
            "element": "Air",
            "attack": 7,
            "defense": 13,
            "movecost": 20
        },
        {
            "id": "254352",
            "img": "Qmd499F22h4LPTZkrqeambQGnTzVJ5Lxn6RX6cKBdYmbGh",
            "name": "Intergalactic Zombie",
            "rarity": "Rare",
            "element": "Neutral",
            "attack": 19,
            "defense": 10,
            "movecost": 30
        },
        {
            "id": "254354",
            "img": "QmTMfZ3b3WfuPDLZyeYiZY1KsW8HKusXqtXgihE7VuV6Rw",
            "name": "Intergalactic Zombie",
            "rarity": "Rare",
            "element": "Neutral",
            "attack": 19,
            "defense": 11,
            "movecost": 30
        },
        {
            "id": "254356",
            "img": "QmRzvXRMwRWt98Au7YGdLps9RQXQ5oXiRv73Srg7SuEhTV",
            "name": "Intergalactic Zombie",
            "rarity": "Rare",
            "element": "Neutral",
            "attack": 20,
            "defense": 11,
            "movecost": 30
        }
    ]
;

export const nfts_weapons =
    [
        {
            "id": "19582",
            "img": "QmbwsoeaUnbEy6abAE2sQJ6coy9eFzLP2SiNTi9hgb15Rp",
            "name": "Waxon Fire Sword",
            "rarity": "Mythical",
            "class": "Fire",
            "attack": 15,
            "defense": 9
        },
        {
            "id": "19583",
            "img": "QmYfMb6jv4fAkYKCYn36kHeXE1PpPhqwCuh5jP9KbJs8jC",
            "name": "Standard Sword",
            "rarity": "Abundant",
            "class": "Neutral",
            "attack": 11,
            "defense": 5
        },
        {
            "id": "19584",
            "img": "QmVAoDXfmabrp1g9Fk1KxRqqzerp4CUXfpU2S9HB6NSPbv",
            "name": "Rock Blade",
            "rarity": "Common",
            "class": "Nature",
            "attack": 13,
            "defense": 4
        },
        {
            "id": "19585",
            "img": "QmS1vdZR4LYcYZMcGXHc9twRfUNy811gEoEv1bb1Nrti94",
            "name": "Stave of Deception",
            "rarity": "Common",
            "class": "Fire",
            "attack": 6,
            "defense": 11
        },
        {
            "id": "19586",
            "img": "Qmf8XMEn7rBuVE7qZQ6kiStgdwp13fLLp8bxLtQe8fW9Hx",
            "name": "Titanium Dagger",
            "rarity": "Common",
            "class": "Metal",
            "attack": 12,
            "defense": 5
        },
        {
            "id": "19587",
            "img": "QmVv8spPgvwdY8JPztXpHkit9vrZVLCYgc5ob6yXhwCfjQ",
            "name": "Jaggered Spear",
            "rarity": "Rare",
            "class": "Air",
            "attack": 14,
            "defense": 4
        },
        {
            "id": "19588",
            "img": "QmPXDcGLNPyJP3vH34w45dvyqNNsRyhDFiq6oz85uuT4WE",
            "name": "Kite Axe",
            "rarity": "Common",
            "class": "Neutral",
            "attack": 9,
            "defense": 8
        },
        {
            "id": "19589",
            "img": "QmVZcV2BdNAxyrortmE1j5u5KUywUQCXUuG2uNHvaL8hxU",
            "name": "Emerald Dagger",
            "rarity": "Rare",
            "class": "Gem",
            "attack": 14,
            "defense": 5
        },
        {
            "id": "19590",
            "img": "QmU5x3spraNBnE96XaRWDewNDoYtAeSj7CRyx49uwuMQGR",
            "name": "Equalizer Bow",
            "rarity": "Rare",
            "class": "Neutral",
            "attack": 15,
            "defense": 3
        },
        {
            "id": "19591",
            "img": "QmbvNr7HqQoNCqryfQC4X2J3fz5MzAtW7YyjBxNdsALeBB",
            "name": "Widow Maker",
            "rarity": "Common",
            "class": "Metal",
            "attack": 10,
            "defense": 7
        },
        {
            "id": "19592",
            "img": "QmZcquuMDjjQbzSezubQTvSctr7U3BSvY5i8rfrTTFM8LD",
            "name": "Storm Edge Sword",
            "rarity": "Rare",
            "class": "Air",
            "attack": 11,
            "defense": 8
        },
        {
            "id": "19593",
            "img": "QmVxmdvnfbnjnRPE81LSBcJoVdx4ikxTEF9rSKzWyjZGpH",
            "name": "Waxon Staff",
            "rarity": "Epic",
            "class": "Nature",
            "attack": 9,
            "defense": 12
        },
        {
            "id": "19594",
            "img": "QmUQUBLRm7sJUkJaiMHdgECKFfFxrKJLVP228Q1HL1XAu7",
            "name": "Spike Hammer",
            "rarity": "Common",
            "class": "Metal",
            "attack": 12,
            "defense": 5
        },
        {
            "id": "19595",
            "img": "QmcwJwqB2GSmkbPiAYrHM7rAiJ45JmBS6shHiGbbtme3Xp",
            "name": "Star Fire Sword",
            "rarity": "Epic",
            "class": "Fire",
            "attack": 13,
            "defense": 7
        },
        {
            "id": "19596",
            "img": "QmeG68PrWhNkKtG43QNFWNhRa6nDUh4iHLX3hy9FxXLtsA",
            "name": "Sandmaster Spear",
            "rarity": "Epic",
            "class": "Nature",
            "attack": 15,
            "defense": 5
        },
        {
            "id": "19597",
            "img": "QmVTtT1L5AKmZjLomg5kzBGbRY8WiUgER5SEdkYGKDqCHs",
            "name": "Waxon Sword",
            "rarity": "Rare",
            "class": "Gem",
            "attack": 11,
            "defense": 8
        },
        {
            "id": "19598",
            "img": "Qmf6rVpa7SZFApUwg7aDQQRNEPxZbhxvVcsubb94gWoEHM",
            "name": "Healing Blade",
            "rarity": "Legendary",
            "class": "Air",
            "attack": 7,
            "defense": 15
        },
        {
            "id": "19599",
            "img": "QmYHGx46XC9F3RozxrA1i4eZMdnGwawWGsRksNVZX6GAKZ",
            "name": "Elite Dagger",
            "rarity": "Epic",
            "class": "Neutral",
            "attack": 16,
            "defense": 5
        },
        {
            "id": "19600",
            "img": "Qme2veDybAw4MCgq9Ymv5s6uKcCWRwm7HgRYSsN6PArTyp",
            "name": "Eternal Blade",
            "rarity": "Legendary",
            "class": "Gem",
            "attack": 10,
            "defense": 13
        },
        {
            "id": "19601",
            "img": "QmTU6CJBxJfLDGHWDpjU6aST7sJSLZVjBQB6Y6UgKuUdER",
            "name": "Randomizer Bow",
            "rarity": "Epic",
            "class": "Gem",
            "attack": 16,
            "defense": 4
        },
        {
            "id": "19602",
            "img": "QmPJmBxGccWdHJ3dWP87mNg9J6NNNzVMta6W1ahStz4MTu",
            "name": "Moonshot Blade",
            "rarity": "Rare",
            "class": "Air",
            "attack": 13,
            "defense": 5
        },
        {
            "id": "19603",
            "img": "QmPBRF42tR2VVozFxUTHC6XUeXmxsM33reN3ZuRN8BFgyd",
            "name": "Dawn Sword",
            "rarity": "Rare",
            "class": "Metal",
            "attack": 10,
            "defense": 9
        },
        {
            "id": "19604",
            "img": "QmRcyzmjWAcXLi2uXPbzGCejw2yXndKVsQwmWEK1y5pMUR",
            "name": "Confuser",
            "rarity": "Common",
            "class": "Neutral",
            "attack": 4,
            "defense": 13
        },
        {
            "id": "19605",
            "img": "QmepYeviPfEEEEws8PURpSkTQYSUPAueAgXMqCfZ7WTU3r",
            "name": "Necromancers Hammer",
            "rarity": "Rare",
            "class": "Gem",
            "attack": 10,
            "defense": 9
        },
        {
            "id": "19606",
            "img": "QmYy1wpiBHjJM4Upu5xJj6eVLWvmsNPPaHFSKoM3RMp3ak",
            "name": "Green Axe",
            "rarity": "Rare",
            "class": "Nature",
            "attack": 9,
            "defense": 10
        },
        {
            "id": "19607",
            "img": "QmSHc7gzuSqQckuNr1moYKBMY9TNQX1E7L4EC3i4mRzuV6",
            "name": "Nordic Warhammer",
            "rarity": "Rare",
            "class": "Metal",
            "attack": 12,
            "defense": 7
        },
        {
            "id": "19608",
            "img": "QmaucxgkiGwm1aJ1dMCvqfMoZJP6wBzLKcjCpe9pA3ZMzc",
            "name": "Galatic Fireblade",
            "rarity": "Legendary",
            "class": "Fire",
            "attack": 13,
            "defense": 10
        },
        {
            "id": "19609",
            "img": "QmWaWj1K2yvVbQWMiePHR5scbpus3hXYHEwWi5wJDjhTVf",
            "name": "Rock Cudgel",
            "rarity": "Abundant",
            "class": "Nature",
            "attack": 8,
            "defense": 8
        },
        {
            "id": "19610",
            "img": "QmPSjMKxC6aZYJiKxSVtb6yVRB7CYC327zUt7dpEbMXot2",
            "name": "Standard Issue Axe",
            "rarity": "Abundant",
            "class": "Neutral",
            "attack": 10,
            "defense": 6
        },
        {
            "id": "19611",
            "img": "QmRP36nkVQxknGRX8sA1D5Rrg36pvvTbQvs4Fy6HRQGcPi",
            "name": "Divine Blade",
            "rarity": "Legendary",
            "class": "Metal",
            "attack": 12,
            "defense": 11
        },
        {
            "id": "19612",
            "img": "QmVhQkDRKkWUivn34niz7LThbaBujTRALCZ6zLqJQVTuKT",
            "name": "Dagger of Creation",
            "rarity": "Epic",
            "class": "Nature",
            "attack": 15,
            "defense": 6
        },
        {
            "id": "19613",
            "img": "QmNWcW1W8HPAFwxUMTctSrmTGDv1S1A8d87uxjEW1kiw4R",
            "name": "Lithium Dagger",
            "rarity": "Common",
            "class": "Air",
            "attack": 13,
            "defense": 4
        },
        {
            "id": "19614",
            "img": "QmawqkcVKKKGvDCcR9KqZB7Dt2Vz7D2EhvyA191yMTMQcD",
            "name": "Sungorger",
            "rarity": "Mythical",
            "class": "Gem",
            "attack": 13,
            "defense": 10
        },
        {
            "id": "19615",
            "img": "QmNsHCG4Kfi1VP8s7x6xdqbxGrZMGfHuuH81G3TYvGqzA1",
            "name": "Toothed Dagger",
            "rarity": "Epic",
            "class": "Neutral",
            "attack": 12,
            "defense": 8
        },
        {
            "id": "19616",
            "img": "QmWajBsRjC2AuATWaiLU1Tit1nxmeDnQjk2oZ8yy2AG9vb",
            "name": "Infernal Axe",
            "rarity": "Common",
            "class": "Fire",
            "attack": 12,
            "defense": 5
        },
        {
            "id": "19617",
            "img": "QmWQ5dCGq8sgM6d4MvZdtMPyszYZQyu9mggAdpd4sY4FHJ",
            "name": "Reptiloid Blade",
            "rarity": "Rare",
            "class": "Fire",
            "attack": 13,
            "defense": 5
        },
        {
            "id": "19618",
            "img": "QmNcfk2AfPb9TMRH7xnTKTgRwNyXX5WXF5MkU3Y5fjfa55",
            "name": "AI Sword",
            "rarity": "Legendary",
            "class": "Metal",
            "attack": 12,
            "defense": 11
        },
        {
            "id": "19619",
            "img": "Qmd9MPkRCXxgxLaAfs39sHYiQEvQ8w9zb6HQ4i6PJWTHrb",
            "name": "Standard Shield",
            "rarity": "Abundant",
            "class": "Neutral",
            "attack": 3,
            "defense": 13
        },
        {
            "id": "19620",
            "img": "QmRkAmq8kJaWJniQTKVLVP5F4WoVdLLjxaSm3DYtowARWN",
            "name": "Sky Shield",
            "rarity": "Common",
            "class": "Air",
            "attack": 4,
            "defense": 13
        },
        {
            "id": "19621",
            "img": "QmVJSA6EJs8VrDECwJHC674yBhPTab7Nh5CPS84rQBjLaN",
            "name": "Plasma Shield",
            "rarity": "Common",
            "class": "Fire",
            "attack": 5,
            "defense": 12
        },
        {
            "id": "19622",
            "img": "QmYoCRv2kbwVQBAMxbneyVibhEhjoDqYkKchY1MuuvV4iw",
            "name": "Waxon Shield",
            "rarity": "Epic",
            "class": "Nature",
            "attack": 8,
            "defense": 12
        },
        {
            "id": "19623",
            "img": "QmTfp2Tnkm89TECjuCjnhkMBu9USTRVjh7kRKpYTAVvcip",
            "name": "Leveller Bow",
            "rarity": "Mythical",
            "class": "Gem",
            "attack": 12,
            "defense": 12
        },
        {
            "id": "20973",
            "img": "QmaywLvkZRTFX962pgqfRrsBzV9QesHC4iA2eYGKdNXXHr",
            "name": "Jaggered Spear",
            "rarity": "Rare",
            "class": "Air",
            "attack": 16,
            "defense": 7
        },
        {
            "id": "20975",
            "img": "Qma9auPSkpAotYKMbhFH7B67kwK656BURF85MFPKqNnGG2",
            "name": "Equalizer Bow",
            "rarity": "Rare",
            "class": "Neutral",
            "attack": 17,
            "defense": 5
        },
        {
            "id": "20976",
            "img": "QmcNgrezkCPoEX4R4ESUN3EsdUKPRrjNHxZdsF8DKayV5U",
            "name": "Storm Edge Sword",
            "rarity": "Rare",
            "class": "Air",
            "attack": 13,
            "defense": 10
        },
        {
            "id": "20977",
            "img": "QmVtbBgD46imEG3iddpZpbpta1wb5uQ627r1HTHDRHzXDY",
            "name": "Waxon Sword",
            "rarity": "Rare",
            "class": "Gem",
            "attack": 13,
            "defense": 10
        },
        {
            "id": "20981",
            "img": "QmemD6CxZ9woSrND1utrRM7xPSjwAmecojMeq3nAJCkEmr",
            "name": "Green Axe",
            "rarity": "Rare",
            "class": "Nature",
            "attack": 11,
            "defense": 12
        },
        {
            "id": "20982",
            "img": "QmcvE3iXRaj63eTaueBYy86TQNzPjcxaWhniif7pWTQytU",
            "name": "Nordic Warhammer",
            "rarity": "Rare",
            "class": "Metal",
            "attack": 14,
            "defense": 9
        },
        {
            "id": "20983",
            "img": "QmaW3rAdc1xDPcDq5eWYQTyZPMkYTRc8gA7oxp71BFuQsK",
            "name": "Reptiloid Blade",
            "rarity": "Rare",
            "class": "Fire",
            "attack": 15,
            "defense": 8
        },
        {
            "id": "56042",
            "img": "QmUVngi2ZwB9mxpkBe6Yc2TdFXaGJcAkVZPdio1Hbptkbg",
            "name": "Moonkey Scepter",
            "rarity": "Legendary",
            "class": "Air",
            "attack": 11,
            "defense": 11
        },
        {
            "id": "141407",
            "img": "QmSPqLNB13FJFw8BSGFeUqoM7wsJ4dyK59sPRU8LBXdLai",
            "name": "Standard Sword",
            "rarity": "Abundant",
            "class": "Neutral",
            "attack": 12,
            "defense": 5
        },
        {
            "id": "141408",
            "img": "Qmcu69GqpJqQdFHFdX7KeMSZRECqCNDWjZCe74JBkPSDjB",
            "name": "Rock Blade",
            "rarity": "Common",
            "class": "Nature",
            "attack": 14,
            "defense": 4
        },
        {
            "id": "141409",
            "img": "QmShRQEokyLrbikzYvdfaMqRV1xxzecainQb6QXGTfrWQv",
            "name": "Stave of Deception",
            "rarity": "Common",
            "class": "Fire",
            "attack": 6,
            "defense": 12
        },
        {
            "id": "141410",
            "img": "QmeYd2rSTZ1sodKxSCiyfMK7WZTsi3kqCRMkMXCzAqX9ak",
            "name": "Titanium Dagger",
            "rarity": "Common",
            "class": "Metal",
            "attack": 13,
            "defense": 5
        },
        {
            "id": "141411",
            "img": "Qmejoy8axNLXSuu4LrVnCehHEgrecdt84BDEtngK6sCbH5",
            "name": "Jaggered Spear",
            "rarity": "Rare",
            "class": "Air",
            "attack": 15,
            "defense": 4
        },
        {
            "id": "141412",
            "img": "QmYFTJbmQsZ9ZBoRUFhaNVsbqjBmAFHmE2K1k9Fpja3G1B",
            "name": "Kite Axe",
            "rarity": "Common",
            "class": "Neutral",
            "attack": 10,
            "defense": 8
        },
        {
            "id": "141413",
            "img": "QmZLE7zaXHhMRMzJA2zWsJi9NgWTLXtiwWmeCKAjSwbMUk",
            "name": "Emerald Dagger",
            "rarity": "Rare",
            "class": "Gem",
            "attack": 15,
            "defense": 5
        },
        {
            "id": "141414",
            "img": "QmZmWwQL3qdeWhkrHvNZYevkLKcLd7VDT3BTUACfVXvEtc",
            "name": "Equalizer Bow",
            "rarity": "Rare",
            "class": "Neutral",
            "attack": 16,
            "defense": 3
        },
        {
            "id": "141415",
            "img": "QmSgpMoPnRPxuFu6ngZXW79ZxUroNPnVNakepLmbEpXQHq",
            "name": "Widow Maker",
            "rarity": "Common",
            "class": "Metal",
            "attack": 11,
            "defense": 7
        },
        {
            "id": "141416",
            "img": "QmXndkJF71xdFbiCeDF2gcxKRXU5thB4SwsiHK2tmZejT8",
            "name": "Storm Edge Sword",
            "rarity": "Rare",
            "class": "Air",
            "attack": 12,
            "defense": 8
        },
        {
            "id": "141417",
            "img": "QmfQdmtzd4PLseGB9dwoVGJc89zvdwhqpgVC59HJ7S7GQg",
            "name": "Waxon Staff",
            "rarity": "Epic",
            "class": "Nature",
            "attack": 9,
            "defense": 13
        },
        {
            "id": "141418",
            "img": "QmP74Xgy3p8eGdD412aDbWiA3YMcmgi1AqQnvzWYCk67eM",
            "name": "Spike Hammer",
            "rarity": "Common",
            "class": "Metal",
            "attack": 13,
            "defense": 5
        },
        {
            "id": "141419",
            "img": "QmYRdBvd9z16xjmTvqHmMHUdkyjzbN1h8yaj4uuFfwqMY5",
            "name": "Star Fire Sword",
            "rarity": "Epic",
            "class": "Fire",
            "attack": 14,
            "defense": 7
        },
        {
            "id": "141420",
            "img": "QmNvbB4tyMu9iadGEeLKed2Z8pEQ5diTjNtW8KjhupP3QC",
            "name": "Sandmaster Spear",
            "rarity": "Epic",
            "class": "Nature",
            "attack": 16,
            "defense": 5
        },
        {
            "id": "141421",
            "img": "QmY9CB5QbmGYJZEddtixdAnzcVvmSzDXCA1y6gGG5mLLTL",
            "name": "Waxon Sword",
            "rarity": "Rare",
            "class": "Gem",
            "attack": 12,
            "defense": 8
        },
        {
            "id": "141422",
            "img": "Qma3rV1Cw7e3Dj2T4xyNga9C9xUF8QvVhwCmkR2adijfHa",
            "name": "Healing Blade",
            "rarity": "Legendary",
            "class": "Air",
            "attack": 7,
            "defense": 16
        },
        {
            "id": "141423",
            "img": "QmVj1rp11TnGjgMVDAyBEu9onuxGUD3XSu4sBckDW4JJ6n",
            "name": "Elite Dagger",
            "rarity": "Epic",
            "class": "Neutral",
            "attack": 17,
            "defense": 5
        },
        {
            "id": "141424",
            "img": "QmSRdhsXQLACEmKas7GPWHt42cRcqji6jYvgAL5bumN5RJ",
            "name": "Eternal Blade",
            "rarity": "Legendary",
            "class": "Gem",
            "attack": 10,
            "defense": 14
        },
        {
            "id": "141425",
            "img": "QmXjrF81w69McS7euLQDTxCxQmwRmJ7yq8ytFVpyRk2nEY",
            "name": "Randomizer Bow",
            "rarity": "Epic",
            "class": "Gem",
            "attack": 17,
            "defense": 4
        },
        {
            "id": "141426",
            "img": "QmYdrn6PVpoinxCmWvGSeSEfZcw9x5nKvDLpWCBVRPo6U9",
            "name": "Moonshot Blade",
            "rarity": "Rare",
            "class": "Air",
            "attack": 14,
            "defense": 5
        },
        {
            "id": "141427",
            "img": "QmPomH2gF21EhrPcXxyRqJg4nFb7L8wZsfspfL11Lz9V5L",
            "name": "Dawn Sword",
            "rarity": "Rare",
            "class": "Metal",
            "attack": 11,
            "defense": 9
        },
        {
            "id": "141428",
            "img": "QmdbQh8VZvetB6aC5gdYAww9Vffs5etBXGw26RCSG8XJN4",
            "name": "Confuser",
            "rarity": "Common",
            "class": "Neutral",
            "attack": 4,
            "defense": 14
        },
        {
            "id": "141429",
            "img": "QmR39whiQiHZbsgSN13txUC44u3tyjQuLFWYk6LtQKgskX",
            "name": "Necromancers Hammer",
            "rarity": "Rare",
            "class": "Gem",
            "attack": 11,
            "defense": 9
        },
        {
            "id": "141430",
            "img": "QmTBVXdRb1g2RiMA2hYtwLyDQNH4oNC5ko3ZoszLhE4Ms7",
            "name": "Green Axe",
            "rarity": "Rare",
            "class": "Nature",
            "attack": 9,
            "defense": 11
        },
        {
            "id": "141431",
            "img": "QmY2NQSsv8y6aLu9GDKHcDBYX2cDgam4EGSTnC7YVYGQhL",
            "name": "Nordic Warhammer",
            "rarity": "Rare",
            "class": "Metal",
            "attack": 13,
            "defense": 7
        },
        {
            "id": "141432",
            "img": "QmdRojMeEP3ZGxU3c7ErM1JbdjJWUtaAEVrqncrc5ATsci",
            "name": "Galatic Fireblade",
            "rarity": "Legendary",
            "class": "Fire",
            "attack": 14,
            "defense": 10
        },
        {
            "id": "141433",
            "img": "QmVECSENYLYpJugNW94vuvrDYwVki1M76t8wP72F74asZL",
            "name": "Rock Cudgel",
            "rarity": "Abundant",
            "class": "Nature",
            "attack": 8,
            "defense": 9
        },
        {
            "id": "141434",
            "img": "QmUz6NgZwVYE3MZ9y8Jc5eBe1V8ZknpAvzQ8eZiS5J9GPK",
            "name": "Standard Issue Axe",
            "rarity": "Abundant",
            "class": "Neutral",
            "attack": 11,
            "defense": 6
        },
        {
            "id": "141435",
            "img": "QmZuQQfBy1nnMgmWeqStkv4CLMaG5aWPZoxZKrt65TBbQ5",
            "name": "Divine Blade",
            "rarity": "Legendary",
            "class": "Metal",
            "attack": 13,
            "defense": 11
        },
        {
            "id": "141436",
            "img": "QmQqRwibP31xYrBXRCcaqrWqjHfaXfMLp8ypDfigcRV3rm",
            "name": "Dagger of Creation",
            "rarity": "Epic",
            "class": "Nature",
            "attack": 16,
            "defense": 6
        },
        {
            "id": "141437",
            "img": "QmPDMfztKvUJ9kV3kHnr8UDr2v8HtejQHQgvCqhf5BoB4x",
            "name": "Lithium Dagger",
            "rarity": "Common",
            "class": "Air",
            "attack": 14,
            "defense": 4
        },
        {
            "id": "141439",
            "img": "Qmd5gkokMYUGEehETqfYQ7ebo3FV33qanS4y9piaaej5UR",
            "name": "Toothed Dagger",
            "rarity": "Epic",
            "class": "Neutral",
            "attack": 13,
            "defense": 8
        },
        {
            "id": "141440",
            "img": "QmR8DMjT3bHKP8KUMHMZoPNjUCu23yMtJurzB6qvbr5q8b",
            "name": "Infernal Axe",
            "rarity": "Common",
            "class": "Fire",
            "attack": 13,
            "defense": 5
        },
        {
            "id": "141441",
            "img": "QmYZcqTJFAdGFjtGara8yoqVgRBtQjqsPc5ZuFvVnciEVh",
            "name": "Reptiloid Blade",
            "rarity": "Rare",
            "class": "Fire",
            "attack": 14,
            "defense": 5
        },
        {
            "id": "141442",
            "img": "QmVTAdMgSx5Jk3zcxC7prbfXYK454yyx9FoeBDKScXayzM",
            "name": "AI Sword",
            "rarity": "Legendary",
            "class": "Metal",
            "attack": 13,
            "defense": 11
        },
        {
            "id": "141443",
            "img": "QmVzrhqD6yWaQSu6XLAY7m5Qdt1rzL4tQRdGMBYJ6PtE6w",
            "name": "Standard Shield",
            "rarity": "Abundant",
            "class": "Neutral",
            "attack": 3,
            "defense": 14
        },
        {
            "id": "141444",
            "img": "QmazwXQpB6n8twaHXJJXQbAJo2AxNA3WmS9crgYQ6iDij4",
            "name": "Sky Shield",
            "rarity": "Common",
            "class": "Air",
            "attack": 4,
            "defense": 14
        },
        {
            "id": "141445",
            "img": "QmbrBC1xdUMWtNk6YgKBQ9TDV7CCcP3vXAzhntiXnHfQUX",
            "name": "Plasma Shield",
            "rarity": "Common",
            "class": "Fire",
            "attack": 5,
            "defense": 13
        },
        {
            "id": "141446",
            "img": "QmcU7bpmisd5b7NafQad95h4KN5WWhaj7JCjHUyFRj645q",
            "name": "Waxon Shield",
            "rarity": "Epic",
            "class": "Nature",
            "attack": 8,
            "defense": 13
        },
        {
            "id": "141447",
            "img": "Qma9UZGSquKb763JW7p3mJCjB8at1dsAFKVckocXdWd5h3",
            "name": "Leveller Bow",
            "rarity": "Mythical",
            "class": "Gem",
            "attack": 12,
            "defense": 13
        },
        {
            "id": "141448",
            "img": "QmaTGdLoBWBXew95Y2iCbag8hCKjK6bc9sZNyTmJxAXdDh",
            "name": "Moonkey Scepter",
            "rarity": "Legendary",
            "class": "Air",
            "attack": 12,
            "defense": 11
        },
        {
            "id": "141450",
            "img": "QmYFb4ocL5sGyLKiKMZ1wAqaPh2fnCtyU1YUnRj77CjiFs",
            "name": "Rock Blade",
            "rarity": "Common",
            "class": "Nature",
            "attack": 14,
            "defense": 5
        },
        {
            "id": "141451",
            "img": "QmaR6y4nvJLdDFMHQhuvQBbgqez9SLEsQiaudVhjfJ3xBV",
            "name": "Stave of Deception",
            "rarity": "Common",
            "class": "Fire",
            "attack": 7,
            "defense": 12
        },
        {
            "id": "141452",
            "img": "QmV5ii84iwpavZTbGCyDcLhQcyMgeu27XoctUdiNqnf2Rq",
            "name": "Titanium Dagger",
            "rarity": "Common",
            "class": "Metal",
            "attack": 13,
            "defense": 6
        },
        {
            "id": "141453",
            "img": "QmbGhpo5pNzMCdNnunrSapjpo4xxNfBqE1EzWR6hmwcQos",
            "name": "Jaggered Spear",
            "rarity": "Rare",
            "class": "Air",
            "attack": 15,
            "defense": 5
        },
        {
            "id": "141454",
            "img": "QmZ9oPJHgsdaUbieRzUTBroapaf5CKzE8mZpAPaoAbVQUo",
            "name": "Kite Axe",
            "rarity": "Common",
            "class": "Neutral",
            "attack": 10,
            "defense": 9
        },
        {
            "id": "141455",
            "img": "QmRPKRD6m9wPptSwFyPUuLzVcqScZbVADnZTbSt7j4uYAQ",
            "name": "Emerald Dagger",
            "rarity": "Rare",
            "class": "Gem",
            "attack": 15,
            "defense": 6
        },
        {
            "id": "141456",
            "img": "QmesN7jQc5k71wyJM3FKQv6vuJ4iV5hnguuDQN8UhQwovz",
            "name": "Equalizer Bow",
            "rarity": "Rare",
            "class": "Neutral",
            "attack": 16,
            "defense": 4
        },
        {
            "id": "141457",
            "img": "QmeXCZbGg6WLiZ9iAKu46Vkiagn4vQPLepsXM3auWKrZHG",
            "name": "Widow Maker",
            "rarity": "Common",
            "class": "Metal",
            "attack": 11,
            "defense": 8
        },
        {
            "id": "141458",
            "img": "Qmb3VTP5yGSazQo4vPJvvwMMAKVgCjjoBvoX2Kb9Yr8aY8",
            "name": "Storm Edge Sword",
            "rarity": "Rare",
            "class": "Air",
            "attack": 13,
            "defense": 8
        },
        {
            "id": "141459",
            "img": "QmYer7BgPAGRAbFUbtV7uhSRCbmkQdiX1kYRkVLFH8GQEK",
            "name": "Waxon Staff",
            "rarity": "Epic",
            "class": "Nature",
            "attack": 10,
            "defense": 13
        },
        {
            "id": "141460",
            "img": "QmbxCqGeQnSTRRukMy7DknxY3QuWtpdHiU6Cmu5ix2LFda",
            "name": "Spike Hammer",
            "rarity": "Common",
            "class": "Metal",
            "attack": 13,
            "defense": 6
        },
        {
            "id": "141461",
            "img": "QmQxP6LiQ5jWkkFkBRGt1YAy2Vx3uRnY5TixhhSWNRNvgB",
            "name": "Star Fire Sword",
            "rarity": "Epic",
            "class": "Fire",
            "attack": 14,
            "defense": 8
        },
        {
          "id": "141462",
          "img": "QmSZU2XemaKZ1FB9vobhjKzLN4pmPGaNGevUezpERboLk9",
          "name": "Sandmaster Spear",
          "rarity": "Epic",
          "class": "Nature",
          "attack": 16,
          "defense": 6
        },
        {
            "id": "141463",
            "img": "QmNPrAfSXUVo4jBuDuPVCs1kjHsj2Mi7jPLZGRyZBfUziD",
            "name": "Waxon Sword",
            "rarity": "Rare",
            "class": "Gem",
            "attack": 12,
            "defense": 9
        },
        {
            "id": "141464",
            "img": "QmS2beADMfRbEeTWMd2bL5nf8xf6PXJ8fitiu1T6bTxiJk",
            "name": "Healing Blade",
            "rarity": "Legendary",
            "class": "Air",
            "attack": 8,
            "defense": 16
        },
        {
            "id": "141465",
            "img": "QmTCgPdTqvruaCzrsQrtU26pK3x6ptgdp3ewuyQeJGwoqB",
            "name": "Elite Dagger",
            "rarity": "Epic",
            "class": "Neutral",
            "attack": 17,
            "defense": 6
        },
        {
            "id": "141467",
            "img": "QmQXCPjipygXAbo6opLUbFDYQjuKAbdx3gsmJkU2huomEr",
            "name": "Randomizer Bow",
            "rarity": "Epic",
            "class": "Gem",
            "attack": 17,
            "defense": 5
        },
        {
            "id": "141468",
            "img": "QmaDwhGrkxewbHwsEwCzfYs3iz6Lfnu9GeBbz2tWWuBsg1",
            "name": "Moonshot Blade",
            "rarity": "Rare",
            "class": "Air",
            "attack": 14,
            "defense": 6
        },
        {
            "id": "141469",
            "img": "QmeZugmm4v9kUX3rL9PCLobWkUxkc3WDFDySihEydXNQgk",
            "name": "Dawn Sword",
            "rarity": "Rare",
            "class": "Metal",
            "attack": 11,
            "defense": 10
        },
        {
            "id": "141470",
            "img": "QmbnawWyuTC3CXVn5avntptsqqoHEKYpMxGsT3RMaZRixZ",
            "name": "Confuser",
            "rarity": "Common",
            "class": "Neutral",
            "attack": 5,
            "defense": 14
        },
        {
            "id": "141471",
            "img": "QmZMzpuno58o6C9j8tDxfNsxPiTn15c31Hb56jEbxuQQuK",
            "name": "Necromancers Hammer",
            "rarity": "Rare",
            "class": "Gem",
            "attack": 11,
            "defense": 11
        },
        {
            "id": "141472",
            "img": "QmTnfgcgW66ZnzUjL84UqAYMF9BNK9tDvin6khuEXSVW4e",
            "name": "Green Axe",
            "rarity": "Rare",
            "class": "Nature",
            "attack": 10,
            "defense": 11
        },
        {
            "id": "141473",
            "img": "QmS9bvombxYwdrFwqGDRy2ywLztWnLtXuXSegqNQhcfsEe",
            "name": "Nordic Warhammer",
            "rarity": "Rare",
            "class": "Metal",
            "attack": 13,
            "defense": 8
        },
        {
            "id": "141475",
            "img": "Qmaz18TtZDTRSb4xTj5ced9RCqwmwLchdhVfMq5dDtQFcp",
            "name": "Divine Blade",
            "rarity": "Legendary",
            "class": "Metal",
            "attack": 13,
            "defense": 12
        },
        {
            "id": "141476",
            "img": "Qmc6GE9LgtP8GvAETaUsBqQvsKa8duGKqdKUYSXNPV1Ppt",
            "name": "Dagger of Creation",
            "rarity": "Epic",
            "class": "Nature",
            "attack": 16,
            "defense": 7
        },
        {
            "id": "141477",
            "img": "QmYoZqZ1dvdaTb8RVEXPDkPG2CX3yxzzkMeETLcdeA7nje",
            "name": "Lithium Dagger",
            "rarity": "Common",
            "class": "Air",
            "attack": 14,
            "defense": 5
        },
        {
            "id": "141479",
            "img": "QmQDJGFmHv97o1uMxtGYt9pKigo7xkAx2TKirLmp8NBTm2",
            "name": "Toothed Dagger",
            "rarity": "Epic",
            "class": "Neutral",
            "attack": 13,
            "defense": 9
        },
        {
            "id": "141480",
            "img": "Qmc2NjU1ULRQtQCh7qZKZipGGKxJTXdk8VdkxGZrZEw6th",
            "name": "Infernal Axe",
            "rarity": "Common",
            "class": "Fire",
            "attack": 13,
            "defense": 6
        },
        {
            "id": "141481",
            "img": "QmXEKethqHGyPmmEaqt6pvEDDuwiFaURoBMoYH4oLk5UoD",
            "name": "Reptiloid Blade",
            "rarity": "Rare",
            "class": "Fire",
            "attack": 14,
            "defense": 6
        },
        {
            "id": "141482",
            "img": "QmQ64UUuRKB9wD7ShzV6NPzUz5MM7DtZiWXjxcBjPxV3XU",
            "name": "AI Sword",
            "rarity": "Legendary",
            "class": "Metal",
            "attack": 13,
            "defense": 12
        },
        {
            "id": "141483",
            "img": "Qmc1z76vKBAWehaz6omVPrekDsXTjyF2sDdUkGKAosFBeq",
            "name": "Sky Shield",
            "rarity": "Common",
            "class": "Air",
            "attack": 5,
            "defense": 14
        },
        {
            "id": "141484",
            "img": "QmeNuQH3kntPGJM4bJYdB3ZUh3C5mq94TX6TVGQkA9e24d",
            "name": "Plasma Shield",
            "rarity": "Common",
            "class": "Fire",
            "attack": 6,
            "defense": 13
        },
        {
            "id": "141485",
            "img": "QmdoxVGEpiQqWK3ioe7Vr7BZCrmvtpu5d8Vz4J3L9wxMZk",
            "name": "Waxon Shield",
            "rarity": "Epic",
            "class": "Nature",
            "attack": 9,
            "defense": 13
        },
        {
            "id": "141489",
            "img": "QmP7r3MbTGvXL3TnK4ATNCK8fe3WWtnd2z2DUDhRUEAd7K",
            "name": "Jaggered Spear",
            "rarity": "Rare",
            "class": "Air",
            "attack": 16,
            "defense": 6
        },
        {
            "id": "141490",
            "img": "QmXJF3Tkq2ExBJQMANJW8UycAdfV1FbAxE6fVyKZVaB8L2",
            "name": "Emerald Dagger",
            "rarity": "Rare",
            "class": "Gem",
            "attack": 16,
            "defense": 7
        },
        {
            "id": "141491",
            "img": "QmS4Vk5JLiAVPXEQH78x22J4vq2EYR3gaBekWBjZBaCpt1",
            "name": "Equalizer Bow",
            "rarity": "Rare",
            "class": "Neutral",
            "attack": 17,
            "defense": 5
        },
        {
            "id": "141492",
            "img": "QmezrQKiuykMng1X7pnhwuETt1MC9NSjQLzWJWdbrXMCPe",
            "name": "Storm Edge Sword",
            "rarity": "Rare",
            "class": "Air",
            "attack": 14,
            "defense": 9
        },
        {
            "id": "141496",
            "img": "QmdyM299wSVTb2PCpPaCewUF2fWAxDShk1ZPYy6tAqqeV5",
            "name": "Waxon Sword",
            "rarity": "Rare",
            "class": "Gem",
            "attack": 13,
            "defense": 10
        },
        {
            "id": "141501",
            "img": "QmQV5oV326dZLt3JMQwA7DLLKGgughfmt37GU17Qi3EJTv",
            "name": "Moonshot Blade",
            "rarity": "Rare",
            "class": "Air",
            "attack": 15,
            "defense": 7
        },
        {
            "id": "141502",
            "img": "QmT72pm7h5pAX4U3UybqoUakAEzhLTyVWiqzP6jAQD1y6k",
            "name": "Dawn Sword",
            "rarity": "Rare",
            "class": "Metal",
            "attack": 12,
            "defense": 11
        },
        {
            "id": "141503",
            "img": "QmQt4RbuzTvLuBv9cERpFsEgmamYeuXZYPCy6fxF88cxst",
            "name": "Necromancers Hammer",
            "rarity": "Rare",
            "class": "Gem",
            "attack": 12,
            "defense": 12
        },
        {
            "id": "141505",
            "img": "QmdRvqNhLwKB2hh3pSHA24wQ8uJb9ogJ4iU6KFRBekUphw",
            "name": "Nordic Warhammer",
            "rarity": "Rare",
            "class": "Metal",
            "attack": 14,
            "defense": 9
        },
        {
            "id": "141511",
            "img": "QmZvssDo12XFw2XZNSwaRcfsWqvzgLidrjMuLx4SunfzMV",
            "name": "Reptiloid Blade",
            "rarity": "Rare",
            "class": "Fire",
            "attack": 15,
            "defense": 7
        }
    ]
;