import { useEffect } from "react";

export const orders_images = ['orders_atk_str.png', 'orders_atk_weak.png', 'orders_defence.png', 'orders_evade.png', 'orders_targeting.png', ];
export const planets_images = ['planet_1.png', 'planet_2.png', 'planet_3.png', 'planet_4.png', 'planet_5.png', 'planet_6.png'];
export const league_images = ['league-beginner-32.png', 'league-beginner-50.png', 'league-beginner-150.png', 'league-advanced-32.png', 'league-advanced-50.png', 'league-advanced-150.png'];

export default function PrecacheImages(props: {images: string[]}) {
    useEffect(() => {

        props.images.forEach(x => {
            new Image().src = x;
        });
    }, [props.images]);

    return (
        <div></div>
    )
}