export function isAdmin(key: string | undefined) {
    return key &&
        [
            'fcari.wam',
            'nmcn4.c.wam',
            '1fdba.wam',
            'v5n3w.c.wam',
        ].includes(key);
}

export function isTester(key: string | undefined) {
    return key &&
        [
            '1fdba.wam',
            '42lra.wam',
            'fcari.wam',
            'lnjtk.wam',
            'nmcn4.c.wam',
            'oe2bo.wam',
            'tusny.c.wam',
            'tysny.c.wam',
            'v5n3w.c.wam',
        ].includes(key);
}
