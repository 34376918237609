import TournamentBanner from "../components/Tournament/TournamentBanner";
import { useEffect, useState } from "react";
import TabWelcome from "../components/Tournament/TabWelcome";
import TabPlayers from "../components/Tournament/TabPlayers";
import TabPlanets from "../components/Tournament/TabPlanets";
import TabPrizes from "../components/Tournament/TabPrizes";
import TabRules from "../components/Tournament/TabRules";
import TournamentFooter from "../components/Tournament/TournamentFooter";
import { Leader, League } from "../helpers/Types";
import { end_of_season, fn_url } from "../helpers/Config";
import { calcCountdown } from "../helpers/Countdown";

export default function Tournament() {
    const [tab, setTab] = useState('WELCOME');
    const [teams, setTeams] = useState<any[]>([]);
    const [topplayers, setTopplayers] = useState<Leader[]>([]);
    const [played, setPlayed] = useState<number | undefined>();
    const [countdown, setCountdown] = useState(calcCountdown(new Date(end_of_season).getTime()));

    const loadTeams = async (league: League) => {
        console.log("Loading teams...");
        let teams : any[] = await fetch(fn_url + "leaderteams?league=" + league).then(response => response.json());
        setTeams(teams);
        setPlayed(teams.reduce((sum, current) => (sum + current.wins), 0));
    }

    const loadTopplayers = async (league: League) => {
        console.log("Loading topplayers...");
        let topplayers = await fetch(fn_url + "leadertopplayers?league=" + league).then(response => response.json());
        setTopplayers(topplayers);
    }

    useEffect(() => {
        loadTeams(League.ADVANCED);
        loadTopplayers(League.ADVANCED);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const interval = setInterval(() => setCountdown(calcCountdown(new Date(end_of_season).getTime())), 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className='flexColumn gap10 justifyStart' style={{width: '1011px', height: '630px', backgroundColor: 'rgba(37, 96, 97, 0.5)', borderRadius: '50px', textAlign: 'center'}}>
            <TournamentBanner />
            <div className='flexRow'>
                <button className='playButton' onClick={() => setTab('WELCOME')}>Welcome</button>
                <button className='playButton' onClick={() => setTab('PLANETS')}>Top Planets</button>
                <button className='playButton' onClick={() => setTab('PLAYERS')}>Top Players</button>
                <button className='playButton' onClick={() => setTab('PRIZES')}>Prizes</button>
                <button className='playButton' onClick={() => setTab('RULES')}>Rules</button>
            </div>
            <div className='flexRow alignStart' style={{height: '500px', width: '100%'}}>
                {tab === 'WELCOME' && <TabWelcome />}
                {tab === 'PLANETS' && <TabPlanets teams={teams}/>}
                {tab === 'PLAYERS' && <TabPlayers topplayers={topplayers}/>}
                {tab === 'PRIZES' && <TabPrizes />}
                {tab === 'RULES' && <TabRules />}
            </div>
            <TournamentFooter played={played} countdown={countdown}/>
        </div>
    )
}
