export default function BattleEvent(props: {bevent: string}) {

    const firstChar = props.bevent.slice(0,1);
    const image =
        firstChar === '>' ? 'icon-round' :
        firstChar === 'x' ? 'icon-attack' :
        firstChar === '@' ? 'icon-dead' :
        firstChar === '*' ? 'icon-gameover' :
        firstChar === '!' ? 'icon-winner' :
        firstChar === '.' ? 'icon-info' :
        firstChar === '$' ? 'icon-token' :
        'unknown';

    let text = props.bevent.slice(2);
    if (firstChar === '>') text = '-< ' + text.split(' ')[0] + ' ' + text.split(' ')[1] + ' >-';

    return (
        <div className='flexRow'>
            <img alt='' src={image + '-50.png'} style={{width: '20px', height: '20px'}}/>
            <div>{text}</div>
            <img alt='' src={image + '-50.png'} style={{width: '20px', height: '20px'}}/>
        </div>
    )
}
