
export interface Player {
    tag: string | undefined,
    wax: number | undefined,
    tlm: number | undefined,
    wins: number | undefined,
    nfts: Nft[] | undefined,
    tokens: number | undefined,
    nextReward: Game | undefined,
    games: Game[],
    repairs: Repair[],
    doRepair: any | undefined,
    refresh: any | undefined,
}

export enum GameMode {
    Free = "FREE",
    Paid = "PAID",
}

export enum League {
    BEGINNER = "BEGINNER",
    ADVANCED = "ADVANCED",
}

export interface Game {
    id: number,
    created_at: string,
    scheduled_at: string,
    played_at: string,
    paidout_at: string,
    name: string,
    mode: GameMode,
    entryFee: number,
    league: League,
    rake: number,
    action: string,
    reward: string,
    minPlayers: number,
    maxPlayers: number,
    players: number,
    totalFees: number,
    payout: number,
    profit: number,
    winner: string,
    winnerWallet: string,
    winnerTag: string,
    winnerTeam: string,
    winnerStat1: string,
    winnerStat2: string,
    winnerStat3: string,
    signature: string,
    registrations: Registration[],
    results: any,
}


export interface PurchasedItem {
    expires_at: string | null,
    quantity: string,
    walletKey: string,
}

export interface ShopItem {
    id: string,
    created_at: string,
    desc: string,
    duration: number | null
    enabled: boolean,
    items: PurchasedItem[],
    name: string,
    price: number,
    rarity: string,
}

export interface TokenPack {
    id: string,
    name: string,
    price: number,
    tokens: number,
    bonusPct: number,
}

export interface Leader {
    placing: number,
    winner: string,
    winnerWallet: string,
    winnerTag: string,
    wins: number,
    winnerStat1: number,
    winnerStat2: number,
    winnerStat3: number,
}

export interface Airdrop {
    walletKey: string,
    gamesPlayed: string,
    playedAt: string,
    airdrop: undefined | boolean,
}

export interface Result {
    game_id: number,
    result: string,
    key: string,
}

export interface Registration {
    game_id: number,
    walletKey: string,
    created_at: string,
    entryFee: number,
    reward: string,
    plr: number,
    placing: number,
    selection: string,
    ipAddress: string,
    country: string,
    coinBalance: number,
    nftsCount: number,
    item: string,
}

export default interface Nft {
    asset_id: string,
    template_id: string,
    name: string,
    image: string,
    schema: string,
    rarity: string,
    element: string | undefined,
}

export interface Token {
    walletKey: string,
    created_at: string,
    modified_at: string,
    balance: number,
}

export type CurrencyType =
    | "TLM"
    | "COIN"
    | "TOKEN"

export interface Transaction {
    id: number,
    created_at: string,
    walletKey: string,
    currency: CurrencyType,
    reason: string,
    prev: number,
    delta: number,
    post: number,
    signature: string,
}

export interface Rewards {
    balance: number;
    claims: Game[];
}

export interface Repair {
    id: string,
    wait_until: string,
}

export interface MarketItem {
    id: string,
    asset_id: string,
    template_id: string,
    collection: string,
    schema: string,
    name: string,
    image: string,
    rarity: string,
    status: string,
    enabled: boolean,
    originalPrice: number,
    price: number,
    currency: string,
    seller: string,
    buyer: string,
    signature: string,
    created_at: string,
    modified_at: string,
}

