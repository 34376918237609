import * as waxjs from "@waxio/waxjs/dist";
import { bank_account, token } from "../helpers/Config";

export async function join(walletKey: string) {
    console.log('wallet ' + walletKey + ' joining a free game...');

    const wax = new waxjs.WaxJS({rpcEndpoint: 'https://wax.greymass.com', userAccount: walletKey});
    let loggedInWallet = await wax.login();
    console.log('approving transaction for ' + loggedInWallet + '...');

    const result : any = await wax.api.transact({
        actions: [{
            account: 'battle.dome',
            name: 'join',
            authorization: [{
                actor: wax.userAccount,
                permission: 'active',
            }],
            data: {
                user: wax.userAccount,
            },
        }]
    }, {
        blocksBehind: 3,
        expireSeconds: 1200,
    });

    let transaction_id = result.transaction_id;
    console.log('transaction_id: ' + transaction_id);
    return transaction_id;
}

export async function buyitem(walletKey: string, item_id: string, price: number) {
    console.log('wallet ' + walletKey + ' buying ' + item_id + ' for ' + price + ' tokens...');

    const wax = new waxjs.WaxJS({rpcEndpoint: 'https://wax.greymass.com', userAccount: walletKey});
    let loggedInWallet = await wax.login();
    console.log('approving transaction for ' + loggedInWallet + '...');

    const result : any = await wax.api.transact({
        actions: [{
            account: 'battle.dome',
            name: 'buyitem',
            authorization: [{
                actor: wax.userAccount,
                permission: 'active',
            }],
            data: {
                user: wax.userAccount,
                item: item_id,
                price: price + ' ' + token
            }
        }]
    }, {
        blocksBehind: 3,
        expireSeconds: 1200
    });

    let transaction_id = result.transaction_id;
    return transaction_id;
}

export async function purchase(walletKey: string, amount: number, currency: string, memo: string) {
    console.log('wallet ' + walletKey + ' is purchasing ' + amount + ' ' + currency + '...');

    const wax = new waxjs.WaxJS({rpcEndpoint: 'https://wax.greymass.com', userAccount: walletKey});
    let loggedInWallet = await wax.login();
    console.log('approving transaction for ' + loggedInWallet + '...');

    let result: any;
    try {
        result = await wax.api.transact({
            actions: [{
                account: 'alien.worlds',
                name: 'transfer',
                authorization: [{
                    actor: wax.userAccount,
                    permission: 'active',
                }],
                data: {
                    from: wax.userAccount,
                    to: bank_account,
                    quantity: amount.toFixed(4) + ' ' + currency,
                    memo: memo,
                },
            }]
        }, {
            blocksBehind: 3,
            expireSeconds: 1200,
        });
    } catch (e:any) {
        console.log('Exception: ' + e.message);
        return null;
    }

    let transaction_id = result.transaction_id;
    console.log('transaction_id: ' + transaction_id);
    return transaction_id;
}
