import { imageForTeam, planetName } from "../../helpers/Utils";
import React from "react";

export default function TabPlanetsPanel(props: {team: any}) {
    let team = props.team;
    return(
        <div className='flexRow' key={team.winner} style={{gap: '20px', padding: '2px 5px 2px 10px', fontSize: '20px', justifyContent: 'flex-start'}}>
            <div style={{width: '20px', marginLeft: '10px'}}>{team.placing}.</div>
            <div className='flexRow' style={{width: '150px', gap: '10px', justifyContent: 'flex-start'}}>
                <img alt='' height='75px' width='75px' title={planetName(team.winnerTeam)} src={imageForTeam(team.winnerTeam)} style={{marginBottom: '0'}}/>
                <div>{planetName(team.winnerTeam)}</div>
            </div>
            <div className='flexRow' style={{width: '150px', gap: '5px', justifyContent: 'flex-start'}}>
                <div>{team.wins} win{team.wins > 1 && 's'}</div>
                {team.placing === 1 && <img alt='' height='25px' width='25px' src='/crown.png'/>}
            </div>
        </div>
    );
}
