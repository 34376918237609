import React from "react";
import { isAdmin } from "../methods/Authorize";
import { WalletContext } from "../main/App";
import BatchjobOverview from "../components/Batch/BatchjobOverview";

export default function Batchjobs() {
    const wallet = React.useContext(WalletContext);

    return (
        <div style={{display: "flex", flexDirection: "row"}}>
            {isAdmin(wallet.key) ?
                <div style={{minWidth: '1000px', padding: '10px 0px 0px 70px', minHeight: '500px'}}>

                    <BatchjobOverview />

                </div> :
                <div style={{width: '660px', padding: '40px 0px 0px 70px'}}>
                    <div style={{fontSize: '30px', marginBottom: '20px'}}>
                        Not authorized
                    </div>
                </div>
            }
        </div>
    );
}

