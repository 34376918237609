import MainMenu from "../components/MainMenu";
import LobbyMid from "../components/Lobby/LobbyMid";
import LobbyRight from "../components/Lobby/LobbyRight";

export default function Lobby() {
    return (
        <div style={{display: "flex", flexDirection: "row"}}>
            <MainMenu/>
            <div className='flexColumn gap5 justifyStart'>
                <div style={{display: "flex", flexDirection: "row", justifyContent: 'flex-start'}}>
                    <LobbyMid/>
                    <LobbyRight/>
                </div>
            </div>
        </div>
    );
}
