import * as waxjs from "@waxio/waxjs/dist";
import { ExplorerApi } from "atomicassets";
import { IAsset } from "atomicassets/build/API/Explorer/Objects";
import Nft from "../helpers/Types";
import { rarityLevel } from "../helpers/Utils";
import { default_collection } from "../helpers/Config";

export async function scanNfts(walletKey: string, collection = default_collection, schema?: string) : Promise<Nft[]> {
    console.log('Scanning nfts for collection ' + collection + (schema ? ' / ' + schema : '') + '...');
    const api = new ExplorerApi("https://wax.api.atomicassets.io", "atomicassets", {});
    let page = 0, pageSize = 1000;
    let assets : IAsset[] = [], assets_page : IAsset[];
    let schema_option = schema ? {schema_name: 'boosts'} : {};
    do {
        assets_page = await api.getAssets({...schema_option, ...{collection_name: collection, owner: walletKey, limit: pageSize, page: ++page}});
        assets = assets.concat(assets_page);
    } while (assets_page.length === pageSize && page < 10);
    console.log('scanned ' + assets.length + ' nfts from wallet ' + walletKey);
    // console.log(assets);
    let plr_nfts = assets
        .filter(x => x.template)    // some assets dont have a template_id
        .map((x: IAsset): Nft => ({
            asset_id: x.asset_id,
            template_id: x.template!.template_id,
            name: x.name,
            image: x.data.img,
            schema: x.schema.schema_name,
            rarity: x.template!.immutable_data.rarity,
            element: x.template!.immutable_data.element || x.template!.immutable_data.class,
        }));
    plr_nfts.sort((a, b) => a.name.localeCompare(b.name));
    plr_nfts.sort((a, b) => rarityLevel(b.rarity) - rarityLevel(a.rarity));
    return plr_nfts;
}

export async function sendNft(walletKey: string, destAccount: string, asset_id: number, memo: string) {
    console.log('wallet ' + walletKey + ' is sending asset_id ' + asset_id + ' to ' + destAccount + '...');

    const wax = new waxjs.WaxJS({rpcEndpoint: 'https://wax.greymass.com', userAccount: walletKey});
    let loggedInWallet = await wax.login();
    console.log('approving transaction for ' + loggedInWallet + '...');

    let result: any;
    try {
        result = await wax.api.transact({
            actions: [{
                account: 'atomicassets',
                name: 'transfer',
                authorization: [{
                    actor: wax.userAccount,
                    permission: 'active',
                }],
                data: {
                    from: wax.userAccount,
                    to: destAccount,
                    asset_ids: [asset_id],
                    memo: memo,
                },
            }]
        }, {
            blocksBehind: 3,
            expireSeconds: 1200,
        });
    } catch (e:any) {
        console.log('Exception: ' + e.message);
        return null;
    }

    let transaction_id = result.transaction_id;
    console.log('transaction_id: ' + transaction_id);
    return transaction_id;
}
