import React from "react";

export default function TabPlayersPanelRow(props: {placing: number, player: any}) {
    return(
        <div className='flexRow gap5'>
            <div style={{width: '15px'}}>{props.placing}.</div>
            <div style={{width: '85px'}}>{props.player.winnerTag?.slice(0,11) || props.player.winnerWallet}</div>
            <img alt='' height='15px' width='15px' src='/icon-attack-50.png'/>
            <div>{props.player.wins}</div>
        </div>
    );
}
