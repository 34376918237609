import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import ShopTimeLimit from "../components/Shop/ShopTimeLimit";
import Popup from "../components/Popup";
import MainMenu from "../components/MainMenu";
import { WalletContext } from "../main/App";
import { buyitem } from "../methods/Bank";
import { fn_url, token } from "../helpers/Config";
import { itemImage, rarityColor } from "../helpers/Utils";
import { ShopItem } from "../helpers/Types";
import ConnectWallet from "../components/ConnectWallet";

export default function Shop() {
    const [tokens, setTokens] = useState(0);
    const [items, setItems] = useState<ShopItem[]>([]);
    const [isPopup, setIsPopup] = useState<boolean>(false);
    const [popupItem, setPopupItem] = useState<ShopItem | null>(null);
    const wallet = React.useContext(WalletContext);

    useEffect(() => {
        loadItems(wallet.key)
        return setTokens(0)
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const loadItems = (key: string | undefined) => {
        if (!key) return;
        fetch(`https://fn.battledome.live/.netlify/functions/items?walletKey=${key}`).then(response => response.json()).then((res) => setItems(res.length ? res : '')).catch((e) => console.log('Items loading error: ', e))
    }

    const performBuyItem = async () => {
        setIsPopup(false);

        if (!wallet.key) return;
        if (!popupItem) return;

        // calling smart contract to authorize purchase
        let signature = await buyitem(wallet.key, popupItem.id, popupItem.price);
        if (!signature) return;

        // backend call, sending tx signature
        var registerResp = await fetch(fn_url + "itembuy", {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'text/plain'},
            body: JSON.stringify({
                walletKey: wallet.key,
                item_id: popupItem.id,
                price: popupItem.price,
                signature: signature,
            })
        });

        if (!registerResp.ok) {
            handleClosePopup();
        } else {
            handleClosePopup();
            loadItems(wallet.key);
            let playerdata = await fetch(fn_url + "playerdata?walletKey=" + wallet.key).then(response => response.json());
            setTokens(playerdata.rewards.balance);
        }
    }

    const handleClosePopup = () => {
        setIsPopup(false);
        setPopupItem(null);
    }

    const handleOpenPopup = (item: ShopItem) => {
        setIsPopup(true);
        setPopupItem(item);
    }

    const hasThisItem = (shopItem: ShopItem) => {
        return (!shopItem.duration && shopItem.items.length) || itemIsActive(shopItem) || equivalentItemIsActive(shopItem);
    }

    const itemIsActive = (shopItem: ShopItem) => {
        return shopItem.duration && shopItem.items.length && shopItem.items[0].expires_at && new Date(shopItem.items[0].expires_at).getTime() > Date.now();
    }

    const equivalentItemIsActive = (shopItem: ShopItem) => {
        if (!shopItem.duration) return false;
        let itemType = shopItem.id.slice(0, 6);     // e.g. POT-01
        return items.filter(x => x.id.startsWith(itemType)).filter(itemIsActive).length > 0;
    }

    return (
        <div style={{display: "flex", flexDirection: "row"}}>
            {isPopup && popupItem &&
                <Popup style={{left: '477px'}} handleClose={handleClosePopup} content={
                    <div className='flexColumn'>
                        <div>Buy {popupItem.name} for {popupItem.price} {token}?</div>
                        <div className='flexColumn gap5' style={{padding: '20px', backgroundColor: '#1a203c', borderRadius: '20px', gap: '5px'}}>
                            <img alt="" title={popupItem.desc} src={itemImage(popupItem.id)} style={{height: '100px', width: '100px', objectFit: 'contain'}}/>
                            <div style={{color: rarityColor(popupItem.rarity)}}>{popupItem.rarity} item</div>
                        </div>
                        <div style={{maxWidth: '75%', textAlign: 'center'}}>{popupItem.desc}</div>
                        {/*<div style={{maxWidth: '75%', textAlign: 'center'}}>{popupItem.duration ? 'lasts for ' + popupItem.duration + ' hours' : 'In-game item, bound to your wallet address'}</div>*/}
                        <div className='flexRow'>
                            <button onClick={() => handleClosePopup()} className="clearButton" style={{cursor: 'pointer'}}>Cancel</button>
                            <button onClick={() => performBuyItem()} className="clearButton" style={{cursor: 'pointer'}}>Buy Item</button>
                        </div>
                    </div>}
                />
            }
            <MainMenu/>
            <div style={{width: '660px', padding: '10px 0px 20px 70px', fontSize: '20px'}}>
                {/*<div style={{fontSize: '30px', marginBottom: '20px'}}>*/}
                {/*    Shop*/}
                {/*</div>*/}

                {wallet.key ?
                    <div className='flexColumn'>
                        {wallet.player.tokens !== undefined ?
                            <div className='flexRow'>
                                <div>Your balance is:</div>
                                <img alt="" src="token-32.png" height="30px" width="30px"/>
                                <div>{tokens ? tokens : wallet.player.tokens} {token.toUpperCase()}</div>
                                <Link to='/tokens'><button className="playButton" style={{marginLeft: '20px'}}>Top up Skullz</button></Link>
                            </div>
                          :
                            <div style={{height: '40px'}}>Loading...</div>
                        }

                        {items.length > 0 ?
                            <div className='flexRow wrap'>
                                {
                                    items.map((item: ShopItem) =>
                                        <div className="flexColumn" style={{width: '165px', padding: '20px', backgroundColor: '#1a203c', borderRadius: '20px', gap: '5px'}} key={item.id}>
                                            <div style={{fontSize: '20px', fontWeight: 'bold', whiteSpace: 'nowrap'}}>{item.name}</div>
                                            <div>
                                                <img alt="" title={item.desc} src={itemImage(item.id)} style={{height: '100px', width: '100px', objectFit: 'contain'}}/>
                                                <div style={{color: rarityColor(item.rarity), textAlign: 'center'}}>{item.rarity}</div>
                                            </div>
                                            <div className="flexRow" style={{gap: '7px'}}>
                                                <img alt="" src="token-32.png" height="24px" width="24px"/>
                                                {item.price} {token}
                                            </div>
                                            <div className='flexColumn' style={{fontSize: '16px'}}>
                                                {hasThisItem(item) ?
                                                    <div className='flexColumn gap5'>
                                                        <div style={{color: '#00ff72', height: '36px'}}>you have this item</div>
                                                        {item.duration && <div style={{height: '25px'}}>{itemIsActive(item) ? <ShopTimeLimit time={item.items[0].expires_at}/> : <div></div>}</div>}
                                                    </div>
                                                :
                                                    <div className='flexColumn gap5'>
                                                        <button onClick={() => handleOpenPopup(item)} className='clearButton'>Buy Item</button>
                                                        {item.duration && <div>lasts for for {item.duration} hours</div>}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                                <div style={{textAlign: 'center', margin: '10px'}}>
                                    Note: all items in the Battledome shop are in-game only. They are bound to your wallet address and are non-transferable between wallets.
                                </div>
                            </div>
                            :
                            <div>Loading...</div>
                        }
                    </div>
                    :
                    <ConnectWallet onConnectFn={loadItems}/>
                }
            </div>
        </div>
    );
}
