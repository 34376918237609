
export interface Countdown {
    days: number,
    hours: number,
    minutes: number,
    seconds: number,
}

export function calcCountdown(dateUntil: number) : Countdown {
    let diff = Math.trunc((dateUntil - Date.now()) / 1000);
    let days = Math.trunc(diff / 86400);
    let hours = Math.trunc(diff % 86400 / 3600);
    let minutes = Math.trunc(diff % 3600 / 60);
    let seconds = diff % 60;

    return {days: days, hours: hours, minutes: minutes, seconds: seconds};

}
