import React from "react";

export default function Popup(props : any) {
    return (
        <div className="popup-box">
            <div className="box" style={props.wide && {width: '800px'}}>
                {props.dontShowX || <span className="close-icon" style={props.style ? props.style : props.wide && {left: '400px'}} onClick={props.handleClose}>x</span>}
                {props.content}
            </div>
        </div>
    );
};
