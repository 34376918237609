import React, { useEffect, useState } from "react";
import Popup from "../Popup";
import { end_of_season, image_base, nftPrizeImagesAdvanced, season } from "../../helpers/Config";
import { Link } from "react-router-dom";

export default function Announcement() {
    const [now, setNow] = useState(Date.now());
    const [isPopup, setIsPopup] = useState(false);

    const togglePopup = () => {
        setIsPopup(!isPopup);
    }

    useEffect(() => {
        const interval = setInterval(() => setNow(Date.now()), 1000);
        return () => clearInterval(interval);
    }, []);

    const endOfSeasonDate = Date.parse(end_of_season);
    let diff = Math.trunc((endOfSeasonDate - now) / 1000);
    let days = Math.trunc(diff / 86400);
    let hours = Math.trunc(diff % 86400 / 3600);
    let minutes = Math.trunc(diff % 3600 / 60);
    // let seconds = diff % 60;

    return (
        <div className='flexColumn gap5' style={{color: 'black', backgroundColor: 'lightblue', borderRadius: '10px', width: '335px', marginLeft: '40px', marginBottom: '10px', padding: '5px 20px 5px 20px'}}>
            { now > endOfSeasonDate ?
                <div className='flexColumn gap5'>
                    <div style={{fontWeight: 'bold', margin: '5px'}}>Battledome season {season}</div>
                    <div className='flexRow gap10'>
                        {nftPrizeImagesAdvanced.slice(0,5).map(img => { return (<img alt='' height='50px' src={image_base + img}/>)})}
                    </div>
                    <div style={{fontWeight: 'bold'}}>SEASON CLOSED.</div>
                    <div>this season is over :)</div>
                </div>
                :
                <div className='flexColumn gap5'>
                    <div style={{fontWeight: 'bold'}}>Battle of the Worlds!</div>
                    <div>
                    <iframe height="150px" width="300px" allow="accelerometer;encrypted-media;gyroscope;picture-in-picture"
                            title="Battle of the Worlds"
                            src="https://www.youtube.com/embed/4nStvzJAKtQ?autohide=1&amp;border=0&amp;wmode=opaque&amp;playsinline=1"
                            className="tf_abs tf_w tf_h"></iframe>
                    </div>
                    {/*<div style={{fontWeight: 'bold', margin: '5px'}}>Season {season} sponsor: {seasonSponsor}</div>*/}
                    {/*<div>Season winners receive up to 100 Skullz</div>*/}
                    {/*<div className='flexRow gap5'>*/}
                    {/*    <img alt='' height='50px' src='/token-50.png'/>*/}
                    {/*</div>*/}

                    {/*<div style={{fontWeight: 'bold', margin: '5px'}}>Battledome season {season}</div>*/}
                    {/*<div className='flexRow gap10'>*/}
                    {/*    {nftPrizeImagesAdvanced.slice(0,5).map((img, x) => { return (<img key={x} alt='' height='50px' src={image_base + img}/>)})}*/}
                    {/*</div>*/}
                    {/*<div style={{fontWeight: 'bold'}}>Sponsored by {seasonSponsor}</div>*/}
                    {/*<div>season {season} ends in {days} days, {hours} hours, {minutes} minutes</div>*/}
                    <div className='flexRow gap10'>
                        <img alt='' height='24px' src='/icon-attack-50.png'/>
                        <Link to='/tournament'><span className='link-dark' style={{fontWeight: 'bold'}}>Open Tournament Info</span></Link>
                        <img alt='' height='24px' src='/icon-attack-50.png'/>
                    </div>
                    <div>Ends in {days} days, {hours} hours, {minutes} minutes</div>
                </div>
            }
            {isPopup && <Popup wide={650} dontShowX={true} content={
                <div className='flexColumn gap5'>
                    <img alt='' src='banner-competition.jpg' width='800px' height='333px'/>
                    <div>Enrolments are closed, 300 players have registered!</div>
                    <div>Tournament runs until 15 December, 1pm UTC.</div>
                    <div>Prize pool 500,000 TLM</div>
                    <div><a target='rules' href='https://ghubs.alienworlds.io/pages/battledome-tournament-rules'><span className='link-dark'>read the tournament rules</span></a></div>
                    <button className="playButton" onClick={togglePopup} style={{marginTop: '10px'}}>OKAY</button>
                </div>
            } handleClose={togglePopup}/>}
        </div>
    );
}