import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MainMenu from "../components/MainMenu";
import { Leader, League } from "../helpers/Types";
import { imageForAvatar } from "../helpers/Utils";
import {
    fn_url,
    image_base,
    leaderGroups,
    leaderGroupSize,
    leaderPageSize,
    nftPrizeImagesAdvanced,
    nftPrizeImagesBeginner,
    season
} from "../helpers/Config";
import { WalletContext } from "../main/App";
import LeagueSelector from "../components/Game/LeagueSelector";

export default function Leaders() {
    let wallet = React.useContext(WalletContext);
    const [league, setLeague] = useState(wallet.player.wins || 0 > 5 ? League.ADVANCED : League.BEGINNER);
    const [leaders, setLeaders] = useState<Leader[]>([]);
    const [page, setPage] = useState(1);

    const selectAndLoadLeague = (league: League) => {
        setLeaders([]);
        setPage(1);
        setLeague(league);
        loadLeaders(league)
    }

    const loadLeaders = async (league: League) => {
        console.log("Loading leaders...");
        let leaders = await fetch(fn_url + "leaders?league=" + league).then(response => response.json());
        setLeaders(leaders);
    }

    useEffect(() => {
        loadLeaders(league);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // const month_lowercase = new Date().toLocaleString('en-us', { month: 'long' });
    // const month = month_lowercase[0].toUpperCase() + month_lowercase.slice(1);

    function leadergroup(leader: Leader) {
        let leaderGroup = Math.trunc((leader.placing-1) / leaderGroupSize) + 1;
        return leaderGroup <= leaderGroups ? leaderGroup : 0;
    }

    function prevPage() {
        if (page > 1) {
            setPage(page - 1);
        }
    }

    function nextPage() {
        if (page < Math.ceil(leaders.length / leaderPageSize)) {
            setPage(page + 1);
        }
    }

    function lastPage() {
        setPage(Math.ceil(leaders.length / leaderPageSize));
    }

    function getPrizeImage(placing: number) {
        let nftPrizeImages = league === League.ADVANCED ? nftPrizeImagesAdvanced : nftPrizeImagesBeginner;
        return placing - 1 < nftPrizeImages.length ? nftPrizeImages[placing -1] : undefined;
    }

    return (
        <div style={{display: "flex", flexDirection: "row"}}>
            <MainMenu/>
            <div style={{width: '660px', padding: '10px 0px 0px 70px'}}>
                <div className='flexRow justifyStart'>
                    <div style={{fontSize: '30px', marginBottom: '20px'}}>
                        Leaderboard - season {season}
                    </div>
                </div>
                <div className='flexColumn' style={{gap: '2px', alignItems: 'flex-start'}}>
                {leaders.slice(leaderPageSize * (page-1), leaderPageSize * page).map(leader =>
                    <div className='flexRow' key={leader.placing} style={{gap: '20px', padding: '2px 5px 2px 10px', fontSize: '20px', justifyContent: 'flex-start',
                            border: leader.winnerWallet === wallet.key ? '1px solid green' : '1px transparent'}}>
                        <div style={{width: '20px'}}>
                            {leader.placing}.
                        </div>
                        <div className='flexRow' style={{width: '210px', gap: '10px', justifyContent: 'flex-start'}}>
                            <img alt="" height="30px" src={imageForAvatar(leader.winner)} style={{marginBottom: '0'}}/>
                            <div>{leader.winnerTag || leader.winnerWallet}</div>
                        </div>
                        <div style={{width: '24px', height: '32px', display: 'flex'}}>
                            {/*{ leadergroup(leader) === 1 && (*/}
                            {/*    <img alt='' style={{height: '32px'}} title={'Tournament Prize: ' + tournamentPrizes[leader.placing-1].toLocaleString() + ' TLM'} src='trophy-gold.png' />*/}
                            {/*)}*/}

                            { leadergroup(leader) === 1 && league === League.ADVANCED && (<img alt='' style={{height: '32px'}} title='Gold end-of-season Reward' src='trophy-gold.png' />)}
                            { leadergroup(leader) === 2 && league === League.ADVANCED && (<img alt='' style={{height: '32px'}} title='Silver end-of-season Reward' src='trophy-silver.png' />)}
                            { leadergroup(leader) === 3 && league === League.ADVANCED && (<img alt='' style={{height: '32px'}} title='Bronze end-of-season Reward' src='trophy-bronze.png' />)}
                        </div>
                        <div className='flexRow' style={{width: '50px', gap: '5px', justifyContent: 'flex-start'}}>
                            <img alt='' style={{height: '16px', width: '16px'}} title='Wins' src='icon-winner-50.png'/><div>{leader.wins}</div>
                        </div>
                        <div className='flexRow' style={{width: '50px', gap: '5px', justifyContent: 'flex-start'}}>
                            <img alt='' style={{height: '16px', width: '16px'}} title='Kills' src='icon-attack-50.png'/><div>{leader.winnerStat1}</div>
                        </div>
                        <div className='flexRow' style={{width: '80px', gap: '5px', justifyContent: 'flex-start'}}>
                            <img alt='' style={{height: '16px', width: '16px'}} title='Health' src='icon-health-50.png'/><div>{leader.winnerStat2}</div>
                        </div>
                        <div className='flexRow gap5 justifyStart' style={{width: '35px', height: '32px'}}>
                            { getPrizeImage(leader.placing) && (
                                <div><img alt='' style={{height: '32px'}} src={image_base + getPrizeImage(leader.placing)} /></div>
                            )}
                            { league === League.ADVANCED && (leadergroup(leader) === 1 || leadergroup(leader) === 2 || leadergroup(leader) === 3) && (
                                <div><img alt='' style={{height: '25px'}} src='token-32.png' /></div>
                            )}

                        </div>

                        {/*<div className='flexRow gap5 justifyStart' style={{width: '85px', height: '32px'}}>*/}
                        {/*    { leadergroup(leader) === 1 && (*/}
                        {/*        <div><img alt='' style={{height: '32px'}} src={image_base + nftPrizeImages[leader.placing -1]} /></div>*/}
                        {/*    )}*/}
                        {/*    { (leadergroup(leader) === 1 || leadergroup(leader) === 2) && (*/}
                        {/*        <div><img alt='' style={{height: '25px'}} src='tlm-32.png' /></div>*/}
                        {/*    )}*/}
                        {/*    { (leadergroup(leader) === 1 || leadergroup(leader) === 2 || leadergroup(leader) === 3) && (*/}
                        {/*        <div><img alt='' style={{height: '25px'}} src='token-32.png' /></div>*/}
                        {/*    )}*/}
                        {/*</div>*/}
                    </div>
                    )}
                </div>
                {leaders.length > leaderPageSize &&
                    <div className='flexColumn' style={{marginTop: '10px', width: '550px'}}>
                        <div className='flexRow' style={{gap: '10px'}}>
                            <button className='playButton' style={{padding: '5px', width: '50px'}} title='First page' onClick={() => setPage(1)}>&lt;&lt;</button>
                            <button className='playButton' style={{padding: '5px', width: '50px'}} title='Previous page' onClick={() => prevPage()}>&lt;</button>
                            <button className='playButton' style={{padding: '5px', width: '50px'}} title='Next page' onClick={() => nextPage()}>&gt;</button>
                            <button className='playButton' style={{padding: '5px', width: '50px'}} title='Last page' onClick={() => lastPage()}>&gt;&gt;</button>
                        </div>
                    </div>
                }
                <div style={{marginTop: '20px', width: '550px'}}>
                    <LeagueSelector league={league} setLeague={selectAndLoadLeague}/>
                </div>
                <div style={{marginTop: '20px'}}><Link to='/leaderteams'>Show Planets Leaderboard</Link></div>
            </div>
        </div>
    );
}
