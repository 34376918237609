import { useEffect, useState } from "react";
import { getPhase, phaseColor, phaseName } from "../helpers/Utils";

export default function PhasePanel() {
    const [now, setNow] = useState<Date>(new Date());
    const [phase, setPhase] = useState<number>(getPhase(new Date()));

    useEffect(() => {
        const interval = setInterval(() => {setNow(new Date()); setPhase(getPhase(new Date()));}, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className='flexColumn' style={{gap: '5px'}}>
            <div style={{fontWeight: 'bold'}}>Battledome Phase:</div>
            <div className='phase' title={'Minions and weapons with this phase gain a +5/+5 bonus'} style={{color: phaseColor(phase)}}>
                {phaseName(phase)}
            </div>
            <div className='flexRow' style={{gap: '5px'}}>
                <img alt='' title={phaseName(phase) + ' phase bonus is active now'} src='phase_icon.png' height='20px'/>
                <div style={{fontSize: '17px', fontWeight: 'bold'}}>
                    {(59 - now.getMinutes()).toString().padStart(2,'0')}:{(60 - now.getSeconds()).toString().padStart(2,'0')}
                </div>
                <img alt='' title={phaseName(phase) + ' phase bonus is active now'} src='phase_icon.png' height='20px'/>
            </div>
        </div>

    )
}
