import { BPlayer } from "./BattleTypes";
import { imageForMinion, imageForWeapon, nameForMinion, nameForWeapon, ordersImage, ordersName, planetImage, planetName } from "../../helpers/Utils";

export default function BattlePlrHover(props: {bplr: BPlayer}) {
    const bplr = props.bplr;
    let height = 100;

    return (
        <div className='flexColumn' style={{fontFamily: 'Alienworld', fontSize: '13px'}}>
            <div>plr-{bplr.num} {bplr.tag ? bplr.tag : bplr.walletKey}</div>
            <div className='flexRow gap5'>
                <img alt='' src={bplr.img} height={height + 'px'}/>
                <img alt='' title={nameForMinion(bplr.selection)} src={imageForMinion(bplr.selection)} height={height + 'px'}/>
                <img alt='' title={nameForWeapon(bplr.selection)} src={imageForWeapon(bplr.selection)} height={height + 'px'}/>
                <img alt='' title={ordersName(bplr.orders)} src={ordersImage(bplr.orders)} height={height-10 + 'px'} style={{marginTop: '0px'}}/>
                <img alt='' title={planetName(bplr.planet)} src={planetImage(bplr.planet)} height={height + 'px'}/>
            </div>
            <div className='flexRow'>
                <div style={{color: 'deepskyblue'}}>attack {bplr.attack}</div>
                <div style={{color: 'lawngreen'}}>health {bplr.maxHealth}</div>
            </div>
        </div>
    );
}
