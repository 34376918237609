import React from "react";
import { Game, GameMode } from "../../helpers/Types";
import { WalletContext } from "../../main/App";
import { imageForAvatar, planetIcon, planetName } from "../../helpers/Utils";
import { token } from "../../helpers/Config";

export default function HistoryGamePlacingsLine(props: { player: any, game: Game, numPlayers: number}) {
    let wallet = React.useContext(WalletContext);
    const p = props.player;

    return (
        <div style={{
            display: 'flex', justifyContent: 'left', alignItems: 'center', gap: '10px',
            // backgroundColor: 'lightblue', width: '470px', color: '#ffce00',
            padding: '2px', border: '1px solid ' + (wallet.key && p.walletKey === wallet.key ? 'green' : 'transparent')
        }}>
            <div style={{height: '30px'}}>
                <img alt='' style={{height: '30px'}} src={imageForAvatar(p.selection.split(' ')[0].split('/')[0])}/>
            </div>
            <div style={{width: '150px'}}>
                {p.tag || p.walletKey}
            </div>
            <img alt='' title={planetName(p.selection.split(' ')[4])} height='24px' width='24px' src={planetIcon(p.selection.split(' ')[4])}/>
            <div style={{width: '120px', marginLeft: '10px'}}>
                Place: {p.placing} of {props.numPlayers}
            </div>
            <div style={{width: '160px'}}>
                {p.placing === 1 && props.game.mode === GameMode.Free && 'Reward: 1 ' + token}
                {p.placing === 1 && props.game.mode === GameMode.Paid && 'Reward: ' + props.game.payout + ' TLM'}
            </div>
        </div>
    );
}