import React from "react";
import { bank_url } from "../../helpers/Config";

export default function AirdropInfo(props: {handleClose: any}) {
    return (
        <div className='flexColumn' style={{textAlign: 'center'}}>
            <div>
                Every day in January, play 5 games of Battledome to be eligible to win a random cryptomonKeys NFT.
            </div>
            <img alt='' height='200px' src='monkeydome-banner.png'/>
            <div>
                10 random <a target='cryptomonkeys' href='https://cryptomonkeys.cc/'><span className='link-dark'>cryptomonKeys</span></a> NFTs are airdropped randomly to eligible players at the end of each day :)
            </div>
            <div style={{marginTop: '10px'}}>
                See the last airdrop here: <a target='explorer' href={bank_url.replace('battle.dome', 'monkey.dome')}><span className='link-dark'>monkey.dome</span></a>
            </div>

            <button className="playButton" onClick={props.handleClose}>OKAY</button>

        </div>
    )
}