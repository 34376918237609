import MainMenu from "../components/MainMenu";
import React, { useEffect, useState } from "react";
import { Airdrop } from "../helpers/Types";
import { fn_url } from "../helpers/Config";
import { WalletContext } from "../main/App";
import Popup from "../components/Popup";
import AirdropInfo from "../components/Airdrops/AirdropInfo";
import { calcCountdown } from "../helpers/Countdown";

export default function Airdrops() {
    let wallet = React.useContext(WalletContext);
    const [countdown, setCountdown] = useState(calcCountdown(new Date().setUTCHours(23,59,59,999)));
    const [airdrops, setAirdrops] = useState<Airdrop[]>([]);
    const [isPopup, setIsPopup] = useState(false);
    const [page, setPage] = useState(1);
    const pageSize = 10;

    useEffect(() => {
        const interval = setInterval(() => setCountdown(calcCountdown(new Date().setUTCHours(23,59,59,999))), 1000);
        return () => clearInterval(interval);
    }, []);

    const loadAirdrops = async () => {
        console.log("Loading airdrops...");
        let airdrops = await fetch(fn_url + "airdrops").then(response => response.json());
        setAirdrops(airdrops);
    }

    useEffect(() => {
        loadAirdrops();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const togglePopup = () => {
        setIsPopup(!isPopup);
    }

    function prevPage() {
        if (page > 1) {
            setPage(page - 1);
        }
    }

    function nextPage() {
        if (page < Math.ceil(airdrops.length / pageSize)) {
            setPage(page + 1);
        }
    }

    function lastPage() {
        setPage(Math.ceil(airdrops.length / pageSize));
    }

    return (
        <div style={{display: "flex", flexDirection: "row"}}>
            <MainMenu/>
            <div style={{width: '660px', padding: '10px 0px 0px 70px', fontSize: '20px', textAlign: 'center'}}>
                <div className='flexRow'>
                    <div style={{fontSize: '30px', marginBottom: '20px'}}>
                        cryptomonKeys Airdrops
                    </div>
                </div>
                <div className='flexRow'>
                    <div>The following {airdrops.length} players are eligible for a random airdrop.</div>
                    <button className="playButton" onClick={togglePopup}>MORE INFO</button>
                </div>
                <div className='flexColumn' style={{gap: '2px', marginTop: '10px'}}>
                {airdrops.slice(pageSize * (page-1), pageSize * page).map(player =>
                    <div className='flexRow' key={player.walletKey} style={{gap: '20px', padding: '2px 5px 2px 10px', fontSize: '20px', justifyContent: 'flex-start', border: player.walletKey === wallet.key ? '1px solid green' : '1px transparent'}}>
                        <div className='flexRow' style={{width: '125px', gap: '10px', justifyContent: 'flex-start'}}>
                            <img alt='' height='20px' width='20px' src='icon-attack-50.png' />
                            <div>{player.walletKey}</div>
                        </div>
                        <div style={{width: '250px'}}>
                            {player.gamesPlayed} games played
                        </div>
                        <div style={{width: '120px'}}>
                            {player.playedAt}
                        </div>
                    </div>
                    )}
                </div>
                {airdrops.length > pageSize &&
                    <div className='flexRow' style={{display: 'flex', gap: '10px', marginTop: '20px'}}>
                        <button className='playButton' style={{padding: '5px', width: '50px'}} title='First page' onClick={() => setPage(1)}>&lt;&lt;</button>
                        <button className='playButton' style={{padding: '5px', width: '50px'}} title='Previous page' onClick={() => prevPage()}>&lt;</button>
                        <button className='playButton' style={{padding: '5px', width: '50px'}} title='Next page' onClick={() => nextPage()}>&gt;</button>
                        <button className='playButton' style={{padding: '5px', width: '50px'}} title='Last page' onClick={() => lastPage()}>&gt;&gt;</button>
                    </div>
                }
                <div style={{marginTop: '10px'}}>Next airdrop in: {countdown.hours} hours {countdown.minutes} minutes {countdown.seconds} seconds</div>

                {isPopup && <Popup content={<AirdropInfo handleClose={togglePopup} />} handleClose={togglePopup} />}
            </div>
        </div>
    );
}
