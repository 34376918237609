import GameDetails from './GameDetails'
import React, { useEffect, useState } from "react";
import { League } from '../../helpers/Types';
import { WalletContext } from "../../main/App";
import LeagueLock from './LeagueLock';
import LeagueSelector from "./LeagueSelector";

export default function GameList() {
    let wallet = React.useContext(WalletContext);
    const [league, setLeague] = useState(wallet.player.wins || 0 > 5 ? League.ADVANCED : League.BEGINNER);

    useEffect(() => {
        wallet.player.refresh();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className='flexColumn gap0' style={{marginTop: '20px'}}>
            <LeagueSelector league={league} setLeague={setLeague}/>
            {wallet.player.wins !== undefined && wallet.player.wins < 5 && league === League.ADVANCED ?
                <LeagueLock/>
                : wallet.player.games.length > 0 ?
                    <div>
                        {wallet.player.games.filter(game => game.league === league).map(game =>
                            <GameDetails key={game.id} game={{...game}} loadGames={wallet.player.refresh}/>)
                        }
                        <div style={{marginTop: '5px', marginLeft: '50px', textAlign: 'left'}}>
                            <button className="link-button" style={{color: 'mediumpurple'}}
                                    onClick={wallet.player.refresh}>&gt; refresh
                            </button>
                        </div>
                    </div>
                    :
                    <div className='flexColumn' style={{height: '250px', fontSize: '20px'}}>
                        <div className='flexRow gap5'>
                            <div>Loading games...</div>
                            <img alt='' height='24px' width='24px' src='icon-attack-50.png'/>
                        </div>
                    </div>
            }

        </div>
    );
}
